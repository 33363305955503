import React, {useLayoutEffect, useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import { countries } from '../app/globals'
import { Paper } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { grey, green, lightBlue, red, cyan } from '@mui/material/colors';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@mui/material';
import './Pricing.css'

const useStyles = makeStyles(theme => ({
    error: {
        color: red[700]
    },
    registrationBox: {
        width: '350px',
        minHeight: '550px',
        margin: '0 auto'
    },
    registrationPage: {
        width: '100%',
        verticalAlign: 'middle'
    },
    container: {
        background: 'rgb(255, 255, 255, 0)',
        maxWidth: '780px',
        marginTop: '5em',
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        color: '#59bbb9'
    },
    text: {
        '@media (max-width:600px)': {
            fontSize: '11px',
        },
    },
    boxTitle1: {
        color: green[900]
    },
    link: {
        textDecoration: 'none'
    },
    countryFlag: {
        maxWidth: '1.4em'
    },
    mainTitles:{
        color: '#16a09e',
        textAlign: 'center',
        background: "background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat",
        textShadow: "0 0px 0px rgba(255, 255, 255, 0.5)",
        WebkitAnimationIterationCount: 'infinte',
        WebkitAnimationDuration: '5s',
        WebkitAnimationName: 'shine',
        WebkitBackgroundClip: 'text'
    },
}));


const Pricing = (props) => {
    const classes = useStyles(props)
    const history = useHistory();

    const { openDrawer, toggleDrawer, loading, registerUser, user, setUser } = props
    const [pricingBox, setPricingBox] = useState(true);
    let [mobileView, setmobileView] = useState(false)
    const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    useEffect(()=> {
        setmobileView(viewWidth < 1200)
    })
    return (
    <main className={classes.registrationPage}>
        <Container maxWidth="xl" className={classes.container} >
        <Slide direction="up" in={pricingBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h2" className={classes.title}>Pricing</Typography><br/>
                <Typography variant="h4" className={classes.text}>Create and design your own academy website at:</Typography><br/>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        {/* Individual Price */}
                        <Paper
                            elevation={12}
                            sx={{
                                padding: mobileView ? "1em" : "1em",
                                margin: '1em'
                            }}
                        >
                            <Typography variant={mobileView ? "h4" : "h2"} sx={{
                                textAlign: 'center'
                            }} className={'shine'}><b>$15</b>/month</Typography><br/>
                            <Typography variant="h4" sx={{
                                textAlign: 'center'
                            }} className={'shine'}>For Individuals</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;Design and Launch your own academy website</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;Student and User Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;Courses and Enrolments Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;Classes and Lessons Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;Issue Digital Certificates, share them on Linkedin</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;Classes and Lessons Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;SMS Service Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon className={'shine'} sx={{
                                verticalAlign: 'text-top'
                            }}/>&nbsp;Payments and Invoice Management</Typography><br/>
                            <Paper 
                                elevation={0}
                                sx={{
                                    border: '0.5em #16a09eb5 solid',
                                    padding: '2em'
                                }}
                            >
                                <Typography variant="h5" className={classes.text}>&nbsp;- Limited to 15 teachers</Typography>
                            </Paper>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper
                            elevation={12}
                            sx={{
                                padding: (mobileView ? "1em" : "1em"),
                                margin: '1em',
                                backgroundColor: '#16a09eb5',
                                color: '#fff'
                            }}
                        >
                            <Typography variant={mobileView ? "h4" : "h2"} sx={{
                                textAlign: 'center'
                            }}><b>$X</b>/month</Typography><br/>
                            <Typography variant="h4" sx={{
                                textAlign: 'center'
                            }}>For Enterprises</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;Design and Launch your own academy website</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;Student and User Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;Courses and Enrolments Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;Classes and Lessons Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;Issue Digital Certificates, share them on Linkedin</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;Classes and Lessons Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;SMS Service Management</Typography><br/>
                            <Typography variant={mobileView ? "h7" : "h5"} className={classes.text}><CheckCircleIcon sx={{
                                verticalAlign: 'text-top',
                                marginBottom: '1em'
                            }}/>&nbsp;Payments and Invoice Management</Typography><br/>
                            <Paper 
                                elevation={0}
                                sx={{
                                    border: '0.5em #fff solid',
                                    padding: '2em',
                                    backgroundColor: '#59bbb9',
                                    color: '#fff'
                                }}
                            >
                                <Typography variant="h5" className={classes.text}>&nbsp;Contact Us Now: 
                                    &nbsp;<IconButton 
                                        onClick={() => {
                                            window.open("https://t.me/+FAvYihPRXQwzNmNl", '_blank')
                                        }}
                                    >
                                        <TelegramIcon sx={{color: '#fff'}}/>
                                    </IconButton>
                                    &nbsp;<IconButton 
                                        onClick={() => {
                                            window.open("https://wa.me/+6587311877", '_blank')
                                        }}
                                    >
                                        <WhatsAppIcon sx={{color: '#fff'}}/>
                                    </IconButton>
                                </Typography>
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
          </Slide>
        </Container>
    </main>
    )
}

export default Pricing