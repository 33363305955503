import { connect } from 'react-redux'

import { 
    togglePreview, selectComponent, getDesignAssets, createDesignAsset, updateDesignAsset,
    setComponentsTree, getLandingPage 
} from '../actions'
import VisualEditorBar from '../components/VisualEditorBar'

const mapStateToProps = state => ({
    preview: state.app.preview,
    components: state.components.components,
    landingPageComponents: state.designAssets.landingPageComponents,
    landingPageComponentsId: state.designAssets.landingPageComponentsId,
})

const mapDispatchToProps = dispatch => ({
    togglePreview: () => dispatch(togglePreview()),
    selectComponent: (data) => dispatch(selectComponent(data)),
    getDesignAssets: (query) => dispatch(getDesignAssets(query)),
    getLandingPage: (query) => dispatch(getLandingPage(query)),
    createDesignAsset: (reqBody) => dispatch(createDesignAsset(reqBody)),
    updateDesignAsset: (params, reqBody) => dispatch(updateDesignAsset(params, reqBody)),
    setComponentsTree: (reqBody) => dispatch(setComponentsTree(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisualEditorBar)