import React from 'react';
import LeftDrawer from '../containers/LeftDrawer.js'
import RightDrawer from '../containers/RightDrawer.js'
import UserApp from '../containers/UserApp.js'
import VisualEditorBar from '../containers/VisualEditorBar.js'


import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function VisualEditor(props) {
  const { preview } = props
  return (
    <DndProvider backend={HTML5Backend}>
        <main>
         <VisualEditorBar /> 
        <div>
            {!preview && <LeftDrawer/>}
            <UserApp _id={'root'}/>
            {!preview && <RightDrawer/>}
        </div>
        </main>
    </DndProvider>
  );
}

export default VisualEditor;