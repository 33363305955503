import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, login, resetRegistrationSuccess } from '../actions'
import Login from '../components/Login'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users && state.users.user,
    registrationSuccess: (state.users && state.users.registrationSuccess) || false,
})

const mapDispatchToProps = dispatch => ({
    login: (reqBody) => dispatch(login(reqBody)),
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    resetRegistrationSuccess: () => dispatch(resetRegistrationSuccess()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)