import React, { useEffect, useState, createRef } from 'react';
import { connect } from 'react-redux'
import { Paper } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ComponentTypes } from '../select-components/cTypes'
import { useHistory } from "react-router-dom"
import { selectComponent, addComponent, updateComponent } from '../actions'
import { grey, green, lightBlue } from '@mui/material/colors';
import { recursiveRender } from '../utils/components'

const MaterialPaper = (props) => {
    const { 
        _id, type, variant, elevation, square, children,
        droppable, draggable, isSelected, isHovered, rootParentType, parentId,
        position, height, heightUnit, stateOfComponents, margin, marginUnit, padding, paddingUnit,
        backgroundColor, backgroundImage, backgroundRepeat, 
        backgroundPosition, backgroundSize, minHeight, minHeightUnit, maxWidth, maxWidthUnit,
        selectComponent, selectedComponent, addComponent, updateComponent,
        preview, cursor, href
    } = props

    const accept = [ComponentTypes.BUTTON, ComponentTypes.GRIDCONTAINER, ComponentTypes.GRIDITEM, ComponentTypes.PAPER, ComponentTypes.TYPOGRAPHY]

    // DRAG
    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        type: type,
        canDrag: preview ? false : true,
        item: {
            // material paper props
            type: type,
            id: _id,
            children: children, // by default, the content of the button component array of items
            elevation: elevation, // by default
            square: square,
            variant: variant, // by default
            // css props
            position: position,
            backgroundColor: backgroundColor,
            backgroundImage: backgroundImage,
            backgroundRepeat: backgroundRepeat,
            backgroundPosition: backgroundPosition,
            backgroundSize: backgroundSize,
            minHeight: minHeight,
            minHeightUnit: minHeightUnit,
            height: height,
            heightUnit: heightUnit,
            margin: margin,
            marginUnit: marginUnit,
            padding: padding,
            paddingUnit: paddingUnit,
            maxWidth: maxWidth,
            maxWidthUnit: maxWidthUnit,
            cursor: cursor,
            href: href,
            // notice that those stringified keys are the ones we make use of, while those above are the material-ui props for buttons
            'droppable': droppable,
            'draggable': draggable,
            'isSelected': isSelected, // this is to show up on the toobox, right drawer and possibly show some css changes
            'isHovered': isHovered, // possibly show some css changes
            'rootParentType': rootParentType,
            'isRendered': true,
            'parentId': parentId,
        },
        collect: monitor => {
            console.log('button monitor: ', monitor);
            return ({
                isDragging: !!monitor.isDragging(),
            })
        },
        end: (item, monitor) => {
            console.log('!!!!!!!!!!!! drag end item: ', item);
            console.log('drag end monitor.didDrop(): ', monitor.didDrop());
            console.log('drag end monitorDropResult: ', monitor.getDropResult());
        }
      }),[props])

    // DROP
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: accept,
        // Props to collect
        collect: (monitor, dropProps) => {
          console.log('GRID monitor !!!!!!!.canDrop(): ', monitor.canDrop())
          console.log('GRID monitor !!!!!!!: ', monitor)
          console.log('GRID monitor !!!!!!! dropProps: ', dropProps)
          return ({
            isOver: !!monitor.isOver({ shallow: true }),
            canDrop: !!monitor.canDrop(),
          })
        },
        drop: (item, monitor) => {
            console.log('drop motion item!!!!!!!!!!!!!!!!:' , item)
            console.log('drop motion monitor getDropResult:' , monitor.getDropResult())
            console.log('ID : ', _id);
            // This is suppose to help drag and shift positions, tbc
            // if (!item.isRendered) {
            const dragIsOverThis = monitor.isOver({ shallow: true })
            console.log('dragIsOverThis : ', dragIsOverThis);
            console.log('item.isRendered : ', item.isRendered);
            if (dragIsOverThis && !item.isRendered) {
                // add component
                console.log('is over and item is not rendered, its from select, add component!');
                console.log('canDrop : ', canDrop);
                const dataConstruct = {
                    ...item,
                    parentId: _id, // the id of this component
                }
                addComponent(dataConstruct)
            } else if (dragIsOverThis && item.isRendered) {
                console.log('item <<<<<<<< >>>>>>>>>>', item);
                // update component
                const dataConstruct = {
                    ...item,
                    parentId: _id, // the id of this component
                }
                updateComponent(dataConstruct)
            }
            //  }
        },
        canDrop: (item, monitor) => {
          const targetItem = monitor.getItem()
          console.log('targetItem : ', targetItem);
          console.log('accept :', accept);
          console.log('accept.indexOf(targetItem.type) > -1 :', accept.indexOf(targetItem.type) > -1);
          return accept.indexOf(targetItem.type) > -1
        },
    }),[props])

    const history = useHistory();

    const handleHref = (href_provided) => {
        let httpsExists = href_provided.indexOf("https")
        let httpExists = href_provided.indexOf("http")
        if ((httpExists >= 0) || (httpsExists >= 0)) {
            window.location.href = href_provided
            return
        }
        if (href_provided) {
            history.push({
                pathname: `${href_provided}`,
            })
        }
    }

    return (
        isDragging ? (
        null) : (
            <Paper
            ref={(node) => drag(drop(node))}
            style={{
                opacity: 1,
                border: preview ? '' : `dashed ${grey[300]}`,
                cursor: preview ? cursor : 'move',
                minHeight: `${minHeight + minHeightUnit}`,
                height: `${height + heightUnit}`,
                maxWidth: `${maxWidth + maxWidthUnit}`,
                margin: `${margin + marginUnit}`,
                padding: `${padding + paddingUnit}`,
                borderColor: isOver ? `${lightBlue['A400']}` : (selectedComponent && selectedComponent.id === _id ) ? `${green['A400']}`: `${grey[300]}`,
                backgroundColor: backgroundColor && `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`,
                backgroundImage: backgroundImage ? `url("${backgroundImage}")` : '',
                backgroundRepeat: backgroundRepeat,
                backgroundPosition: backgroundPosition,
                backgroundSize: backgroundSize,
                position: position,
            }}
            variant={variant}
            elevation={elevation}
            square={square}
            children={children}
            onClick={(ev)=> {
                ev.stopPropagation();
                if (preview) {
                    if (href) {
                        handleHref(href)
                    } else {
                        return
                    }
                }
                selectComponent({
                    type: type,
                    id: _id,
                    children: children, // by default, the content of the button component array of items
                    elevation: elevation, // by default
                    square: square,
                    variant: variant, // by default
                    // css props
                    height: height,
                    heightUnit: heightUnit,
                    maxWidth: maxWidth,
                    maxWidthUnit: maxWidthUnit,
                    margin: margin,
                    marginUnit: marginUnit,
                    padding: padding,
                    paddingUnit: paddingUnit,
                    backgroundColor: backgroundColor,
                    backgroundImage: backgroundImage,
                    backgroundRepeat: backgroundRepeat,
                    backgroundPosition: backgroundPosition,
                    backgroundSize: backgroundSize,
                    position: position,
                    cursor: cursor,
                    href: href,
                    // notice that those stringified keys are the ones we make use of, while those above are the material-ui props for buttons
                    'droppable': droppable,
                    'draggable': draggable,
                    'isSelected': isSelected, // this is to show up on the toobox, right drawer and possibly show some css changes
                    'isHovered': isHovered, // possibly show some css changes
                    'rootParentType': rootParentType,
                    'isRendered': true,
                    'parentId': parentId
                })
            }}
        >
            {children && children.length > 0 && children.map((child) => {
                console.log('child ----> ', child);
                return recursiveRender(stateOfComponents[child])
            })}
        </Paper>)
    )
}

const mapStateToProps = state => ({
    selectedComponent: state.components.selectedComponent,
    stateOfComponents: state.components.components,
    preview: state.app.preview,
})
  
const mapDispatchToProps = dispatch => ({
    selectComponent: (data) => dispatch(selectComponent(data)),
    addComponent: (data) => dispatch(addComponent(data)),
    updateComponent: (data) => dispatch(updateComponent(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaterialPaper)
