import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, forgetPassword } from '../actions'
import ForgetPassword from '../components/ForgetPassword'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
})

const mapDispatchToProps = dispatch => ({
    forgetPassword: (reqBody) => dispatch(forgetPassword(reqBody)),
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgetPassword)