import React, { useLayoutEffect } from 'react';
// import './App.css';
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import clsx from 'clsx';
import cookie from 'js-cookie';

import NoMatch from './containers/NoMatch'
import Home from './containers/Home'
import Register from './containers/Register'
import Login from './containers/Login'
import ForgetPassword from './containers/ForgetPassword';
import ResetPassword from './containers/ResetPassword';
import ComingSoon from './containers/ComingSoon'
// import GettingStarted from './containers/GettingStarted'
import Organizations from './containers/Organizations';
import VisualEditor from './containers/VisualEditor';
import Pricing from './containers/Pricing';
import { drawerWidth } from './app/globals';

let theme = createMuiTheme({});

theme = responsiveFontSizes(theme)

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: +drawerWidth,
  },
}));

function App(props) {
  const classes = useStyles(props);
  const { openDrawer, getMe } = props

  useLayoutEffect(() => {
    const accessToken = cookie.get('access_token');
    getMe()
  }, [])

  return (
    <ThemeProvider theme={theme}>
    <main 
      className={clsx(classes.content, {
        [classes.contentShift]: openDrawer,
      })}
    >
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/register" exact component={Register}/>
        <Route path="/login" exact component={Login}/>
        <Route path="/forgot" exact component={ForgetPassword}/>
        <Route path="/pricing" exact component={Pricing}/>
        <Route path="/reset-password" exact component={ResetPassword}/>
        <Route path="/organizations" exact component={Organizations}/>
        <Route path="/organizations/:id/landing-page-designer" component={VisualEditor}/>
        <Route component={NoMatch} />
      </Switch>
    </main>
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  openDrawer: state.navDrawers.isOpen
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)