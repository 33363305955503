import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import cookie from 'js-cookie';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Menu from '@mui/material/Menu';
import RootRef from '@material-ui/core/RootRef';

import { drawerWidth } from '../app/globals';
import { Link, useHistory } from "react-router-dom"

import { grey, green, lightBlue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    navbar: {
        backgroundColor: 'rgba(255,255,255,1)'
    },
    navbarWhite: {
        backgroundColor: 'rgba(255,255,255,1)'
    },
    black:{
        color: 'black',
    },
    white:{
        color: 'white'
    },
    link:{
        color: 'black',
        textDecoration: 'none'
    },
    navbar_logo: {
        maxWidth: '10em'
    },
    toolbar: {
        overflowX: 'scroll'
    },
    title: {
        color: 'black',
        flexGrow: 1,
        cursor: 'pointer'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    Btn: {
        border: '1.2em',
        borderColor: '#156573',
        textDecoration: 'none'
    }
}));

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
  }

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const Appbar = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, originalNavState, user, setUser } = props
    let [originalNav, setoriginalNav] = useState(false)
    const history = useHistory();
        useEffect(() => {
            setoriginalNav(originalNavState)
          },[originalNavState])
    let [mobileView, setmobileView] = useState(false)
    window.onscroll = function(e) {
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) * 0.5
        const pageYOffSet = window.pageYOffset
        if (originalNavState) {
            setoriginalNav(window.location.pathname === '/' && pageYOffSet < viewHeight)        
        }
    }
    const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

    const inEditorView = window.location.href.includes("designer") || window.location.href.includes("editor")

    useEffect(()=> {
        setmobileView(viewWidth > 768)
    })

    let domRef = createRef();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleProfileMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseProfileMenu = () => {
        setAnchorEl(null);
    };

    const backToMain = () => {
        history.push({
            pathname: `/`,
        })
      }

    return <React.Fragment>
        <CssBaseline />
        {!inEditorView && <HideOnScroll {...props}>
            <AppBar
                position="relative"
                color='white'
            >
                <Toolbar 
                    className={classes.toolbar}
                >
                    {/* <IconButton 
                        className={originalNav ? classes.white : classes.black} 
                        color="inherit" 
                        aria-label="Menu"
                        onClick={() => toggleDrawer()}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    { 
                        <Typography variant="h4" onClick={() => { backToMain()  }} className={classes.title} sx={{ flexGrow: 2 }}><b style={{color: '#59bbb9'}}>Liberalize Academy</b></Typography>
                    }
                    {(user && user._id) &&<Link className={classes.link} to="/organizations"><Button 
                        variant="outlined" 
                        className={clsx(originalNav ? classes.black : classes.black, classes.Btn)}
                    >
                        <Typography variant="subtitle1">Organizations</Typography>
                    </Button></Link>}
                    {<Link className={classes.link} to="/pricing"><Button 
                        variant="outlined" 
                        className={clsx(originalNav ? classes.black : classes.black, classes.Btn)}
                    >
                        <Typography variant="subtitle1">Pricing</Typography>
                    </Button></Link>}
                    {(!user || !user._id) &&<Link className={classes.link} to="/login"><Button 
                        variant="outlined" 
                        className={clsx(originalNav ? classes.black : classes.black, classes.Btn)}
                    >
                        <Typography variant="subtitle1">Login</Typography>
                    </Button></Link>}
                    {(!user || !user._id) && <Link className={classes.link} to="/register"><Button 
                        variant="outlined" 
                        className={clsx(originalNav ? classes.black : classes.black, classes.Btn)}
                        // endIcon={<ArrowForwardIcon/>}
                    >
                        <Typography variant="subtitle1">
                            Register
                        </Typography>
                    </Button></Link>}
                <RootRef rootRef={domRef}>
                    <Button onClick={handleProfileMenu} color="inherit">{(user && user._id && <Avatar className={classes.green}>{user && user.name && user.name[0].toUpperCase()}</Avatar>) || ''}</Button>
                    <Menu
                        id="simple-menu"
                        className={classes.menuPosition}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        container={domRef.current}
                        onClose={handleCloseProfileMenu}
                    >
                        {/* {user && <MenuItem onClick={goToUserProfile}>Profile Settings</MenuItem>} */}
                        {user && user._id && <MenuItem onClick={() => {
                            cookie.set('access_token', "")
                            setUser("")
                            handleCloseProfileMenu()
                            backToMain()
                        }}>Logout</MenuItem>}
                    </Menu>
                </RootRef>
                </Toolbar>
            </AppBar>
        </HideOnScroll>}
        </React.Fragment>
}

Appbar.propTypes = {
    openDrawer: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired
}

export default Appbar
