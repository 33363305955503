import React, {useLayoutEffect, useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import { red, grey } from '@mui/material/colors';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import { countries } from '../app/globals';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(theme => ({
    error: {
        color: red[700]
    },
    loginBox: {
        width: '350px',
        minHeight: '550px',
        margin: '0 auto'
    },
    registrationPage: {
        width: '100%',
        verticalAlign: 'middle'
    },
    container: {
        background: 'rgb(255, 255, 255, 0)',
        maxWidth: '780px',
        marginTop: '5em',
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    countryFlag: {
        maxWidth: '1.4em'
    }
}));


const Login = (props) => {
    const classes = useStyles(props)
    const history = useHistory();

    const { 
        openDrawer, toggleDrawer, loading, login, user, 
        resetRegistrationSuccess, registrationSuccess } = props

    const [loginBox, setLoginBox] = useState(true);
    const [logo, setLogo] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = () => {
        login({
            email: email,
            password: password
        })
    }

    useEffect(() => {
        if (user && user._id) {
            history.push({
                pathname: `/organizations`,
            })
        }
    },[user])

    useEffect(() => {
        if (registrationSuccess) {
            setTimeout(() => {
                resetRegistrationSuccess()
            }, 2000);
        }
    },[registrationSuccess])

    return (
    <main className={classes.registrationPage}>
        <Container maxWidth="xl" className={classes.container} >
        {logo && <img className={classes.logo} alt="logo" src={logo}/>}
        <Slide direction="up" in={loginBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                {registrationSuccess && <Alert severity="success">Registration Success!</Alert>}
                <Typography variant="h5" className={classes.text}>Login</Typography><br/>
                <TextField
                    disabled={loading}
                    error={false}
                    onChange={(e) => { setEmail(e.target.value) }}
                    className={classes.root}
                    label="Email"
                    fullWidth
                    variant="outlined"
                />
                <br/><br/>
                <TextField
                    onChange={(e) => { setPassword(e.target.value) }}
                    disabled={loading}
                    error={false}
                    className={classes.root}
                    label="Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                />
                <br/><br/>
                <Typography >
                    <Link to={`/forgot`} className={classes.link}>
                        Forgot your password?
                    </Link>
                </Typography><br/>
                <Button 
                    disabled={loading} 
                    onClick={() => {handleLogin()}}
                    className={classes.introBtn}
                >{loading ? <CircularProgress/> : 'Login'}</Button><br/><br/>
                <Typography className={classes.text}>Don't have an account? <Link className={classes.link} to={`/register`}>Register</Link></Typography> 
            </Box>
          </Slide>
        </Container>
    </main>
    )
}

export default Login