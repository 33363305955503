import React, {useLayoutEffect, useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import { red, grey } from '@mui/material/colors';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import { countries } from '../app/globals'

const useStyles = makeStyles(theme => ({
    error: {
        color: red[700]
    },
    loginBox: {
        width: '350px',
        minHeight: '550px',
        margin: '0 auto'
    },
    registrationPage: {
        width: '100%',
        verticalAlign: 'middle'
    },
    container: {
        background: 'rgb(255, 255, 255, 0)',
        maxWidth: '780px',
        marginTop: '5em',
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    countryFlag: {
        maxWidth: '1.4em'
    }
}));


const ForgetPassword = (props) => {
    const classes = useStyles(props)
    const history = useHistory();

    const { openDrawer, toggleDrawer, loading, forgetPassword, user } = props

    const [loginBox, setLoginBox] = useState(true);
    const [logo, setLogo] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notReady, setNotReady] = useState(0);

    const handlePasswordReset = () => {
        setNotReady(120)
        forgetPassword({
            email: email,
        })
    }

    useEffect(() => {
        if (notReady > 0) {
            setTimeout(() => {
                setNotReady(notReady - 1)
            }, 1000);
        }
    },[notReady])

    useEffect(() => {
        if (user && user._id) {
            history.push({
                pathname: `/`,
            })
        }
    },[user])

    return (
    <main className={classes.registrationPage}>
        <Container maxWidth="xl" className={classes.container} >
        {logo && <img className={classes.logo} alt="logo" src={logo}/>}
        <Slide direction="up" in={loginBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h5" className={classes.text}>Password Reset</Typography><br/>
                <TextField
                    disabled={loading || (notReady > 0)}
                    error={false}
                    onChange={(e) => { setEmail(e.target.value) }}
                    className={classes.root}
                    label="Email"
                    fullWidth
                    variant="outlined"
                />
                <br/><br/>
                <Button 
                    disabled={loading || (notReady > 0)} 
                    onClick={() => {handlePasswordReset()}}
                    className={classes.introBtn}
                >{loading ? <CircularProgress/> : notReady > 0 ? `ready in ${notReady} seconds` : 'Send Password Reset Email'}</Button><br/><br/>
                <Typography className={classes.text}>Don't have an account? <Link className={classes.link} to={`/register`}>Register</Link></Typography> 
            </Box>
          </Slide>
        </Container>
    </main>
    )
}

export default ForgetPassword