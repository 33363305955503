import { connect } from 'react-redux'
import { toggleDrawer, setUser } from '../actions'
import Appbar from '../components/Appbar'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    originalNavState: (state.navBar && state.navBar.originalNav) || false,
    user: state.users.user,
})
  
const mapDispatchToProps = dispatch => ({
    setUser:(user) => dispatch(setUser(user)),
    toggleDrawer: () => dispatch(toggleDrawer()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appbar)