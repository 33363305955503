import cookie from 'js-cookie';

function designAssets(state ={}, action) {
    switch (action.type) {
      case 'POST_CREATE_DESIGN_ASSET_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'POST_CREATE_DESIGN_ASSET_SUCCESS':
          let createdDesignAsset = (action.data && action.data.designAsset) || []
          if (createdDesignAsset._id) {
            createdDesignAsset.id = createdDesignAsset._id
          }
          let landingPageComponents = state.landingPageComponents || {}
          let images = state.images || []
          let landingPageComponentsId = state.landingPageComponentsId || ""
          let imagesId = state.imagesId || ""

          if (createdDesignAsset.key === "landing-page-components") {
            landingPageComponents = createdDesignAsset.value
            landingPageComponentsId = createdDesignAsset._id
          }
          if (createdDesignAsset.key === "images") {
            images = createdDesignAsset.value
            imagesId = createdDesignAsset._id
          }
          
          return {
            ...state,
            landingPageComponents: landingPageComponents,
            landingPageComponentsId: landingPageComponentsId,
            images: images,
            imagesId: imagesId,
            loading: false,
          }
      case 'POST_CREATE_DESIGN_ASSET_FAILURE':
        const createDesignAssetError = (action.response && action.response.data && action.response.data.message) || 'Something went wrong'
        return {...state,
          loading: false,
          error: createDesignAssetError
        }
      case 'GET_DESIGN_ASSETS_REQUEST':
        return {...state,
          totalCount: 0,
          loading: true,
          error: ""
        }
      case 'GET_DESIGN_ASSETS_SUCCESS':
        const designAssetsArr = (action.data && action.data.designAssets) || (action.data) || []
        console.log('designAssetsArr: ',designAssetsArr);
        let currentPageComponents = state.landingPageComponents || {}
        let currentImages = state.images || []
        let currentPageComponentsId = state.landingPageComponentsId || ""
        let currentImagesId = state.imagesId || ""

        designAssetsArr.map((eachAsset) => {
            if (eachAsset.key === "landing-page-components") {
                currentPageComponents = eachAsset.value
                currentPageComponentsId = eachAsset._id
            }
            if (eachAsset.key === "images") {
                currentImages = eachAsset.value 
                currentImagesId = eachAsset._id
            }
        })

        console.log('currentImages : ', currentImages);

        return {...state,
            landingPageComponents: currentPageComponents,
            landingPageComponentsId: currentPageComponentsId,
            images: currentImages,
            imagesId: currentImagesId,
            loading: false,
            error: ""
        }
      case 'GET_DESIGN_ASSETS_FAILURE':
        const designAssetsError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          totalCount: 0,
          loading: false,
          error: designAssetsError
        }
      case 'PUT_UPDATE_DESIGN_ASSET_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'PUT_UPDATE_DESIGN_ASSET_SUCCESS':
          let updatedAsset = action.data || {}

          console.log('PUT_UPDATE_DESIGN_ASSET_SUCCESS1 updatedAsset:', updatedAsset);

          let oldPageComponents = state.landingPageComponents || {}
          let oldPageComponentsId = state.landingPageComponentsId || ""
          let oldImages = state.images || []
          let oldImagesId = state.imagesId || ""

          console.log('PUT_UPDATE_DESIGN_ASSET_SUCCESS1 oldImages:', oldImages);
          console.log('PUT_UPDATE_DESIGN_ASSET_SUCCESS1 oldPageComponents:', oldPageComponents);

          if (updatedAsset.key === "landing-page-components") {
            oldPageComponents = updatedAsset.value
            oldPageComponentsId = updatedAsset._id
          }
          if (updatedAsset.key === "images") {
            oldImages = updatedAsset.value
            oldImagesId = updatedAsset._id
          }

          console.log('PUT_UPDATE_DESIGN_ASSET_SUCCESS2 oldImages:', oldImages);
          console.log('PUT_UPDATE_DESIGN_ASSET_SUCCESS2 oldPageComponents:', oldPageComponents);

          return {...state,
            loading: false,
            landingPageComponents: oldPageComponents,
            landingPageComponentsId: oldPageComponentsId,
            images: oldImages,
            imagesId: oldImagesId,
            error: "",
          }
      case 'PUT_UPDATE_DESIGN_ASSET_FAILURE':
        const assetUpdatedError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: assetUpdatedError
        }
      case 'GET_LANDING_PAGE_REQUEST':
        return {...state,
          totalCount: 0,
          loading: true,
          error: ""
        }
      case 'GET_LANDING_PAGE_SUCCESS':
        const landingArr = (action.data && action.data.designAssets) || (action.data) || []
        console.log('landingArr: ',landingArr);
        let pageComponents = {}
        let pageComponentsId = ""

        landingArr.map((eachAsset) => {
            if (eachAsset.key === "landing-page-components") {
                pageComponents = eachAsset.value
                pageComponentsId = eachAsset._id
            }
        })
        return {...state,
            landingPageComponents: pageComponents,
            landingPageComponentsId: pageComponentsId,
            loading: false,
            error: ""
        }
      case 'GET_LANDING_PAGE_FAILURE':
        const landingPageError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          totalCount: 0,
          loading: false,
          error: landingPageError
        }
      default:
        return state
    }
  }

export default designAssets