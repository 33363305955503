import React, {useLayoutEffect, useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import { red, grey } from '@mui/material/colors';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import { countries } from '../app/globals'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '@mui/material/ButtonBase';
import { LoadingButton } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { Paper } from '@mui/material';

import {fetchApi} from '../app/utils/s3Api';

const useStyles = makeStyles(theme => ({
    error: {
        color: red[700]
    },
    organizationsBox: {
    },
    container: {
        background: 'rgb(255, 255, 255, 0)',
        maxWidth: '780px',
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    countryFlag: {
        maxWidth: '1.4em'
    },
    hiddeninput: {
        display: 'none',
    },
    orgAdminBox: {
        border: 'red 2px solid',
        padding: '1em',
    },
    paper: {
        padding: '1em'
    }
}));


const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));
  
  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));
  
  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  }));
  
  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));


const Organization = (props) => {
    const classes = useStyles(props)
    const history = useHistory();

    const { 
        openDrawer, toggleDrawer, loading, organization, getOrganization, updateOrganization,
        createdOrgAdminEmail, createdOrgAdminPass, getTemplateVersion, updateOrganizationTemplate,
        handleShowCreateOrgCustomDomain, handleShowDeleteOrgCustomDomain 
    } = props

    const hiddenInputRef = React.createRef();
    const faviconHiddenInputRef = React.createRef();

    const [organizationsBox, setOrganizationsBox] = useState(true);
    const [logo, setLogo] = useState("");
    const [page, setPage] = useState(1);
    const [inputChange, setInputChange] = useState(false);
    const [imageHover, setImageHover] = useState(false);
    const [copiedText, setCopiedText] = useState("");
    const [imageLoading, setImageLoading] = useState(false);
    const [faviconHover, setFaviconHover] = useState(false);
    const [faviconLoading, setFaviconLoading] = useState(false);
    const [templateUpdateAvail, setTemplateUpdateAvail] = useState(false);
    const [allCxDomainVerified, setAllCxDomainVerified] = useState(false);

    // const pollOrganization = () => {
    //     if (organization && organization.domain_state === "processing") {
    //         console.log('polling!');
    //         getOrganization({
    //             id: organization._id
    //         })
    //         // set time out to poll this organization
    //         setTimeout(() => {
    //             pollOrganization()
    //         }, 30000);
    //     }
    // }
    
    useEffect(() => {
        const currenctTemplateVersion = getTemplateVersion((organization && organization.domain_template))
        console.log('organization.domain_template_version: ', organization.domain_template_version);
        if (organization.domain_template_version != currenctTemplateVersion) {
            setTemplateUpdateAvail(true)
        }
        if (organization && organization.cx_domain && organization.cx_domain_cert) {
            // if there's custom domain check cert
            let allVerified = true
            if (organization.cx_domain_cert.domainValidationOptions) {
                organization.cx_domain_cert.domainValidationOptions.map((eachDomain) => {
                    if (eachDomain.ValidationStatus !== "SUCCESS") {
                        allVerified = false
                    }
                })
            }
            setAllCxDomainVerified(allVerified)
        } else {
            setAllCxDomainVerified(false)
        }
    },[])

    useEffect(() => {
        if (organization && organization.cx_domain && organization.cx_domain_cert) {
            // if there's custom domain check cert
            let allVerified = true
            if (organization.cx_domain_cert.domainValidationOptions) {
                organization.cx_domain_cert.domainValidationOptions.map((eachDomain) => {
                    if (eachDomain.ValidationStatus !== "SUCCESS") {
                        allVerified = false
                    }
                })
            }
            console.log('ALL VERIFIED ? ', allVerified);
            setAllCxDomainVerified(allVerified)
        } else {
            console.log('NOT ALL VERIFIED');
            setAllCxDomainVerified(false)
        }
        const currenctTemplateVersion = getTemplateVersion((organization && organization.domain_template))
        console.log('organization.domain_template_version: ', organization.domain_template_version);
        if (organization.domain_template_version !== currenctTemplateVersion) {
            console.log("currenctTemplateVersion: ", currenctTemplateVersion);
            setTemplateUpdateAvail(true)
        }
        console.log('organization changes: ', organization);
    },[organization])
    
    const copyText = (targetElementId) => {
        let textInput = document.createElement("input");
        let wrapper = document.getElementById(targetElementId)
        // console.log('textInput - > ', textInput);
        // Place in top-left corner of screen regardless of scroll position.
        textInput.style.position = 'fixed';
        textInput.style.top = 0;
        textInput.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textInput.style.width = '2em';
        textInput.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textInput.style.padding = 0;

        // Clean up any borders.
        textInput.style.border = 'none';
        textInput.style.outline = 'none';
        textInput.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textInput.style.background = 'transparent';

        textInput.value = targetElementId
         
        wrapper.appendChild(textInput);
        textInput.focus();
        textInput.select();
        textInput.setSelectionRange(0, 99999);

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            // console.log('Copying text command was ' + msg);
        } catch (err) {
            // console.log('Oops, unable to copy');
        }
        // console.log('textInput - > ', textInput);
        // console.log('textInput.value - > ', textInput.value);
        setCopiedText(targetElementId)
        setTimeout(() => {
            setCopiedText('')
        }, 3500);
        wrapper.removeChild(textInput);
    }

    // not using until we have all the Task to update ready and set up.
    const handleSave = () => {
        // save changes
        // set changes to false
        setInputChange(false)
    }

    const imageUpload = (eventFile) => {
        setImageLoading(true);
        let file = eventFile[0]
        console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setImageLoading(false);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/jpg':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setImageLoading(false);
            alert('Invalid file type')
            return;
        }
        const max_file_size = 102400 * 3
        if (file.size <= max_file_size){
            const reader = new FileReader()
            console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                console.log('content -> ', content);
                console.log('file 2 -> ', file);
                saveLogoImage(file, content)
            }
        } else {
            setImageLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 300KB');
        }
    }

    const saveLogoImage = (file, content) => {
        console.log('saveLogoImage: ', file);
        try {
            let fileNameArr = file.name.split('.')
            fetchApi("production",
                '/s3/putObjectUrl',
                'get',
                null,
                null,
                {fileType: file.type}
                ).then((result) => {
                    console.log('presigned result => ', result);
                    const { data } = result
                    if (!data.signedRequest) return
                    const xhr = new XMLHttpRequest()
                    let url = data.signedRequest
                    
                    xhr.open(data.method, url)
                    // temp commented out
                    // xhr.setRequestHeader('x-amz-acl', data.XamzACL)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            console.log('success!');
                            let orgInfo = JSON.parse(JSON.stringify(organization))
                            orgInfo.organization_logo = data.cdnUrl
                            updateOrganization({ id: organization._id }, orgInfo)
                            setImageLoading(false);
                        }
                        console.log('xhr : ', xhr);
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setImageLoading(false);
            console.log('error : ', err);
        }
    }

    const faviconUpload = (eventFile) => {
        setFaviconLoading(true);
        let file = eventFile[0]
        console.log('file -> ', file);
        console.log('file.type1 -> ', file.type);
        let matchedFileType = false;
        if (!file) {
            setImageLoading(false);
            return;
        }
        console.log('file.type1 -> ', file.type);
        switch (file.type) {
            case 'image/x-icon':
            case 'image/vnd.microsoft.icon':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setFaviconLoading(false);
            alert('Invalid file type')
            return;
        }
        const max_file_size = 102400 * 3
        if (file.size <= max_file_size){
            const reader = new FileReader()
            console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                console.log('content -> ', content);
                console.log('file 2 -> ', file);
                saveFaviconImage(file, content)
            }
        } else {
            setFaviconLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 300KB');
        }
    }

    const saveFaviconImage = (file, content) => {
        console.log('saveLogoImage: ', file);
        try {
            let fileNameArr = file.name.split('.')
            fetchApi("production",
                '/s3/putObjectUrl',
                'get',
                null,
                null,
                {fileType: file.type}
                ).then((result) => {
                    console.log('presigned result => ', result);
                    const { data } = result
                    if (!data.signedRequest) return
                    const xhr = new XMLHttpRequest()
                    let url = data.signedRequest
                    
                    xhr.open(data.method, url)
                    // temp commented out
                    // xhr.setRequestHeader('x-amz-acl', data.XamzACL)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            console.log('success!');
                            let orgInfo = JSON.parse(JSON.stringify(organization))
                            orgInfo.organization_favicon = data.cdnUrl
                            updateOrganization({ id: organization._id }, orgInfo)
                            setFaviconLoading(false);
                        }
                        console.log('xhr : ', xhr);
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setFaviconLoading(false);
            console.log('error : ', err);
        }
    }

    const goToVisualEditor = (pagetoDesign) => {
        switch (pagetoDesign) {
            case "landing-page":
                history.push({
                    pathname: `/organizations/${organization._id}/landing-page-designer`,
                })
                break;
            default:
                break;
        }
    }

    

    return (
    <main className={classes.registrationPage}>
        <Container maxWidth="xl" className={classes.container} >
        {logo && <img className={classes.logo} alt="logo" src={logo}/>}
        <Slide direction="up" in={organizationsBox} mountOnEnter unmountOnExit>
            <Paper elevation={3} className={classes.paper}>
                <Box m={2} variant="middle" className={classes.box} >
                    <Typography variant="h5" className={classes.text}>{organization && organization.name}</Typography><br/>
                    {/* LOGO */}
                    <Typography variant="h6" className={classes.text}>Organisation Logo: </Typography><br/>
                    <Typography variant="h7" className={classes.text}>Recommended Dimension: 280 x 128 </Typography><br/>
                    <input ref={hiddenInputRef} accept="image/*" className={classes.hiddeninput} onChange={(e) => {imageUpload(e.target.files)}} id="icon-button-file" type="file" />
                    {
                        <ImageButton
                        disabled={loading || imageLoading}
                        focusRipple
                        sx={{
                            width: '40%'
                        }}
                        onMouseEnter={() => {
                            setImageHover(true)
                        }}
                        onMouseLeave={() => {
                            setImageHover(false)
                        }}
                        onClick={() => {
                            if (loading || imageLoading) return
                            hiddenInputRef.current.click()
                        }}
                    >
                        <ImageSrc style={{ backgroundImage: `url(${(organization && organization.organization_logo) || ""})` }} />
                        <ImageBackdrop className="MuiImageBackdrop-root" />
                        <Image 
                            onClick={() => {
                                console.log('clicked!');
                            }}
                        >
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                sx={{
                                    position: 'relative',
                                    p: 4,
                                    pt: 2,
                                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                }}
                            >
                                { imageHover && 'UPLOAD IMAGE'}
                                {imageHover && <ImageMarked className="MuiImageMarked-root" />}
                            </Typography>
                        </Image>
                    </ImageButton>
                    }
                    <br/><br/>
                    {/* FAVICON */}
                    <Typography variant="h6" className={classes.text}>Organisation Favicon: </Typography><br/>
                    <Typography variant="h7" className={classes.text}>A favicon is a small icon or collection of icons associated with a website, web page, or web application. It’s displayed within the browser tabs and bookmarks bar.
                    Check <a target="_blank" href="https://favicon.io/">https://favicon.io/</a> to understand more and generate your own favicon. It should be a ".ico" file extension.</Typography><br/>
                    <input ref={faviconHiddenInputRef} accept="image/x-icon" className={classes.hiddeninput} onChange={(e) => {faviconUpload(e.target.files)}} id="favicon-button-file" type="file" />
                    {
                        <ImageButton
                        disabled={loading || faviconLoading}
                        focusRipple
                        sx={{
                            width: '40%'
                        }}
                        onMouseEnter={() => {
                            setFaviconHover(true)
                        }}
                        onMouseLeave={() => {
                            setFaviconHover(false)
                        }}
                        onClick={() => {
                            if (loading || faviconLoading) return
                            faviconHiddenInputRef.current.click()
                        }}
                    >
                        <ImageSrc style={{ backgroundImage: `url(${(organization && organization.organization_favicon) || ""})` }} />
                        <ImageBackdrop className="MuiImageBackdrop-root" />
                        <Image 
                            onClick={() => {
                                console.log('clicked!');
                            }}
                        >
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                sx={{
                                    position: 'relative',
                                    p: 4,
                                    pt: 2,
                                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                }}
                            >
                                {faviconHover && 'UPLOAD FAVICON'}
                                {faviconHover && <ImageMarked className="MuiImageMarked-root" />}
                            </Typography>
                        </Image>
                    </ImageButton>
                    }
                    <br/><br/>
                    <Typography variant="h6" className={classes.text}>Domain Template: {organization && organization.domain_template === "template_1" ? "Original" : ""}</Typography><br/>
                    <Typography variant="h6" className={classes.text}>
                        Web Application Status: &nbsp; &nbsp;
                        {
                            organization && organization.domain_state && organization.domain_state === 'processing' ? <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                        {organization.domain_state.toUpperCase()}
                    </LoadingButton> : organization.domain_state === 'completed' ? <Button variant="contained" color="success">{organization.domain_state.toUpperCase()}</Button> : <Button variant="contained" color="error">{'ERROR'}</Button>
                    }
                    </Typography><br/>
                    {organization && organization.domain_state && organization.domain_state === 'processing' && <Typography variant="h6" className={classes.text}>This process will take 2-3mins.</Typography>}
                    {organization && organization.domain_state && organization.domain_state === 'processing' && <br/>}
                    {organization && organization.full_domain && <Typography variant="h6" className={classes.text} id={`https://${organization.full_domain}`}>Domain: https://{organization && organization.full_domain} <IconButton onClick={() => { copyText(`https://${organization.full_domain}`)}} aria-label="copy"><ContentCopyIcon/></IconButton></Typography>}<br/>
                    {organization && organization.full_domain && <Typography className={classes.text}>When creation first completes, please allow for 30 seconds before attempting to go the web address<br/><br/></Typography>}
                    {/* Customize Domain */}
                    {organization && organization.full_domain && <div className={classes.text}>{<Button 
                        variant="contained"
                        onClick={() => { 
                            handleShowCreateOrgCustomDomain()
                        }} 
                    >ADD CUSTOM DOMAIN</Button>}&nbsp;{(organization && organization.cx_domain_cert && organization.cx_domain_cert.certificateArn) && <Button 
                    variant="contained"
                    color="error"
                    disabled={loading || ((organization && organization.cx_domain_cert && organization.cx_domain_cert.certificateArn) && !allCxDomainVerified)}
                    onClick={() => { 
                        handleShowDeleteOrgCustomDomain()
                    }} 
                >REMOVE CUSTOM DOMAIN</Button>}<br/><br/></div>}
                    {/* Template Version */}
                    {organization && templateUpdateAvail && <Typography className={classes.text}>Current Template Version: {`${organization.domain_template_version}`}<br/><br/></Typography>}
                    {organization && templateUpdateAvail && <Typography className={classes.text}><Button
                        disabled={loading || organization.domain_state === 'processing'} 
                        variant="contained"
                        onClick={() => { 
                            updateOrganizationTemplate({ id: organization._id }, {domain_template_version: getTemplateVersion(organization.domain_template) })
                        }} 
                    >Update Template! (New Version: {`${getTemplateVersion(organization.domain_template)}`})</Button><br/><br/></Typography>}
                    {(createdOrgAdminEmail || createdOrgAdminPass) && <div className={classes.orgAdminBox}>
                        {createdOrgAdminEmail && <Typography variant="h6" className={classes.text} id={`${createdOrgAdminEmail}`}>Admin Email: {createdOrgAdminEmail} <IconButton onClick={() => { copyText(`${createdOrgAdminEmail}`)}} aria-label="copy"><ContentCopyIcon/></IconButton><br/></Typography>}
                        {createdOrgAdminPass && <Typography variant="h6" className={classes.text} id={`${createdOrgAdminPass}`}>Admin Password: {createdOrgAdminPass} <IconButton onClick={() => { copyText(`${createdOrgAdminPass}`)}} aria-label="copy"><ContentCopyIcon/></IconButton><br/></Typography>}
                        {createdOrgAdminPass && createdOrgAdminEmail && <Typography className={classes.text}>The admin email and password will only appear once. Please save your account details. You may change your password upon logging into the account of your platform once deployment is completed.<br/></Typography>}
                    </div>}
                    {organization && organization.domain_state && <Typography className={classes.text}><br/><Button
                        variant="contained"
                        onClick={() => { goToVisualEditor("landing-page")}} 
                    >Design Landing Page</Button></Typography>}
                </Box>
            </Paper>
        </Slide>
        </Container>
    </main>
    )
}

export default Organization