export const toggleNavbarWhite = () => ({
    type: 'TOGGLE_NAVBAR_WHITE',
})

export const toggleNavbarTransparent = () => ({
    type: 'TOGGLE_NAVBAR_TRANSPARENT',
})

export const toggleAppbarWhiteFont = (isWhite) => ({
    type: 'APPBAR_FONT_COLOR',
    data: { isWhite: isWhite }
})