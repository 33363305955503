import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganizations, createOrganization, 
    getOrganization, updateOrganization, setOrganization, getNextOrganizations,
    updateOrganizationTemplate, createCustomDomainLink, getCustomDomainLink, deleteCustomDomainLink,
    checkNameSpace, resetCheckNameSpace 
} from '../actions'
import Organizations from '../components/Organizations'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
    organizations: state.organizations.organizations,
    organization: state.organizations.organization,
    totalCount: state.organizations.totalCount,
    loading: state.organizations.loading,
    createdOrgAdminEmail: state.organizations.createdOrgAdminEmail,
    createdOrgAdminPass: state.organizations.createdOrgAdminPass,
    nameSpaceAvailable: (state.organizations && state.organizations.name_space_available) || false,
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    setOrganization: (organization) => dispatch(setOrganization(organization)),
    createOrganization: (reqBody) => dispatch(createOrganization(reqBody)),
    getOrganizations: (query) => dispatch(getOrganizations(query)),
    getNextOrganizations: (query) => dispatch(getNextOrganizations(query)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    updateOrganization: (params, reqBody) => dispatch(updateOrganization(params, reqBody)),
    updateOrganizationTemplate: (params, reqBody) => dispatch(updateOrganizationTemplate(params, reqBody)),
    createCustomDomainLink: (params, reqBody) => dispatch(createCustomDomainLink(params, reqBody)),
    getCustomDomainLink: (params) => dispatch(getCustomDomainLink(params)),
    deleteCustomDomainLink: (params, reqBody) => dispatch(deleteCustomDomainLink(params, reqBody)),
    resetCheckNameSpace: () => dispatch(resetCheckNameSpace()),
    checkNameSpace: (reqBody) => dispatch(checkNameSpace(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Organizations)