import { connect } from 'react-redux'

import { getDesignAssets, createDesignAsset, updateDesignAsset } from '../actions'
import VisualEditor from '../components/VisualEditor'

const mapStateToProps = state => ({
    preview: state.app.preview,
})
  
const mapDispatchToProps = dispatch => ({
    getDesignAssets: (query) => dispatch(getDesignAssets(query)),
    createDesignAsset: (reqBody) => dispatch(createDesignAsset(reqBody)),
    updateDesignAsset: (params, reqBody) => dispatch(updateDesignAsset(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisualEditor)