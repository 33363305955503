import React, { useEffect, useState, createRef } from 'react';
import { Button } from '@mui/material';
import { useDrag } from 'react-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ComponentTypes } from './cTypes'

const MaterialPaper = (props) => {

    const { _id } = props

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        type: ComponentTypes.PAPER,
        item: {
            type: ComponentTypes.PAPER,
            id: _id,
            children: [], // can be list of strings
            elevation: 1, // by default, [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
            square: false, // by default
            variant: 'elevation', // by default ['elevation', 'outlined']
            'droppable': true,
            'draggable': true,
            'isSelected': false, // this is to show up on the toobox, right drawer and possibly show some css changes
            'isHovered': false, // possibly show some css changes
            'rootParentType': null,
            'isRendered': false,
            'minHeight': 100, // default
            'minHeightUnit': 'px', // default
        },
        collect: monitor => {
            return ({
                isDragging: !!monitor.isDragging(),
            })
        },
        end: (item, monitor) => {
        }
      }))

    return (
        isDragging ? (
        <Button
            ref={dragPreview}
            style={{
                opacity: 0.5,
                cursor: 'move',
                justifyContent: 'start'
            }}
            color={'success'}
            fullWidth
            startIcon={<DragIndicatorIcon />}
        >PAPER</Button>) : (
            <Button
            ref={drag}
            style={{
                opacity: 1,
                cursor: 'move',
                justifyContent: 'start',
            }}
            fullWidth
            startIcon={<DragIndicatorIcon />}
        >PAPER</Button>)
    )
}

export default MaterialPaper