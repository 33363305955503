import cookie from 'js-cookie';

function users(state ={}, action) {
    switch (action.type) {
      case 'SET_USER':
          return {...state,
            user: action.data.user,
            error: ""
          }
      case 'POST_CREATE_USER_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      // TODO DO A REGISTER USER FLOW
      case 'POST_CREATE_USER_SUCCESS':
          let createdUser = [action.data] || []
          if (createdUser.length) {
            createdUser[0].id = createdUser[0]._id
          }
          let newAllUsers = createdUser.concat(state.users)
          return {...state,
            users: newAllUsers,
            loading: false,
            success: `Successfully Created User! Time:${Date.now()}`
          }
      case 'POST_CREATE_USER_FAILURE':
        const createUserError = (action.response && action.response.data && action.response.data.message) || 'Something went wrong'
        return {...state,
          loading: false,
          error: createUserError
        }
      case 'POST_REGISTER_USER_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      // TODO DO A REGISTER USER FLOW
      case 'POST_REGISTER_USER_SUCCESS':
          return {...state,
            user: action.data,
            registrationSuccess: true,
            loading: false,
            error: ""
          }
      case 'POST_REGISTER_USER_FAILURE':
        const registerUserError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || 'Something went wrong'
        return {...state,
          loading: false,
          error: registerUserError
        }
      case 'RESET_REGISTER_USER_SUCCESS':
        return {...state,
          registrationSuccess: false,
        }
      case 'POST_LOGIN_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      // TODO DO A REGISTER USER FLOW
      case 'POST_LOGIN_SUCCESS':
        cookie.set('access_token', action.data.token)
        return {...state,
          user: action.data,
          loading: false,
          error: ""
        }
      case 'POST_LOGIN_FAILURE':
        const loginUserError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || 'Something went wrong'
        return {...state,
          loading: false,
          error: loginUserError
        }
      case 'GET_ME_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      // TODO DO A REGISTER USER FLOW
      case 'GET_ME_SUCCESS':
          return {...state,
            user: action.data,
            loading: false,
            error: ""
          }
      case 'GET_ME_FAILURE':
        const userMeError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: userMeError
        }
      case 'POST_RESET_PASSWORD_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      // TODO DO A REGISTER USER FLOW
      case 'POST_RESET_PASSWORD_SUCCESS':
          return {...state,
            user: action.data,
            loading: false,
            error: "",
            resetSuccess: true
          }
      case 'POST_RESET_PASSWORD_FAILURE':
        const resetPasswordError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || 'Something went wrong'
        return {...state,
          loading: false,
          error: resetPasswordError
        }
      case 'GET_USERS_REQUEST':
        return {...state,
          totalCount: 0,
          loading: true,
          error: ""
        }
      // TODO DO A REGISTER USER FLOW
      case 'GET_USERS_SUCCESS':
          const totalUsersArr = (action.data && action.data.users) || (action.data) || []
          let transformUsers = totalUsersArr.map((eachUser) => {
            eachUser.id = eachUser._id
            return eachUser
          })
          return {...state,
            totalCount: (action.data && action.data.totalCount) || 0,
            users: transformUsers,
            loading: false,
            error: ""
          }
      case 'GET_USERS_FAILURE':
        const usersError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          totalCount: 0,
          loading: false,
          error: usersError
        }
      case 'GET_NEXT_USERS_REQUEST':
        return {...state,
          totalCount: 0,
          loading: true,
          error: ""
        }
      // TODO DO A REGISTER USER FLOW
      case 'GET_NEXT_USERS_SUCCESS':
          const totalNextUsersArr = (action.data && action.data.users) || (action.data) || []
          console.log('totalNextUsersArr : ', totalNextUsersArr);
          let transformNextUsers = totalNextUsersArr.map((eachUser) => {
            eachUser.id = eachUser._id
            return eachUser
          })
          let newTotalUsers = transformNextUsers.concat(state.users)
          // console.log(newTotalUsers.length);
          return {...state,
            totalCount: (action.data && action.data.totalCount) || 0,
            users: newTotalUsers,
            loading: false,
            error: ""
          }
      case 'GET_NEXT_USERS_FAILURE':
        const nextUsersError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          totalCount: 0,
          loading: false,
          error: nextUsersError
        }
      case 'PUT_UPDATE_USER_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'PUT_UPDATE_USER_SUCCESS':
          let updatedUserId = (action.data && action.data._id) || ""
          const usersWithNewUser = state.users.map((eachUser) => {
            if(eachUser._id === updatedUserId) {
              let updatedUserData = action.data
              updatedUserData.id = updatedUserData._id
              return updatedUserData
            }
            return eachUser
          })
          return {...state,
            loading: false,
            userUpdated: action.data,
            users: usersWithNewUser,
            error: "",
            success: `Successfully Updated User! Time:${Date.now()}`
          }
      case 'PUT_UPDATE_USER_FAILURE':
        const userUpdatedError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: userUpdatedError
        }
      default:
        return state
    }
  }

export default users