import { combineReducers } from 'redux'
import users from './users'
import organizations from './organizations'
import navDrawers from './navDrawers'
import navBar from './navBar'
import components from './components'
import app from './app'
import designAssets from './designAssets'


const mainApp = combineReducers({
    users,
    navDrawers,
    navBar,
    organizations,
    app,
    components,
    designAssets
  })

export default mainApp