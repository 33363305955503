import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { Drawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import { cssForms } from '../utils/components'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import logo from '../logo.svg'
import { Link, useHistory } from "react-router-dom"

const VisualEditorBar = (props) => {
    const { 
        preview, togglePreview, selectComponent, components, setComponentsTree, 
        landingPageComponentsId, landingPageComponents, createDesignAsset, updateDesignAsset,
        getDesignAssets, getLandingPage
    } = props
    const history = useHistory();

    const [title, setTitle] = useState("");
    const [organizationId, setOrganizationId] = useState("");

    const hiddenInputRef = React.createRef();

    const handleTogglePreview = () => {
        togglePreview()
        selectComponent({})
    }

    const handleBack = () => {
        history.push({
            pathname: `/organizations`,
        })
    }

    useEffect(() => {
        let currUrl = window.location.href.split("/")
        let currentEdit = currUrl.pop()
        setTitle(currentEdit)
        const orgID = currUrl.pop()
        setOrganizationId(orgID)
        getLandingPage({
            'filter[key]': 'landing-page-components',
            organizationId: orgID,
            count: 100,
            page: 1
        })
      }, [])

    const handleSaveComponents = () => {
        let reqBody = {
            value: JSON.parse(JSON.stringify(components))
        }
        if (landingPageComponentsId) {
            updateDesignAsset({ id: landingPageComponentsId }, reqBody)
        } else {
            if (!organizationId) return
            reqBody.key = "landing-page-components"
            reqBody.organizationId = organizationId
            createDesignAsset(reqBody)
        }
    }

    const handleReset = () => {
        setComponentsTree(landingPageComponents)
    }

    const downloadObjectAsJson = (exportObj, exportName) => {
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    const jsonUpload = (eventFile) => {
        let file = eventFile[0]
        console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            return;
        }
        switch (file.type) {
            case 'application/json':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            alert('Invalid file type')
            return;
        }
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = readerEvent => {
            const content = readerEvent.target.result
            setComponentsTree(JSON.parse(content))
        }
    }

    return (
            <AppBar
                color="primary"
                style={{ 
                    marginLeft: preview ? 0 : 250, 
                    width: preview ? window.innerWidth : window.innerWidth - 250 - 350,
                    position: 'inherit',
                    backgroundColor: '#001e3c',
                }}
            >
                <Toolbar
                >
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        <Button
                            variant="h6"
                            onClick={() => {
                                handleBack()
                            }}
                        >
                            {`< BACK`}
                        </Button>
                        {title && title.toUpperCase()}
                    </Typography>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={preview} onChange={handleTogglePreview} />} label="Preview" />
                    </FormGroup>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleReset()
                        }}
                    >
                            {`RESET`}
                    </Button>&nbsp;
                    <input ref={hiddenInputRef} accept="application/json" style={{display: 'none'}} onChange={(e) => {jsonUpload(e.target.files)}} id="image-button-file" type="file" />
                    <Button
                        variant="contained"
                        onClick={() => {
                            hiddenInputRef.current.click()
                        }}
                    >
                            {`IMPORT`}
                    </Button>&nbsp;
                    <Button
                        variant="contained"
                        onClick={() => {
                            downloadObjectAsJson(components, "elements")
                        }}
                    >
                            {`EXPORT`}
                    </Button>&nbsp;
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleSaveComponents()
                        }}
                    >
                            {`SAVE`}
                    </Button>
                </Toolbar>
            </AppBar>
    );
}

export default VisualEditorBar