import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { grey, green, lightBlue } from '@mui/material/colors';
import { Translate } from '@material-ui/icons';
import { Paper } from '@mui/material';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import './Home.css'

const creativeImage = require('../assets/digitalize.jpg')
const header1 = require('../assets/header1.png')
const header3 = require('../assets/header3.png')
const header4 = require('../assets/header4.png')
const driving = require('../assets/driving.jpeg')
const swimming = require('../assets/swimming.jpeg')
const piano = require('../assets/piano.jpeg')
const tuition = require('../assets/tuition.jpeg')
const marketing = require('../assets/marketing.png')
const marketing2 = require('../assets/marketing2.png')
const marketing3 = require('../assets/marketing3.png')
const paymgmt = require('../assets/paymgmt.png')
const cert = require('../assets/cert.png')
const nocode = require('../assets/nocode.png')


const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    onePage: {
        overflow: 'hidden'
    },
    mainHeader: {
        minHeight: '10em',
        // backgroundImage: `url(${creativeImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'grid',
        position: 'relative',
        backgroundPosition: 'center',
        '@media (max-width:600px)': {
            minHeight: '35em',
            backgroundPosition: 'center',
            marginLeft: 0,
        },
        background: `linear-gradient(210deg,#59bbb9 0%, ${green[200]} 100%)` ,// '##fafafa',
    },
    mainHeaderContainer: {
        height: '100%',
        width: '100%',
        padding: '1em',
        '@media (max-width:600px)': {
            maxHeight: '99vh',
        },
    },
    secondSection: {
        minHeight: '10em',
        // backgroundImage: `url(${creativeImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'grid',
        position: 'relative',
        '@media (max-width:600px)': {
            minHeight: '35em',
            backgroundPosition: 'center',
            marginLeft: 0,
        },
    },
    secondSectionContainer: {
        backgroundColor: '##fafafa', // green[100]
        height: '100%',
        width: '100%',
        padding: '1em',
        '@media (max-width:600px)': {
            maxHeight: '99vh',
        },
    },
    header: {
        marginLeft: '5em',
        maxHeight: '20em',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        marginTop: '10em',
        borderRadius: '5px',
        '@media (max-width:600px)': {
            marginLeft: 0,
        },
    },
    header1: {
        marginLeft: '5em',
        '@media (max-width:600px)': {
            display: 'none',
        },
    },
    header3: {
        transform: 'translateX(-50%) translateY(-10%)',
        '@media (max-width:600px)': {
            transform: 'translateX(0%) translateY(0%)',
            maxWidth: '100%'
        },
    },
    header4: {
        transform: 'translateX(-100%)',
        marginLeft: '-5em',
        '@media (max-width:600px)': {
            transform: 'translateX(110%) translateY(-145%)',
            maxWidth: '100%',
            boxShadow: '20px 50px 100px -20px rgba(50,50,93,.75), 20px 30px 60px -30px rgba(0,0,0,.75), inset 20px 20px 20px 20px rgba(10,37,64,.75)',
            marginLeft: '0em'
        },
    },
    schType: {
        padding: '1em',
        minHeight: '500px'
    },
    schTypeContainer: {
        maxWidth: '70%',
        margin: '0 auto',
        marginTop: '5em',
        '@media (max-width:600px)': {
            maxWidth: '100%',
        },
    },
    thirdSection: {
        // backgroundImage: `url(${creativeImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'grid',
        position: 'relative',
        backgroundPosition: 'center',
        '@media (max-width:600px)': {
            minHeight: '35em',
            backgroundPosition: 'center',
            marginLeft: 0,
            marginTop: '30em'
        },
    },
    thirdSectionContainer: {
        backgroundColor: '#59bbb9' ,// '##fafafa',
        height: '100%',
        width: '100%',
        padding: '1em',
        textAlign: 'center',
    },
    forthSectionContainer: {
        backgroundColor: '#59bbb9' ,// '##fafafa',
        height: '100%',
        width: '100%',
        padding: '1em',
        textAlign: 'center',
        minHeight: '30vh',
        display: 'none',
        '@media (max-width:600px)': {
            display: 'block',
        },
    },
    marketing: {
        maxHeight: '30em',
        borderRadius: '5px',
    },
    marketing1: {
        transform: 'translateX(-200%) translateY(-100%)',
        maxWidth: "150px",
        '@media (max-width:600px)': {
            transform: 'translateX(100%) translateY(-240%)',
            maxWidth: "150px",
        },
    },
    marketing2: {
        position: 'absolute',
        top: '5em',
        '@media (max-width:600px)': {
            display: 'none'
        },
    },
    marketingmobile: {
        marginLeft: '5em',
        '@media (max-width:600px)': {
            marginLeft: '-10em'
        },
    },
    marketingWordSection: {
        color: 'white',
        fontWeight: 400,
        '@media (max-width:600px)': {
            display: 'none'
        },
    },
    marketingSubWord: { 
        color: '#42474c',
        '@media (max-width:600px)': {
            display: 'none'
        },
    },
    marketingMobileWordSection: {
        color: 'white',
        fontWeight: 400,
    },
    marketingSection: { 
        margin: '0 auto', 
        maxWidth: '80%',
        '@media (max-width:600px)': {
            height: '50vh'
        },
    },
    manageWordSection: {
        color: 'white',
        fontWeight: 400,
        marginTop: '2em',
        textAlign: 'right',
        '@media (max-width:600px)': {
            marginTop: 0,
            textAlign: 'left',
        },
    },
    manageSubWording: { 
        color: '#42474c', 
        position: 'absolute', 
        right: 0,
        '@media (max-width:600px)': {
            position: 'relative', 
            textAlign: 'left',
        },
    },
    manageSection: { 
        position: 'relative',
        '@media (max-width:600px)': {
            maxWidth: '360px', 
        },
    },
    manageImageSection: {
        marginLeft: '30%',
        '@media (max-width:600px)': {
            marginLeft: 0,
        },
    },
    paymgmt: {
        maxWidth: '450px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        borderRadius: '5px',
        '@media (max-width:600px)': {
            marginTop: '2em',
            maxWidth: '350px',
        },
    },
    cert: {
        maxWidth: '450px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        transform: 'translateX(20%) translateY(-50%)',
        borderRadius: '5px',
        '@media (max-width:600px)': {
            marginTop: '2em',
            maxWidth: '350px',
            transform: 'translateX(0%) translateY(-100%)',
        },
    },
    nocode: {
        maxWidth: '450px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        transform: 'translateX(-40%) translateY(10%)',
        borderRadius: '5px',
        '@media (max-width:600px)': {
            marginTop: '2em',
            maxWidth: '350px',
            transform: 'translateX(10%) translateY(-50%)',
        },
    },
    introVideo: {
        width: "560px",
        height: "315px",
        '@media (max-width:600px)': {
            width: "350px",
            height: "200px",
        },
    },
    startTrialEnd: {
        display: 'none',
        '@media (max-width:600px)': {
            display: 'block',
            transform: 'translateX(0%) translateY(100%)',
        },
    },
    landingBrowser: {
        maxHeight: '678px',
        marginTop: '7em',
        marginLeft: '14em',
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            maxHeight: '500px',
            marginLeft: '7em',
            marginTop: '7em',
        },
        [theme.breakpoints.down('md')]: {
            maxHeight: '400px',
            marginLeft: '0em',
            marginTop: '7em',
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: '300px',
            marginLeft: '0em',
            marginTop: '3em',
        },
    },
}));

const Home = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarTransparent } = props
    let speed = 50
    let webnameOrgZIndex = 0
    let organzationNameIndex = 0
    let webNameInputTimers = []
    let orgNameInputTimers = []
    let browserTimers = []

    const typeWriter = async (textId, text, i) => {
        if (document.getElementById(textId) && document.getElementById(textId).children && document.getElementById(textId).children[0]) {
            // check if children exists
        } else {
            return
        }
        if (i < text.length) {
            document.getElementById(textId).children[0].innerHTML = document.getElementById(textId).children[0].innerHTML + text.charAt(i);
            i++;
            browserTimers.push(setTimeout( () => {
                let targetIndex = typeWriter(textId, text, i)
                if (textId === "webnameOrgZ") webnameOrgZIndex = targetIndex
                if (textId === "OrganzationName") organzationNameIndex = targetIndex
            }, speed));
        } else {
            for (var i = 0; i < browserTimers.length; i++) {
                clearTimeout(browserTimers[i]);
            }
        }
        return i
    }

    const animateWebNameInput = async () => {
        let webName = document.getElementById("webnameOrgZ")
        let webNamePlaceHolder = document.getElementById("webNamePlaceHolder")
        if ((webName && webName.children && webName.children[0]) && (webNamePlaceHolder)) {

        } else {
            return
        }
        webName.children[0].innerHTML = ""
        webnameOrgZIndex = 0 
        await new Promise(resolve => webNameInputTimers.push(setTimeout(resolve, 3000)));
        webNamePlaceHolder.style.display = "none"
        typeWriter("webnameOrgZ", "academy-x", webnameOrgZIndex)
        await new Promise(resolve =>  webNameInputTimers.push(setTimeout(resolve, 7000)));
        for (var i = 0; i < webNameInputTimers.length; i++) {
            clearTimeout(webNameInputTimers[i]);
        }
        webNamePlaceHolder.style.display = "block"
        animateWebNameInput()
    }

    const animateOrgNameInput = async () => {
        let orgName = document.getElementById("OrganzationName")
        let orgNamePlaceHolder = document.getElementById("OrgnNamePlaceHolder")
        if ((orgName && orgName.children && orgName.children[0]) && (orgNamePlaceHolder)) {

        } else {
            return
        }
        orgName.children[0].innerHTML = ""
        organzationNameIndex = 0 
        await new Promise(resolve => orgNameInputTimers.push(setTimeout(resolve, 2000)));
        orgNamePlaceHolder.style.display = "none"
        typeWriter("OrganzationName", "Academy X", organzationNameIndex)
        await new Promise(resolve =>  orgNameInputTimers.push(setTimeout(resolve, 8000)));
        for (var i = 0; i < orgNameInputTimers.length; i++) {
            clearTimeout(orgNameInputTimers[i]);
        }
        orgNamePlaceHolder.style.display = "block"
        animateOrgNameInput()
    }

    useEffect(() => {
        toggleNavbarTransparent(true)
        document.getElementById("CreateOrgBtn").style.animation = "createPopup 10s infinite";
        document.getElementById("CreateForm").style.animation = "createFormSlide 10s infinite";
        document.getElementById("CreateApiBtn").style.animation = "createBtnPop 10s infinite";
        document.getElementById("OrganizationDetail").style.animation = "organizationDetailSlide 10s infinite";
        document.getElementById("PastOrganization").style.animation = "oldOrgSlide 10s infinite";
        document.getElementById("completeBtn").style.animation = "completeBtnAppear 10s infinite";
        document.getElementById("orgZdomainLink").style.animation = "completeBtnAppear 10s infinite";
        document.getElementById("orgZdomainWait").style.animation = "processBtnDissappear 10s infinite";
        document.getElementById("processingBtn").style.animation = "processBtnDissappear 10s infinite";
    
        document.getElementById("CreateOrg").style.animation = "CreateOrgPageSlide 10s infinite";
        document.getElementById("OrgWebFrame").style.animation = "academyWebSlide 10s infinite";
        document.getElementById("MobileFrame").style.animation = "mobileWebSlide 10s infinite";
        animateWebNameInput()
        animateOrgNameInput()

        return () => {
            for (var i = 0; i < webNameInputTimers.length; i++) {
                clearTimeout(webNameInputTimers[i]);
            }
            for (var i = 0; i < orgNameInputTimers.length; i++) {
                clearTimeout(orgNameInputTimers[i]);
            }
            for (var i = 0; i < browserTimers.length; i++) {
                clearTimeout(browserTimers[i]);
            }            
        };
    },[])
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
    setOpen(false);
    };



    return (<div className={classes.onePage}>
            <div className={classes.mainHeader}>
                <Container maxWidth={'xl'}>
                    <Grid container className={classes.mainHeaderContainer}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Typography variant="h2" style={{
                                marginTop: '20%',
                                color: 'white',
                                fontWeight: 400
                            }}>
                                The platform <br/>
                                for your digital academy.
                            </Typography>
                            <br/>
                            <Link className={classes.link} to="/register">
                            <Button 
                                variant={'contained'} 
                                style={{
                                    backgroundColor: "#59bbb9",
                                    color: 'white'
                                }}
                            >Start Free Trial</Button></Link><br/><br/>
                            <span style={{color: '#42474c'}}>Try Liberalize Academy free for 14 days, no credit card required.</span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <svg className={classes.landingBrowser} width="1352" height="758" viewBox="0 0 1352 758" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="MainFrame">
                                <g id="Browser" filter="url(#filter0_d_2_2)">
                                    <rect x="23" y="20" width="1111" height="680" rx="5" fill="white" />
                                    <g id="browserBar">
                                    <rect id="Rectangle 1" x="23" y="20" width="1111" height="44" fill="#CACACA" />
                                    <circle id="Ellipse 1" cx="48" cy="42" r="7" fill="#FF7373" />
                                    <circle id="Ellipse 2" cx="70" cy="42" r="7" fill="#FFD600" />
                                    <circle id="Ellipse 3" cx="92" cy="42" r="7" fill="#15D804" />
                                    </g>
                                    <g id="CreateOrg">
                                    <g id="CreateForm">
                                        <g id="CreateApiBtn">
                                        <rect id="CreateBtn" x="631" y="536" width="439" height="52" rx="4" fill="#0094FF" />
                                        <text id="create" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="13" font-weight="600" letter-spacing="0em">
                                            <tspan x="823" y="565.727">CREATE</tspan>
                                        </text>
                                        </g>
                                        <g id="TemplateSelection">
                                        <rect id="TemplateInput" x="628" y="422" width="440" height="54" rx="3" fill="white" stroke="#BCBCBC"
                                            stroke-width="2" />
                                        <text id="OriginalTemplateSelected" fill="#0F0F0F" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="16" letter-spacing="0em">
                                            <tspan x="645" y="455.318">Original Template</tspan>
                                        </text>
                                        <path id="Polygon 1" d="M1044.11 460.065L1032.77 442.871L1055.29 442.759L1044.11 460.065Z"
                                            fill="#D9D9D9" />
                                        </g>
                                        <g id="WebName">
                                        <rect id="WebNameInPut" x="711" y="307" width="203" height="54" rx="3" fill="white" stroke="#BCBCBC"
                                            stroke-width="2" />
                                        <text id="webnameOrgZ" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="16" letter-spacing="0em">
                                            <tspan x="727" y="340.318">
                                            </tspan>
                                        </text>
                                        <text id="webNamePlaceHolder" fill="#949494" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="16" letter-spacing="0em">
                                            <tspan x="724" y="340.318">Web Name Space *</tspan>
                                        </text>
                                        <text id="HTTPS://" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="16" letter-spacing="0em">
                                            <tspan x="627" y="340.318">HTTPS://</tspan>
                                        </text>
                                        <text id=".liberalize.academy" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="16" letter-spacing="0em">
                                            <tspan x="924" y="340.318">.liberalize.academy</tspan>
                                        </text>
                                        </g>
                                        <g id="OrgName">
                                        <rect id="OrgNameInput" x="626" y="192" width="443" height="54" rx="3" fill="white" stroke="#BCBCBC"
                                            stroke-width="2" />
                                        <text id="OrgnNamePlaceHolder" fill="#949494" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="16" letter-spacing="0em">
                                            <tspan x="651.451" y="225.318">Organisation Name *</tspan>
                                        </text>
                                        <text id="OrganzationName" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="16" letter-spacing="0em">
                                            <tspan x="651.451" y="225.318">
                                            </tspan>
                                        </text>
                                        </g>
                                        <text id="Create Organisation" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                        font-family="Roboto" font-size="20" letter-spacing="0em">
                                        <tspan x="625" y="159.273">Create Organisation</tspan>
                                        </text>
                                        <rect x="588.5" y="106.5" width="521" height="543" stroke="black" />
                                    </g>
                                    <g id="OrganizationDetail">
                                        <g id="DesignBtn">
                                        <rect id="DesignBtnBox" x="625" y="340" width="439" height="52" rx="4" fill="#0094FF" />
                                        <text id="design_landingpage" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="20" font-weight="600" letter-spacing="0em">
                                            <tspan x="732" y="373.273">DESIGN LANDING PAGE</tspan>
                                        </text>
                                        </g>
                                        <g id="CustomDomainBtn">
                                        <rect id="CustomBtnBox" x="625" y="430" width="439" height="52" rx="4" fill="#0094FF" />
                                        <text id="ADD CUSTOM DOMAIN" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="20" font-weight="600" letter-spacing="0em">
                                            <tspan x="736" y="465.273">ADD CUSTOM DOMAIN</tspan>
                                        </text>
                                        </g>
                                        <text id="orgZdomainLink" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="16" font-weight="500" letter-spacing="0em">
                                        <tspan x="628" y="298.318">Domain: https://academy-x.liberalize.academy</tspan>
                                        </text>
                                        <text id="orgZdomainWait" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="16" font-weight="500" letter-spacing="0em">
                                        <tspan x="628" y="298.318">This process will take 2-3 mins.</tspan>
                                        </text>
                                        <g id="processingBtn">
                                        <rect id="webstatusbtn" x="832.5" y="201.5" width="152" height="43" rx="3.5" fill="white"
                                            stroke="#939393" />
                                        <text id="PROCESSING" fill="#939393" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="16" font-weight="500" letter-spacing="0em">
                                            <tspan x="855" y="229.318">PROCESSING</tspan>
                                        </text>
                                        </g>
                                        <g id="completeBtn">
                                        <rect id="webstatusGreenbtn" x="832.5" y="201.5" width="152" height="43" rx="3.5" fill="#2E7D32"
                                            stroke="#2E7D32" />
                                        <text id="COMPLETED" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="16" font-weight="500" letter-spacing="0em">
                                            <tspan x="855" y="229.318">COMPLETED</tspan>
                                        </text>
                                        </g>
                                        <text id="webstatus" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="16" font-weight="500" letter-spacing="0em">
                                        <tspan x="631" y="229.318">Web Application Status: </tspan>
                                        </text>
                                        <text id="Academy X" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="20" letter-spacing="0em">
                                        <tspan x="625" y="159.273">Academy X</tspan>
                                        </text>
                                        <rect x="588.5" y="106.5" width="521" height="543" stroke="black" />
                                    </g>
                                    <g id="AcademyX">
                                        <rect id="AcademyXBox" x="93" y="257" width="284" height="54" rx="3" fill="white" stroke="black"
                                        stroke-width="2" />
                                        <text id="552c5f3b0c7c403fc174c61a" fill="#949494" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                        font-family="Roboto" font-size="14" letter-spacing="0em">
                                        <tspan x="150" y="297.591">552c5f3b0c7c403fc174c61a</tspan>
                                        </text>
                                        <text id="AcademyX_2" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="14" font-weight="600" letter-spacing="0em">
                                        <tspan x="184" y="278.591">Academy X</tspan>
                                        </text>
                                    </g>
                                    <g id="PastOrganization">
                                        <g id="OrganizationY">
                                        <rect id="OrganizationY_2" x="93" y="342" width="284" height="54" rx="3" fill="white" stroke="black"
                                            stroke-width="2" />
                                        <text id="62946838f66c7c62d37f4719" fill="#949494" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="14" letter-spacing="0em">
                                            <tspan x="135" y="382.591">62946838f66c7c62d37f4719</tspan>
                                        </text>
                                        <text id="Organisation Y" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="14" font-weight="600" letter-spacing="0em">
                                            <tspan x="184" y="363.591">Organisation Y</tspan>
                                        </text>
                                        </g>
                                        <g id="OrganizationX">
                                        <rect id="OrganizationX_2" x="93" y="257" width="284" height="54" rx="3" fill="white" stroke="black"
                                            stroke-width="2" />
                                        <text id="629c5f3b0c7c403fc174c61c" fill="#949494" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                            font-family="Roboto" font-size="14" letter-spacing="0em">
                                            <tspan x="150" y="297.591">629c5f3b0c7c403fc174c61c</tspan>
                                        </text>
                                        <text id="Organisation X" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                            font-size="14" font-weight="600" letter-spacing="0em">
                                            <tspan x="184" y="278.591">Organisation X</tspan>
                                        </text>
                                        </g>
                                    </g>
                                    <g id="CreateOrgBtn">
                                        <rect id="CreateBtn_2" x="76" y="163" width="186" height="43" rx="3" fill="white" stroke="#0094FF"
                                        stroke-width="2" />
                                        <text id="CREATE ORGANISATION" fill="#0094FF" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                        font-family="Roboto" font-size="13" font-weight="600" letter-spacing="0em">
                                        <tspan x="92" y="188.727">CREATE ORGANISATION</tspan>
                                        </text>
                                    </g>
                                    <text id="Organisations" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="20" letter-spacing="0em">
                                        <tspan x="75" y="139.273">Organisations</tspan>
                                    </text>
                                    </g>
                                    <g id="OrgWebFrame" clip-path="url(#clip0_2_2)">
                                    <g id="Banner">
                                        <rect id="BannerBox" x="23" y="64" width="1111" height="281" fill="#3EB7FB" />
                                        <text id="Academy X_2" fill="white" stroke="black" xmlSpace="preserve" style={{whiteSpace: "pre"}}
                                        font-family="Roboto" font-size="36" font-weight="600" letter-spacing="0em">
                                        <tspan x="92" y="217.091">Academy X</tspan>
                                        </text>
                                        <rect id="JoinBtn" x="92" y="257" width="167" height="47" rx="4" fill="#0075FF" />
                                        <text id="JOIN NOW" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="20" font-weight="600" letter-spacing="0em">
                                        <tspan x="120" y="287.273">JOIN NOW</tspan>
                                        </text>
                                    </g>
                                    <g id="courses">
                                        <g id="course1" filter="url(#filter1_d_2_2)">
                                        <rect x="150" y="497" width="218" height="203" fill="#D9D9D9" />
                                        </g>
                                        <text id="Our Courses" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="20" font-weight="600" letter-spacing="0em">
                                        <tspan x="75" y="401.273">Our Courses</tspan>
                                        </text>
                                        <g id="course2" filter="url(#filter2_d_2_2)">
                                        <rect x="460" y="497" width="218" height="203" fill="#D9D9D9" />
                                        </g>
                                        <g id="course3" filter="url(#filter3_d_2_2)">
                                        <rect x="787" y="497" width="218" height="203" fill="#D9D9D9" />
                                        </g>
                                        <text id="Course1text" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="20" font-weight="600" letter-spacing="0em">
                                        <tspan x="175" y="538.273">Course 1</tspan>
                                        </text>
                                        <text id="Course2text" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="20" font-weight="600" letter-spacing="0em">
                                        <tspan x="477" y="538.273">Course 2</tspan>
                                        </text>
                                        <text id="Cours3text" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                        font-size="20" font-weight="600" letter-spacing="0em">
                                        <tspan x="809" y="538.273">Course 3</tspan>
                                        </text>
                                    </g>
                                    </g>
                                </g>
                                <g id="MobileFrame">
                                    <g id="MobileCase" filter="url(#filter4_d_2_2)">
                                    <rect x="1071" y="269" width="261" height="470" rx="10" fill="#D9D9D9" />
                                    </g>
                                    <rect id="MobileScreen" x="1076" y="275" width="250" height="458" rx="4" fill="#FEFDFD" />
                                    <g id="Mobilecourse1" filter="url(#filter5_d_2_2)">
                                    <rect x="1093" y="568" width="214" height="153" fill="#D9D9D9" />
                                    </g>
                                    <text id="MobileCourse1text" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                    font-size="20" font-weight="600" letter-spacing="0em">
                                    <tspan x="1110" y="604.273">Course 1</tspan>
                                    </text>
                                    <rect id="mobileBannerBox" x="1076" y="275" width="250" height="238" rx="2" fill="#3EB7FB" />
                                    <rect id="MobileJoinBtn" x="1088" y="453" width="114" height="36" rx="4" fill="#0075FF" />
                                    <text id="mobileJoinNowText" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                    font-size="14" font-weight="600" letter-spacing="0em">
                                    <tspan x="1102" y="477.591">JOIN NOW</tspan>
                                    </text>
                                    <text id="MobileOurCourses" fill="black" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                    font-size="20" font-weight="600" letter-spacing="0em">
                                    <tspan x="1088" y="550.273">Our Courses</tspan>
                                    </text>
                                    <line id="menu3" x1="1084" y1="285" x2="1119" y2="285" stroke="white" stroke-width="2" />
                                    <line id="menu2" x1="1084" y1="294" x2="1119" y2="294" stroke="white" stroke-width="2" />
                                    <line id="menu1" x1="1084" y1="303" x2="1119" y2="303" stroke="white" stroke-width="2" />
                                    <text id="Academy X_3" fill="white" xmlSpace="preserve" style={{whiteSpace: "pre"}} font-family="Roboto"
                                    font-size="20" font-weight="600" letter-spacing="0em">
                                    <tspan x="1088" y="445.273">Academy X</tspan>
                                    </text>
                                </g>
                                </g>
                                <defs>
                                <filter id="filter0_d_2_2" x="17" y="18" width="1123" height="692" filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2_2" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2" result="shape" />
                                </filter>
                                <filter id="filter1_d_2_2" x="144" y="495" width="230" height="215" filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2_2" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2" result="shape" />
                                </filter>
                                <filter id="filter2_d_2_2" x="454" y="495" width="230" height="215" filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2_2" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2" result="shape" />
                                </filter>
                                <filter id="filter3_d_2_2" x="781" y="495" width="230" height="215" filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2_2" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2" result="shape" />
                                </filter>
                                <filter id="filter4_d_2_2" x="1065" y="267" width="273" height="482" filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2_2" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2" result="shape" />
                                </filter>
                                <filter id="filter5_d_2_2" x="1087" y="566" width="226" height="165" filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2_2" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2" result="shape" />
                                </filter>
                                <clipPath id="clip0_2_2">
                                    <rect width="1111" height="636" fill="white" transform="translate(23 64)" />
                                </clipPath>
                                </defs>
                            </svg>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.secondSection}>
                <Container maxWidth={'xl'}>
                <Grid container className={classes.secondSectionContainer}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography 
                            variant="h3" 
                            style={{
                                color: '#59bbb9',
                                fontWeight: 400,
                            }}
                        >
                                Create and design your own academy website
                        </Typography>
                        <span style={{
                            color: '#59bbb9',
                            fontWeight: 400,
                            fontSize: '1em'
                        }}><b>Backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.</b></span>
                    </Grid>
                    <Grid container className={classes.schTypeContainer}>
                        {
                            [swimming, tuition, piano, driving].map((eachType, index) => {
                                return <Grid 
                                    item xs={6} sm={6} md={3}
                                    className={classes.schType}
                                >
                                    <Paper
                                        elevation={12}
                                        sx={{
                                            backgroundImage: `url(${eachType})`,
                                            height: '100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover'
                                        }}
                                    >
                                        <div 
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: '#0000007d',
                                                position: 'relative'
                                            }}
                                        >
                                            <Typography 
                                                variant="h4"
                                                style={{ 
                                                    position: 'absolute',
                                                    textAlign: 'center',
                                                    bottom: 0,
                                                    left: '10px',
                                                    color: 'white'
                                                }}
                                            >
                                                {index === 0 ? 'Sport Academies' : index === 1 ? 'Private Tuition' : index === 2 ? 'Music Academies' : 'Driving Lessons'}
                                            </Typography>
                                        </div>
                                    </Paper>
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid>
                </Container>
            </div>
            <div className={classes.thirdSection}>
                <Grid container className={classes.thirdSectionContainer}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography 
                                variant="h2"
                                className={classes.marketingMobileWordSection}
                            >
                                Market your academy
                        </Typography>
                        <span className={classes.marketingMobileWordSection}>Make aware your digital presences and scale your academy.<br/><br/><br/></span>
                        <iframe className={classes.introVideo} src="https://www.youtube.com/embed/Iwp1vjm1iAE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Grid>
                </Grid>
                <Grid container className={classes.forthSectionContainer}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box textAlign='center' className={classes.startTrialEnd}>
                            <Link className={classes.link} to="/register">
                                <Button variant={'contained'} style={{
                                    backgroundColor: green[200],
                                    color: 'white'
                                }}>Start Free Trial</Button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>)
}

export default Home