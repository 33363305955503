import { CALL_API } from '../app/middleware/organizationApi';

export const setOrganization = (organization) => ({
    type: 'SET_ORGANIZATION',
    data: { organization: organization }
})

export const createOrganization = (reqBody) => ({
    types: ['POST_CREATE_ORGANIZATION_REQUEST', 'POST_CREATE_ORGANIZATION_SUCCESS', 'POST_CREATE_ORGANIZATION_FAILURE'],
    [CALL_API]: '/organizations',
    method: 'post',
    reqBody: reqBody,
})

export const getOrganizations = (query) => ({
    types: ['GET_ORGANIZATIONS_REQUEST', 'GET_ORGANIZATIONS_SUCCESS', 'GET_ORGANIZATIONS_FAILURE'],
    [CALL_API]: '/organizations',
    method: 'get',
    query: query
})

export const getNextOrganizations = (query) => ({
    types: ['GET_NEXT_ORGANIZATIONS_REQUEST', 'GET_NEXT_ORGANIZATIONS_SUCCESS', 'GET_NEXT_ORGANIZATIONS_FAILURE'],
    [CALL_API]: '/organizations',
    method: 'get',
    query: query
})

export const getOrganization = (params) => ({
    types: ['GET_ORGANIZATION_REQUEST', 'GET_ORGANIZATION_SUCCESS', 'GET_ORGANIZATION_FAILURE'],
    [CALL_API]: '/organizations/:id',
    method: 'get',
    params: params
})

export const updateOrganization = (params, reqBody) => ({
    types: ['PUT_UPDATE_ORGANIZATION_REQUEST', 'PUT_UPDATE_ORGANIZATION_SUCCESS', 'PUT_UPDATE_ORGANIZATION_FAILURE'],
    [CALL_API]: '/organizations/:id',
    method: 'put',
    params: params,
    reqBody: reqBody,
})

export const updateOrganizationTemplate = (params, reqBody) => ({
    types: ['UPDATE_ORGANIZATION_TEMPLATE_REQUEST', 'UPDATE_ORGANIZATION_TEMPLATE_SUCCESS', 'UPDATE_ORGANIZATION_TEMPLATE_FAILURE'],
    [CALL_API]: '/organizations/:id/update-template-version',
    method: 'put',
    params: params,
    reqBody: reqBody,
})

export const createCustomDomainLink = (params, reqBody) => ({
    types: ['CREATE_CUSTOM_DOMAIN_REQUEST', 'CREATE_CUSTOM_DOMAIN_SUCCESS', 'CREATE_CUSTOM_DOMAIN_FAILURE'],
    [CALL_API]: '/organizations/:id/create-custom-domain',
    method: 'put',
    params: params,
    reqBody: reqBody,
})

export const getCustomDomainLink = (params) => ({
    types: ['GET_CUSTOM_DOMAIN_REQUEST', 'GET_CUSTOM_DOMAIN_SUCCESS', 'GET_CUSTOM_DOMAIN_FAILURE'],
    [CALL_API]: '/organizations/:id/fetch-custom-domain',
    method: 'get',
    params: params,
})

export const deleteCustomDomainLink = (params, reqBody) => ({
    types: ['DELETE_CUSTOM_DOMAIN_REQUEST', 'DELETE_CUSTOM_DOMAIN_SUCCESS', 'DELETE_CUSTOM_DOMAIN_FAILURE'],
    [CALL_API]: '/organizations/:id/delete-custom-domain',
    method: 'delete',
    params: params,
    reqBody: reqBody,
})

export const checkNameSpace = (reqBody) => ({
    types: ['POST_CHECK_NAMESPACE_REQUEST', 'POST_CHECK_NAMESPACE_SUCCESS', 'POST_CHECK_NAMESPACE_FAILURE'],
    [CALL_API]: '/check-availability',
    method: 'post',
    reqBody: reqBody,
})

export const resetCheckNameSpace = () => ({
    type: 'RESET_CHECK_NAMESPACE',
})