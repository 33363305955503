import cookie from 'js-cookie';

function organizations(state ={}, action) {
    switch (action.type) {
      case 'SET_ORGANIZATION':
          return {...state,
            organization: action.data.organization,
            createdOrgAdminEmail: "",
            createdOrgAdminPass: "",
            error: ""
          }
      case 'POST_CREATE_ORGANIZATION_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'POST_CREATE_ORGANIZATION_SUCCESS':
          let createdOrganization = [(action.data && action.data.organization)] || []
          if (createdOrganization.length && createdOrganization[0]) {
            createdOrganization[0].id = createdOrganization[0]._id
          }
          let newAllOrganizations = createdOrganization.concat(state.organizations)
          let createdOrgAdmin = action.data && action.data.user
          let createdOrgAdminPass = action.data && action.data.password
          console.log('action.data : ', action.data);
          console.log("createdOrgAdmin:", createdOrgAdmin)
          console.log("createdOrgAdminPass:", createdOrgAdminPass)
          return {...state,
            organization: createdOrganization[0],
            organizations: newAllOrganizations,
            createdOrgAdminEmail: (createdOrgAdmin && createdOrgAdmin.email),
            createdOrgAdminPass: createdOrgAdminPass,
            loading: false,
            success: `Successfully Created Organization! Time:${Date.now()}`
          }
      case 'POST_CREATE_ORGANIZATION_FAILURE':
        const createOrganizationError = (action.response && action.response.data && action.response.data.message) || 'Something went wrong'
        return {...state,
          loading: false,
          error: createOrganizationError
        }
      case 'GET_ORGANIZATION_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'GET_ORGANIZATION_SUCCESS':
        let pollOrganizationId = (action.data && action.data.organization && action.data.organization._id) || (action.data && action.data._id) || ""
        console.log('pollOrganizationId: ', pollOrganizationId);
        const organizationsWithPollOrg = state.organizations.map((eachOrg) => {
          if(eachOrg._id === pollOrganizationId) {
            let polledOrgData = (action.data.organization || action.data)
            polledOrgData.id = polledOrgData._id
            return polledOrgData
          }
          return eachOrg
        })
          return {...state,
            organization: (action.data.organization || action.data),
            organizations: organizationsWithPollOrg,
            loading: false,
            error: ""
          }
      case 'GET_ORGANIZATION_FAILURE':
        const getOrganizationError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: getOrganizationError
        }
      case 'GET_ORGANIZATIONS_REQUEST':
        return {...state,
          totalCount: 0,
          loading: true,
          error: ""
        }
      case 'GET_ORGANIZATIONS_SUCCESS':
          const totalOrganizationsArr = (action.data && action.data.organizations) || (action.data) || []
          let transformOrganizations = totalOrganizationsArr.map((eachOrganization) => {
            eachOrganization.id = eachOrganization._id
            return eachOrganization
          })
          return {...state,
            totalCount: (action.data && action.data.totalCount) || 0,
            organizations: transformOrganizations,
            loading: false,
            error: ""
          }
      case 'GET_ORGANIZATIONS_FAILURE':
        const organizationsError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          totalCount: 0,
          loading: false,
          error: organizationsError
        }
      case 'GET_NEXT_ORGANIZATIONS_REQUEST':
        return {...state,
          totalCount: 0,
          loading: true,
          error: ""
        }
      case 'GET_NEXT_ORGANIZATIONS_SUCCESS':
          const totalNextOrganizationsArr = (action.data && action.data.organizations) || (action.data) || []
          let transformNextOrganizations = totalNextOrganizationsArr.map((eachOrganization) => {
            eachOrganization.id = eachOrganization._id
            return eachOrganization
          })
          let newTotalOrganizations = transformNextOrganizations.concat(state.organizations)
          return {...state,
            organizations: newTotalOrganizations,
            loading: false,
            error: ""
          }
      case 'GET_NEXT_ORGANIZATIONS_FAILURE':
        const nextOrganizationsError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          totalCount: 0,
          loading: false,
          error: nextOrganizationsError
        }
      case 'PUT_UPDATE_ORGANIZATION_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'PUT_UPDATE_ORGANIZATION_SUCCESS':
          let updatedOrganizationId = (action.data && action.data._id) || ""
          const organizationsWithNewOrg = state.organizations.map((eachOrg) => {
            if(eachOrg._id === updatedOrganizationId) {
              let updatedOrgData = action.data
              updatedOrgData.id = updatedOrgData._id
              return updatedOrgData
            }
            return eachOrg
          })
          return {...state,
            loading: false,
            orgUpdated: action.data,
            organizations: organizationsWithNewOrg,
            error: "",
            success: `Successfully Updated Organization! Time:${Date.now()}`
          }
      case 'PUT_UPDATE_ORGANIZATION_FAILURE':
        const orgUpdatedError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: orgUpdatedError
        }
      case 'CREATE_CUSTOM_DOMAIN_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'CREATE_CUSTOM_DOMAIN_SUCCESS':
          let targetOrganizationId = (action.data && action.data.organization && action.data.organization._id) || ""
          const organizationsWithOrgNewDomain = state.organizations.map((eachOrg) => {
            if(eachOrg._id === targetOrganizationId) {
              let updatedOrgData = (action.data && action.data.organization)
              updatedOrgData.id = updatedOrgData._id
              return updatedOrgData
            }
            return eachOrg
          })

          return {...state,
            loading: false,
            orgUpdated: (action.data && action.data.organization),
            organization: (action.data && action.data.organization),
            organizations: organizationsWithOrgNewDomain,
            error: "",
            success: `Successfully Created Organization Domain! Time:${Date.now()}`
          }
      case 'CREATE_CUSTOM_DOMAIN_FAILURE':
        const orgDomainCreatedError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: orgDomainCreatedError
        }
      case 'GET_CUSTOM_DOMAIN_REQUEST':
      case 'DELETE_CUSTOM_DOMAIN_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'GET_CUSTOM_DOMAIN_SUCCESS':
      case 'DELETE_CUSTOM_DOMAIN_SUCCESS':
          let domainOrganizationId = (action.data && action.data.organization && action.data.organization._id) || ""
          const organizationsWithNewDomain = state.organizations.map((eachOrg) => {
            if(eachOrg._id === domainOrganizationId) {
              let updatedOrgData = (action.data && action.data.organization)
              updatedOrgData.id = updatedOrgData._id
              return updatedOrgData
            }
            return eachOrg
          })
          return {...state,
            loading: false,
            organization: (action.data && action.data.organization),
            organizations: organizationsWithNewDomain,
            error: "",
          }
      case 'GET_CUSTOM_DOMAIN_FAILURE':
      case 'DELETE_CUSTOM_DOMAIN_FAILURE':
        const orgDomainGetError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: orgDomainGetError
        }
      case 'RESET_CHECK_NAMESPACE':
        return {...state,
          loading: false,
          name_space_available: false,
          error: ""
        }
      case 'POST_CHECK_NAMESPACE_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'POST_CHECK_NAMESPACE_SUCCESS':
          let availability = (action.data && action.data.message) || ""
          return {...state,
            loading: false,
            name_space_available: availability  === "OK" ? true : false,
            error: "",
          }
      case 'POST_CHECK_NAMESPACE_FAILURE':
        const nameSpaceError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: nameSpaceError
        }
      case 'UPDATE_ORGANIZATION_TEMPLATE_REQUEST':
        return {...state,
          loading: true,
          error: ""
        }
      case 'UPDATE_ORGANIZATION_TEMPLATE_SUCCESS':
          let targetOrgId = (action.data && action.data.organization && action.data.organization._id) || ""
          const orgsWithOrgNewTemplate = state.organizations.map((eachOrg) => {
            if(eachOrg._id === targetOrgId) {
              let updatedOrgData = (action.data && action.data.organization)
              updatedOrgData.id = updatedOrgData._id
              return updatedOrgData
            }
            return eachOrg
          })

          return {...state,
            loading: false,
            orgUpdated: (action.data && action.data.organization),
            organization: (action.data && action.data.organization),
            organizations: orgsWithOrgNewTemplate,
            error: "",
            success: `Successfully Created Organization Domain! Time:${Date.now()}`
          }
      case 'UPDATE_ORGANIZATION_TEMPLATE_FAILURE':
        const orgTemplateUpdateError = (action.error && action.error.response && action.error.response.data && action.error.response.data.message) || ''
        return {...state,
          loading: false,
          error: orgTemplateUpdateError
        }
      default:
        return state
    }
  }

export default organizations