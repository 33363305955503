const drawerWidth = 240;
const countries = [
    {
    "name": "Afghanistan",
    "dialCode": "+93",
    "isoCode": "AF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/af.svg"
    },
    {
    "name": "Aland Islands",
    "dialCode": "+358",
    "isoCode": "AX",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ax.svg"
    },
    {
    "name": "Albania",
    "dialCode": "+355",
    "isoCode": "AL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/al.svg"
    },
    {
    "name": "Algeria",
    "dialCode": "+213",
    "isoCode": "DZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dz.svg"
    },
    {
    "name": "American Samoa",
    "dialCode": "+1684",
    "isoCode": "AS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/as.svg"
    },
    {
    "name": "Andorra",
    "dialCode": "+376",
    "isoCode": "AD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ad.svg"
    },
    {
    "name": "Angola",
    "dialCode": "+244",
    "isoCode": "AO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ao.svg"
    },
    {
    "name": "Anguilla",
    "dialCode": "+1264",
    "isoCode": "AI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ai.svg"
    },
    {
    "name": "Antarctica",
    "dialCode": "+672",
    "isoCode": "AQ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/aq.svg"
    },
    {
    "name": "Antigua and Barbuda",
    "dialCode": "+1268",
    "isoCode": "AG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ag.svg"
    },
    {
    "name": "Argentina",
    "dialCode": "+54",
    "isoCode": "AR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ar.svg"
    },
    {
    "name": "Armenia",
    "dialCode": "+374",
    "isoCode": "AM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/am.svg"
    },
    {
    "name": "Aruba",
    "dialCode": "+297",
    "isoCode": "AW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/aw.svg"
    },
    {
    "name": "Ascension Island",
    "dialCode": "+247",
    "isoCode": "AC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ac.svg"
    },
    {
    "name": "Australia",
    "dialCode": "+61",
    "isoCode": "AU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/au.svg"
    },
    {
    "name": "Austria",
    "dialCode": "+43",
    "isoCode": "AT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/at.svg"
    },
    {
    "name": "Azerbaijan",
    "dialCode": "+994",
    "isoCode": "AZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/az.svg"
    },
    {
    "name": "Bahamas",
    "dialCode": "+1242",
    "isoCode": "BS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bs.svg"
    },
    {
    "name": "Bahrain",
    "dialCode": "+973",
    "isoCode": "BH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bh.svg"
    },
    {
    "name": "Bangladesh",
    "dialCode": "+880",
    "isoCode": "BD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bd.svg"
    },
    {
    "name": "Barbados",
    "dialCode": "+1246",
    "isoCode": "BB",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bb.svg"
    },
    {
    "name": "Belarus",
    "dialCode": "+375",
    "isoCode": "BY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/by.svg"
    },
    {
    "name": "Belgium",
    "dialCode": "+32",
    "isoCode": "BE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/be.svg"
    },
    {
    "name": "Belize",
    "dialCode": "+501",
    "isoCode": "BZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bz.svg"
    },
    {
    "name": "Benin",
    "dialCode": "+229",
    "isoCode": "BJ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bj.svg"
    },
    {
    "name": "Bermuda",
    "dialCode": "+1441",
    "isoCode": "BM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bm.svg"
    },
    {
    "name": "Bhutan",
    "dialCode": "+975",
    "isoCode": "BT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bt.svg"
    },
    {
    "name": "Bolivia",
    "dialCode": "+591",
    "isoCode": "BO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bo.svg"
    },
    {
    "name": "Bosnia and Herzegovina",
    "dialCode": "+387",
    "isoCode": "BA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ba.svg"
    },
    {
    "name": "Botswana",
    "dialCode": "+267",
    "isoCode": "BW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bw.svg"
    },
    {
    "name": "Brazil",
    "dialCode": "+55",
    "isoCode": "BR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/br.svg"
    },
    {
    "name": "British Indian Ocean Territory",
    "dialCode": "+246",
    "isoCode": "IO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/io.svg"
    },
    {
    "name": "Brunei Darussalam",
    "dialCode": "+673",
    "isoCode": "BN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bn.svg"
    },
    {
    "name": "Bulgaria",
    "dialCode": "+359",
    "isoCode": "BG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bg.svg"
    },
    {
    "name": "Burkina Faso",
    "dialCode": "+226",
    "isoCode": "BF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bf.svg"
    },
    {
    "name": "Burundi",
    "dialCode": "+257",
    "isoCode": "BI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bi.svg"
    },
    {
    "name": "Cambodia",
    "dialCode": "+855",
    "isoCode": "KH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kh.svg"
    },
    {
    "name": "Cameroon",
    "dialCode": "+237",
    "isoCode": "CM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cm.svg"
    },
    {
    "name": "Canada",
    "dialCode": "+1",
    "isoCode": "CA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ca.svg"
    },
    {
    "name": "Cape Verde",
    "dialCode": "+238",
    "isoCode": "CV",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cv.svg"
    },
    {
    "name": "Cayman Islands",
    "dialCode": "+1345",
    "isoCode": "KY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ky.svg"
    },
    {
    "name": "Central African Republic",
    "dialCode": "+236",
    "isoCode": "CF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cf.svg"
    },
    {
    "name": "Chad",
    "dialCode": "+235",
    "isoCode": "TD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/td.svg"
    },
    {
    "name": "Chile",
    "dialCode": "+56",
    "isoCode": "CL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cl.svg"
    },
    {
    "name": "China",
    "dialCode": "+86",
    "isoCode": "CN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cn.svg"
    },
    {
    "name": "Christmas Island",
    "dialCode": "+61",
    "isoCode": "CX",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cx.svg"
    },
    {
    "name": "Cocos (Keeling) Islands",
    "dialCode": "+61",
    "isoCode": "CC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cc.svg"
    },
    {
    "name": "Colombia",
    "dialCode": "+57",
    "isoCode": "CO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/co.svg"
    },
    {
    "name": "Comoros",
    "dialCode": "+269",
    "isoCode": "KM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/km.svg"
    },
    {
    "name": "Congo",
    "dialCode": "+242",
    "isoCode": "CG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cg.svg"
    },
    {
    "name": "Cook Islands",
    "dialCode": "+682",
    "isoCode": "CK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ck.svg"
    },
    {
    "name": "Costa Rica",
    "dialCode": "+506",
    "isoCode": "CR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cr.svg"
    },
    {
    "name": "Croatia",
    "dialCode": "+385",
    "isoCode": "HR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hr.svg"
    },
    {
    "name": "Cuba",
    "dialCode": "+53",
    "isoCode": "CU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cu.svg"
    },
    {
    "name": "Cyprus",
    "dialCode": "+357",
    "isoCode": "CY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cy.svg"
    },
    {
    "name": "Czech Republic",
    "dialCode": "+420",
    "isoCode": "CZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cz.svg"
    },
    {
    "name": "Democratic Republic of the Congo",
    "dialCode": "+243",
    "isoCode": "CD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cd.svg"
    },
    {
    "name": "Denmark",
    "dialCode": "+45",
    "isoCode": "DK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dk.svg"
    },
    {
    "name": "Djibouti",
    "dialCode": "+253",
    "isoCode": "DJ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dj.svg"
    },
    {
    "name": "Dominica",
    "dialCode": "+1767",
    "isoCode": "DM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dm.svg"
    },
    {
    "name": "Dominican Republic",
    "dialCode": "+1849",
    "isoCode": "DO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/do.svg"
    },
    {
    "name": "Ecuador",
    "dialCode": "+593",
    "isoCode": "EC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ec.svg"
    },
    {
    "name": "Egypt",
    "dialCode": "+20",
    "isoCode": "EG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/eg.svg"
    },
    {
    "name": "El Salvador",
    "dialCode": "+503",
    "isoCode": "SV",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sv.svg"
    },
    {
    "name": "Equatorial Guinea",
    "dialCode": "+240",
    "isoCode": "GQ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gq.svg"
    },
    {
    "name": "Eritrea",
    "dialCode": "+291",
    "isoCode": "ER",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/er.svg"
    },
    {
    "name": "Estonia",
    "dialCode": "+372",
    "isoCode": "EE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ee.svg"
    },
    {
    "name": "Eswatini",
    "dialCode": "+268",
    "isoCode": "SZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sz.svg"
    },
    {
    "name": "Ethiopia",
    "dialCode": "+251",
    "isoCode": "ET",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/et.svg"
    },
    {
    "name": "Falkland Islands (Malvinas)",
    "dialCode": "+500",
    "isoCode": "FK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fk.svg"
    },
    {
    "name": "Faroe Islands",
    "dialCode": "+298",
    "isoCode": "FO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fo.svg"
    },
    {
    "name": "Fiji",
    "dialCode": "+679",
    "isoCode": "FJ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fj.svg"
    },
    {
    "name": "Finland",
    "dialCode": "+358",
    "isoCode": "FI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fi.svg"
    },
    {
    "name": "France",
    "dialCode": "+33",
    "isoCode": "FR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fr.svg"
    },
    {
    "name": "French Guiana",
    "dialCode": "+594",
    "isoCode": "GF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gf.svg"
    },
    {
    "name": "French Polynesia",
    "dialCode": "+689",
    "isoCode": "PF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pf.svg"
    },
    {
    "name": "Gabon",
    "dialCode": "+241",
    "isoCode": "GA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ga.svg"
    },
    {
    "name": "Gambia",
    "dialCode": "+220",
    "isoCode": "GM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gm.svg"
    },
    {
    "name": "Georgia",
    "dialCode": "+995",
    "isoCode": "GE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ge.svg"
    },
    {
    "name": "Germany",
    "dialCode": "+49",
    "isoCode": "DE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/de.svg"
    },
    {
    "name": "Ghana",
    "dialCode": "+233",
    "isoCode": "GH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gh.svg"
    },
    {
    "name": "Gibraltar",
    "dialCode": "+350",
    "isoCode": "GI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gi.svg"
    },
    {
    "name": "Greece",
    "dialCode": "+30",
    "isoCode": "GR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gr.svg"
    },
    {
    "name": "Greenland",
    "dialCode": "+299",
    "isoCode": "GL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gl.svg"
    },
    {
    "name": "Grenada",
    "dialCode": "+1473",
    "isoCode": "GD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gd.svg"
    },
    {
    "name": "Guadeloupe",
    "dialCode": "+590",
    "isoCode": "GP",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gp.svg"
    },
    {
    "name": "Guam",
    "dialCode": "+1671",
    "isoCode": "GU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gu.svg"
    },
    {
    "name": "Guatemala",
    "dialCode": "+502",
    "isoCode": "GT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gt.svg"
    },
    {
    "name": "Guernsey",
    "dialCode": "+44",
    "isoCode": "GG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gg.svg"
    },
    {
    "name": "Guinea",
    "dialCode": "+224",
    "isoCode": "GN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gn.svg"
    },
    {
    "name": "Guinea-Bissau",
    "dialCode": "+245",
    "isoCode": "GW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gw.svg"
    },
    {
    "name": "Guyana",
    "dialCode": "+592",
    "isoCode": "GY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gy.svg"
    },
    {
    "name": "Haiti",
    "dialCode": "+509",
    "isoCode": "HT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ht.svg"
    },
    {
    "name": "Holy See (Vatican City State)",
    "dialCode": "+379",
    "isoCode": "VA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/va.svg"
    },
    {
    "name": "Honduras",
    "dialCode": "+504",
    "isoCode": "HN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hn.svg"
    },
    {
    "name": "Hong Kong",
    "dialCode": "+852",
    "isoCode": "HK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hk.svg"
    },
    {
    "name": "Hungary",
    "dialCode": "+36",
    "isoCode": "HU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hu.svg"
    },
    {
    "name": "Iceland",
    "dialCode": "+354",
    "isoCode": "IS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/is.svg"
    },
    {
    "name": "India",
    "dialCode": "+91",
    "isoCode": "IN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/in.svg"
    },
    {
    "name": "Indonesia",
    "dialCode": "+62",
    "isoCode": "ID",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/id.svg"
    },
    {
    "name": "Iran",
    "dialCode": "+98",
    "isoCode": "IR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ir.svg"
    },
    {
    "name": "Iraq",
    "dialCode": "+964",
    "isoCode": "IQ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/iq.svg"
    },
    {
    "name": "Ireland",
    "dialCode": "+353",
    "isoCode": "IE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ie.svg"
    },
    {
    "name": "Isle of Man",
    "dialCode": "+44",
    "isoCode": "IM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/im.svg"
    },
    {
    "name": "Israel",
    "dialCode": "+972",
    "isoCode": "IL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/il.svg"
    },
    {
    "name": "Italy",
    "dialCode": "+39",
    "isoCode": "IT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/it.svg"
    },
    {
    "name": "Ivory Coast / Cote d'Ivoire",
    "dialCode": "+225",
    "isoCode": "CI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ci.svg"
    },
    {
    "name": "Jamaica",
    "dialCode": "+1876",
    "isoCode": "JM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jm.svg"
    },
    {
    "name": "Japan",
    "dialCode": "+81",
    "isoCode": "JP",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jp.svg"
    },
    {
    "name": "Jersey",
    "dialCode": "+44",
    "isoCode": "JE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/je.svg"
    },
    {
    "name": "Jordan",
    "dialCode": "+962",
    "isoCode": "JO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jo.svg"
    },
    {
    "name": "Kazakhstan",
    "dialCode": "+77",
    "isoCode": "KZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kz.svg"
    },
    {
    "name": "Kenya",
    "dialCode": "+254",
    "isoCode": "KE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ke.svg"
    },
    {
    "name": "Kiribati",
    "dialCode": "+686",
    "isoCode": "KI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ki.svg"
    },
    {
    "name": "Korea, Democratic People's Republic of Korea",
    "dialCode": "+850",
    "isoCode": "KP",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kp.svg"
    },
    {
    "name": "Korea, Republic of South Korea",
    "dialCode": "+82",
    "isoCode": "KR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kr.svg"
    },
    {
    "name": "Kosovo",
    "dialCode": "+383",
    "isoCode": "XK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/xk.svg"
    },
    {
    "name": "Kuwait",
    "dialCode": "+965",
    "isoCode": "KW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kw.svg"
    },
    {
    "name": "Kyrgyzstan",
    "dialCode": "+996",
    "isoCode": "KG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kg.svg"
    },
    {
    "name": "Laos",
    "dialCode": "+856",
    "isoCode": "LA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/la.svg"
    },
    {
    "name": "Latvia",
    "dialCode": "+371",
    "isoCode": "LV",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lv.svg"
    },
    {
    "name": "Lebanon",
    "dialCode": "+961",
    "isoCode": "LB",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lb.svg"
    },
    {
    "name": "Lesotho",
    "dialCode": "+266",
    "isoCode": "LS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ls.svg"
    },
    {
    "name": "Liberia",
    "dialCode": "+231",
    "isoCode": "LR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lr.svg"
    },
    {
    "name": "Libya",
    "dialCode": "+218",
    "isoCode": "LY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ly.svg"
    },
    {
    "name": "Liechtenstein",
    "dialCode": "+423",
    "isoCode": "LI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/li.svg"
    },
    {
    "name": "Lithuania",
    "dialCode": "+370",
    "isoCode": "LT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lt.svg"
    },
    {
    "name": "Luxembourg",
    "dialCode": "+352",
    "isoCode": "LU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lu.svg"
    },
    {
    "name": "Macau",
    "dialCode": "+853",
    "isoCode": "MO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mo.svg"
    },
    {
    "name": "Madagascar",
    "dialCode": "+261",
    "isoCode": "MG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mg.svg"
    },
    {
    "name": "Malawi",
    "dialCode": "+265",
    "isoCode": "MW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mw.svg"
    },
    {
    "name": "Malaysia",
    "dialCode": "+60",
    "isoCode": "MY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/my.svg"
    },
    {
    "name": "Maldives",
    "dialCode": "+960",
    "isoCode": "MV",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mv.svg"
    },
    {
    "name": "Mali",
    "dialCode": "+223",
    "isoCode": "ML",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ml.svg"
    },
    {
    "name": "Malta",
    "dialCode": "+356",
    "isoCode": "MT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mt.svg"
    },
    {
    "name": "Marshall Islands",
    "dialCode": "+692",
    "isoCode": "MH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mh.svg"
    },
    {
    "name": "Martinique",
    "dialCode": "+596",
    "isoCode": "MQ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mq.svg"
    },
    {
    "name": "Mauritania",
    "dialCode": "+222",
    "isoCode": "MR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mr.svg"
    },
    {
    "name": "Mauritius",
    "dialCode": "+230",
    "isoCode": "MU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mu.svg"
    },
    {
    "name": "Mayotte",
    "dialCode": "+262",
    "isoCode": "YT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/yt.svg"
    },
    {
    "name": "Mexico",
    "dialCode": "+52",
    "isoCode": "MX",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mx.svg"
    },
    {
    "name": "Micronesia, Federated States of Micronesia",
    "dialCode": "+691",
    "isoCode": "FM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fm.svg"
    },
    {
    "name": "Moldova",
    "dialCode": "+373",
    "isoCode": "MD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/md.svg"
    },
    {
    "name": "Monaco",
    "dialCode": "+377",
    "isoCode": "MC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mc.svg"
    },
    {
    "name": "Mongolia",
    "dialCode": "+976",
    "isoCode": "MN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mn.svg"
    },
    {
    "name": "Montenegro",
    "dialCode": "+382",
    "isoCode": "ME",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/me.svg"
    },
    {
    "name": "Montserrat",
    "dialCode": "+1664",
    "isoCode": "MS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ms.svg"
    },
    {
    "name": "Morocco",
    "dialCode": "+212",
    "isoCode": "MA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ma.svg"
    },
    {
    "name": "Mozambique",
    "dialCode": "+258",
    "isoCode": "MZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mz.svg"
    },
    {
    "name": "Myanmar",
    "dialCode": "+95",
    "isoCode": "MM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mm.svg"
    },
    {
    "name": "Namibia",
    "dialCode": "+264",
    "isoCode": "NA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/na.svg"
    },
    {
    "name": "Nauru",
    "dialCode": "+674",
    "isoCode": "NR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nr.svg"
    },
    {
    "name": "Nepal",
    "dialCode": "+977",
    "isoCode": "NP",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/np.svg"
    },
    {
    "name": "Netherlands",
    "dialCode": "+31",
    "isoCode": "NL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nl.svg"
    },
    {
    "name": "Netherlands Antilles",
    "dialCode": "+599",
    "isoCode": "AN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/an.svg"
    },
    {
    "name": "New Caledonia",
    "dialCode": "+687",
    "isoCode": "NC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nc.svg"
    },
    {
    "name": "New Zealand",
    "dialCode": "+64",
    "isoCode": "NZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nz.svg"
    },
    {
    "name": "Nicaragua",
    "dialCode": "+505",
    "isoCode": "NI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ni.svg"
    },
    {
    "name": "Niger",
    "dialCode": "+227",
    "isoCode": "NE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ne.svg"
    },
    {
    "name": "Nigeria",
    "dialCode": "+234",
    "isoCode": "NG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ng.svg"
    },
    {
    "name": "Niue",
    "dialCode": "+683",
    "isoCode": "NU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nu.svg"
    },
    {
    "name": "Norfolk Island",
    "dialCode": "+672",
    "isoCode": "NF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nf.svg"
    },
    {
    "name": "North Macedonia",
    "dialCode": "+389",
    "isoCode": "MK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mk.svg"
    },
    {
    "name": "Northern Mariana Islands",
    "dialCode": "+1670",
    "isoCode": "MP",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mp.svg"
    },
    {
    "name": "Norway",
    "dialCode": "+47",
    "isoCode": "NO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/no.svg"
    },
    {
    "name": "Oman",
    "dialCode": "+968",
    "isoCode": "OM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/om.svg"
    },
    {
    "name": "Pakistan",
    "dialCode": "+92",
    "isoCode": "PK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pk.svg"
    },
    {
    "name": "Palau",
    "dialCode": "+680",
    "isoCode": "PW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pw.svg"
    },
    {
    "name": "Palestine",
    "dialCode": "+970",
    "isoCode": "PS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ps.svg"
    },
    {
    "name": "Panama",
    "dialCode": "+507",
    "isoCode": "PA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pa.svg"
    },
    {
    "name": "Papua New Guinea",
    "dialCode": "+675",
    "isoCode": "PG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pg.svg"
    },
    {
    "name": "Paraguay",
    "dialCode": "+595",
    "isoCode": "PY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/py.svg"
    },
    {
    "name": "Peru",
    "dialCode": "+51",
    "isoCode": "PE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pe.svg"
    },
    {
    "name": "Philippines",
    "dialCode": "+63",
    "isoCode": "PH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ph.svg"
    },
    {
    "name": "Pitcairn",
    "dialCode": "+872",
    "isoCode": "PN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pn.svg"
    },
    {
    "name": "Poland",
    "dialCode": "+48",
    "isoCode": "PL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pl.svg"
    },
    {
    "name": "Portugal",
    "dialCode": "+351",
    "isoCode": "PT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pt.svg"
    },
    {
    "name": "Puerto Rico",
    "dialCode": "+1939",
    "isoCode": "PR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pr.svg"
    },
    {
    "name": "Qatar",
    "dialCode": "+974",
    "isoCode": "QA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/qa.svg"
    },
    {
    "name": "Reunion",
    "dialCode": "+262",
    "isoCode": "RE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/re.svg"
    },
    {
    "name": "Romania",
    "dialCode": "+40",
    "isoCode": "RO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ro.svg"
    },
    {
    "name": "Russia",
    "dialCode": "+7",
    "isoCode": "RU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ru.svg"
    },
    {
    "name": "Rwanda",
    "dialCode": "+250",
    "isoCode": "RW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/rw.svg"
    },
    {
    "name": "Saint Barthelemy",
    "dialCode": "+590",
    "isoCode": "BL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bl.svg"
    },
    {
    "name": "Saint Helena, Ascension and Tristan Da Cunha",
    "dialCode": "+290",
    "isoCode": "SH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sh.svg"
    },
    {
    "name": "Saint Kitts and Nevis",
    "dialCode": "+1869",
    "isoCode": "KN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kn.svg"
    },
    {
    "name": "Saint Lucia",
    "dialCode": "+1758",
    "isoCode": "LC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lc.svg"
    },
    {
    "name": "Saint Martin",
    "dialCode": "+590",
    "isoCode": "MF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mf.svg"
    },
    {
    "name": "Saint Pierre and Miquelon",
    "dialCode": "+508",
    "isoCode": "PM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pm.svg"
    },
    {
    "name": "Saint Vincent and the Grenadines",
    "dialCode": "+1784",
    "isoCode": "VC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vc.svg"
    },
    {
    "name": "Samoa",
    "dialCode": "+685",
    "isoCode": "WS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ws.svg"
    },
    {
    "name": "San Marino",
    "dialCode": "+378",
    "isoCode": "SM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sm.svg"
    },
    {
    "name": "Sao Tome and Principe",
    "dialCode": "+239",
    "isoCode": "ST",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/st.svg"
    },
    {
    "name": "Saudi Arabia",
    "dialCode": "+966",
    "isoCode": "SA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sa.svg"
    },
    {
    "name": "Senegal",
    "dialCode": "+221",
    "isoCode": "SN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sn.svg"
    },
    {
    "name": "Serbia",
    "dialCode": "+381",
    "isoCode": "RS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/rs.svg"
    },
    {
    "name": "Seychelles",
    "dialCode": "+248",
    "isoCode": "SC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sc.svg"
    },
    {
    "name": "Sierra Leone",
    "dialCode": "+232",
    "isoCode": "SL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sl.svg"
    },
    {
    "name": "Singapore",
    "dialCode": "+65",
    "isoCode": "SG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sg.svg"
    },
    {
    "name": "Sint Maarten",
    "dialCode": "+1721",
    "isoCode": "SX",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sx.svg"
    },
    {
    "name": "Slovakia",
    "dialCode": "+421",
    "isoCode": "SK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sk.svg"
    },
    {
    "name": "Slovenia",
    "dialCode": "+386",
    "isoCode": "SI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/si.svg"
    },
    {
    "name": "Solomon Islands",
    "dialCode": "+677",
    "isoCode": "SB",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sb.svg"
    },
    {
    "name": "Somalia",
    "dialCode": "+252",
    "isoCode": "SO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/so.svg"
    },
    {
    "name": "South Africa",
    "dialCode": "+27",
    "isoCode": "ZA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/za.svg"
    },
    {
    "name": "South Georgia and the South Sandwich Islands",
    "dialCode": "+500",
    "isoCode": "GS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gs.svg"
    },
    {
    "name": "South Sudan",
    "dialCode": "+211",
    "isoCode": "SS",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ss.svg"
    },
    {
    "name": "Spain",
    "dialCode": "+34",
    "isoCode": "ES",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/es.svg"
    },
    {
    "name": "Sri Lanka",
    "dialCode": "+94",
    "isoCode": "LK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lk.svg"
    },
    {
    "name": "Sudan",
    "dialCode": "+249",
    "isoCode": "SD",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sd.svg"
    },
    {
    "name": "Suriname",
    "dialCode": "+597",
    "isoCode": "SR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sr.svg"
    },
    {
    "name": "Svalbard and Jan Mayen",
    "dialCode": "+47",
    "isoCode": "SJ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sj.svg"
    },
    {
    "name": "Sweden",
    "dialCode": "+46",
    "isoCode": "SE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/se.svg"
    },
    {
    "name": "Switzerland",
    "dialCode": "+41",
    "isoCode": "CH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ch.svg"
    },
    {
    "name": "Syrian Arab Republic",
    "dialCode": "+963",
    "isoCode": "SY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sy.svg"
    },
    {
    "name": "Taiwan",
    "dialCode": "+886",
    "isoCode": "TW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tw.svg"
    },
    {
    "name": "Tajikistan",
    "dialCode": "+992",
    "isoCode": "TJ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tj.svg"
    },
    {
    "name": "Tanzania, United Republic of Tanzania",
    "dialCode": "+255",
    "isoCode": "TZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tz.svg"
    },
    {
    "name": "Thailand",
    "dialCode": "+66",
    "isoCode": "TH",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/th.svg"
    },
    {
    "name": "Timor-Leste",
    "dialCode": "+670",
    "isoCode": "TL",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tl.svg"
    },
    {
    "name": "Togo",
    "dialCode": "+228",
    "isoCode": "TG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tg.svg"
    },
    {
    "name": "Tokelau",
    "dialCode": "+690",
    "isoCode": "TK",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tk.svg"
    },
    {
    "name": "Tonga",
    "dialCode": "+676",
    "isoCode": "TO",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/to.svg"
    },
    {
    "name": "Trinidad and Tobago",
    "dialCode": "+1868",
    "isoCode": "TT",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tt.svg"
    },
    {
    "name": "Tunisia",
    "dialCode": "+216",
    "isoCode": "TN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tn.svg"
    },
    {
    "name": "Turkey",
    "dialCode": "+90",
    "isoCode": "TR",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tr.svg"
    },
    {
    "name": "Turkmenistan",
    "dialCode": "+993",
    "isoCode": "TM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tm.svg"
    },
    {
    "name": "Turks and Caicos Islands",
    "dialCode": "+1649",
    "isoCode": "TC",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tc.svg"
    },
    {
    "name": "Tuvalu",
    "dialCode": "+688",
    "isoCode": "TV",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tv.svg"
    },
    {
    "name": "Uganda",
    "dialCode": "+256",
    "isoCode": "UG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ug.svg"
    },
    {
    "name": "Ukraine",
    "dialCode": "+380",
    "isoCode": "UA",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ua.svg"
    },
    {
    "name": "United Arab Emirates",
    "dialCode": "+971",
    "isoCode": "AE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ae.svg"
    },
    {
    "name": "United Kingdom",
    "dialCode": "+44",
    "isoCode": "GB",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gb.svg"
    },
    {
    "name": "United States",
    "dialCode": "+1",
    "isoCode": "US",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
    },
    {
    "name": "United States Minor Outlying Islands",
    "dialCode": "+246",
    "isoCode": "UMI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/umi.svg"
    },
    {
    "name": "Uruguay",
    "dialCode": "+598",
    "isoCode": "UY",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/uy.svg"
    },
    {
    "name": "Uzbekistan",
    "dialCode": "+998",
    "isoCode": "UZ",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/uz.svg"
    },
    {
    "name": "Vanuatu",
    "dialCode": "+678",
    "isoCode": "VU",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vu.svg"
    },
    {
    "name": "Venezuela, Bolivarian Republic of Venezuela",
    "dialCode": "+58",
    "isoCode": "VE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ve.svg"
    },
    {
    "name": "Vietnam",
    "dialCode": "+84",
    "isoCode": "VN",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vn.svg"
    },
    {
    "name": "Virgin Islands, British",
    "dialCode": "+1284",
    "isoCode": "VG",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vg.svg"
    },
    {
    "name": "Virgin Islands, U.S.",
    "dialCode": "+1340",
    "isoCode": "VI",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vi.svg"
    },
    {
    "name": "Wallis and Futuna",
    "dialCode": "+681",
    "isoCode": "WF",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/wf.svg"
    },
    {
    "name": "Yemen",
    "dialCode": "+967",
    "isoCode": "YE",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ye.svg"
    },
    {
    "name": "Zambia",
    "dialCode": "+260",
    "isoCode": "ZM",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/zm.svg"
    },
    {
    "name": "Zimbabwe",
    "dialCode": "+263",
    "isoCode": "ZW",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/zw.svg"
    }
    ]
const currencies = [{
    "name": "Emirati Dirham",
    "value": {
        "currency_alpha3_code": "AED",
        "currency_no": 784,
        "minor_unit": "2",
        "description": "Emirati Dirham",
        "plural": "Emirati Dirhams",
        "related_terms": ["United Arab Emirates Dirham"],
        "withdrawal_date": null,
        "slug": "aed-emirati-dirham",
        "popularity": 28
    }
}, {
    "name": "Afghan Afghani",
    "value": {
        "currency_alpha3_code": "AFN",
        "currency_no": 971,
        "minor_unit": "2",
        "description": "Afghan Afghani",
        "plural": "Afghan Afghanis",
        "related_terms": ["Afghanistan Afghani"],
        "withdrawal_date": null,
        "slug": "afn-afghan-afghani",
        "popularity": 162
    }
}, {
    "name": "Albanian Lek",
    "value": {
        "currency_alpha3_code": "ALL",
        "currency_no": 8,
        "minor_unit": "2",
        "description": "Albanian Lek",
        "plural": "Albanian Leke",
        "related_terms": ["Albania Lek"],
        "withdrawal_date": null,
        "slug": "all-albanian-lek",
        "popularity": 77
    }
}, {
    "name": "Armenian Dram",
    "value": {
        "currency_alpha3_code": "AMD",
        "currency_no": 51,
        "minor_unit": "2",
        "description": "Armenian Dram",
        "plural": "Armenian Drams",
        "related_terms": ["Armenia Dram"],
        "withdrawal_date": null,
        "slug": "amd-armenian-dram",
        "popularity": 126
    }
}, {
    "name": "Dutch Guilder",
    "value": {
        "currency_alpha3_code": "ANG",
        "currency_no": 532,
        "minor_unit": "2",
        "description": "Dutch Guilder",
        "plural": "Dutch Guilders (also called Florins)",
        "related_terms": ["Netherlands Antilles Guilder", "Bonaire", "Curaçao", "Saba", "Sint Eustatius", "Sint Maarten"],
        "withdrawal_date": null,
        "slug": "ang-dutch-guilder",
        "popularity": 102
    }
}, {
    "name": "Angolan Kwanza",
    "value": {
        "currency_alpha3_code": "AOA",
        "currency_no": 973,
        "minor_unit": "2",
        "description": "Angolan Kwanza",
        "plural": "Angolan Kwanzas",
        "related_terms": ["Angola Kwanza"],
        "withdrawal_date": null,
        "slug": "aoa-angolan-kwanza",
        "popularity": 114
    }
}, {
    "name": "Argentine Peso",
    "value": {
        "currency_alpha3_code": "ARS",
        "currency_no": 32,
        "minor_unit": "2",
        "description": "Argentine Peso",
        "plural": "Argentine Pesos",
        "related_terms": ["Argentina Peso", "Islas Malvinas"],
        "withdrawal_date": null,
        "slug": "ars-argentine-peso",
        "popularity": 32
    }
}, {
    "name": "Australian Dollar",
    "value": {
        "currency_alpha3_code": "AUD",
        "currency_no": 36,
        "minor_unit": "2",
        "description": "Australian Dollar",
        "plural": "Australian Dollars",
        "related_terms": ["Australia Dollar", "Christmas Island", "Cocos (Keeling) Islands", "Norfolk Island", "Ashmore and Cartier Islands", "Australian Antarctic Territory", "Coral Sea Islands", "Heard Island", "McDonald Islands", "Kiribati", "Nauru"],
        "withdrawal_date": null,
        "slug": "aud-australian-dollar",
        "popularity": 5
    }
}, {
    "name": "Aruban or Dutch Guilder",
    "value": {
        "currency_alpha3_code": "AWG",
        "currency_no": 533,
        "minor_unit": "2",
        "description": "Aruban or Dutch Guilder",
        "plural": "Aruban or Dutch Guilders (also called Florins)",
        "related_terms": ["Aruba Guilder"],
        "withdrawal_date": null,
        "slug": "awg-aruban-or-dutch-guilder",
        "popularity": 125
    }
}, {
    "name": "Azerbaijan Manat",
    "value": {
        "currency_alpha3_code": "AZN",
        "currency_no": 944,
        "minor_unit": "2",
        "description": "Azerbaijan Manat",
        "plural": "Azerbaijan Manats",
        "related_terms": ["Azerbaijan Manat"],
        "withdrawal_date": null,
        "slug": "azn-azerbaijan-manat",
        "popularity": 163
    }
}, {
    "name": "Bosnian Convertible Mark",
    "value": {
        "currency_alpha3_code": "BAM",
        "currency_no": 977,
        "minor_unit": "2",
        "description": "Bosnian Convertible Mark",
        "plural": "Bosnian Convertible Marks",
        "related_terms": ["Bosnia and Herzegovina Convertible Mark"],
        "withdrawal_date": null,
        "slug": "bam-bosnian-convertible-mark",
        "popularity": 44
    }
}, {
    "name": "Barbadian or Bajan Dollar",
    "value": {
        "currency_alpha3_code": "BBD",
        "currency_no": 52,
        "minor_unit": "2",
        "description": "Barbadian or Bajan Dollar",
        "plural": "Barbadian or Bajan Dollars",
        "related_terms": ["Barbados Dollar"],
        "withdrawal_date": null,
        "slug": "bbd-barbadian-or-bajan-dollar",
        "popularity": 70
    }
}, {
    "name": "Bangladeshi Taka",
    "value": {
        "currency_alpha3_code": "BDT",
        "currency_no": 50,
        "minor_unit": "2",
        "description": "Bangladeshi Taka",
        "plural": "Bangladeshi Takas",
        "related_terms": ["Bangladesh Taka"],
        "withdrawal_date": null,
        "slug": "bdt-bangladeshi-taka",
        "popularity": 55
    }
}, {
    "name": "Bulgarian Lev",
    "value": {
        "currency_alpha3_code": "BGN",
        "currency_no": 975,
        "minor_unit": "2",
        "description": "Bulgarian Lev",
        "plural": "Bulgarian Leva",
        "related_terms": ["Bulgaria Lev"],
        "withdrawal_date": null,
        "slug": "bgn-bulgarian-lev",
        "popularity": 12
    }
}, {
    "name": "Bahraini Dinar",
    "value": {
        "currency_alpha3_code": "BHD",
        "currency_no": 48,
        "minor_unit": "3",
        "description": "Bahraini Dinar",
        "plural": "Bahraini Dinars",
        "related_terms": ["Bahrain Dinar"],
        "withdrawal_date": null,
        "slug": "bhd-bahraini-dinar",
        "popularity": 54
    }
}, {
    "name": "Burundian Franc",
    "value": {
        "currency_alpha3_code": "BIF",
        "currency_no": 108,
        "minor_unit": "0",
        "description": "Burundian Franc",
        "plural": "Burundian Francs",
        "related_terms": ["Burundi Franc"],
        "withdrawal_date": null,
        "slug": "bif-burundian-franc",
        "popularity": 132
    }
}, {
    "name": "Bermudian Dollar",
    "value": {
        "currency_alpha3_code": "BMD",
        "currency_no": 60,
        "minor_unit": "2",
        "description": "Bermudian Dollar",
        "plural": "Bermudian Dollars",
        "related_terms": ["Bermuda Dollar"],
        "withdrawal_date": null,
        "slug": "bmd-bermudian-dollar",
        "popularity": 79
    }
}, {
    "name": "Bruneian Dollar",
    "value": {
        "currency_alpha3_code": "BND",
        "currency_no": 96,
        "minor_unit": "2",
        "description": "Bruneian Dollar",
        "plural": "Bruneian Dollars",
        "related_terms": ["Brunei Darussalam Dollar"],
        "withdrawal_date": null,
        "slug": "bnd-bruneian-dollar",
        "popularity": 110
    }
}, {
    "name": "Bolivian Bolíviano",
    "value": {
        "currency_alpha3_code": "BOB",
        "currency_no": 68,
        "minor_unit": "2",
        "description": "Bolivian Bolíviano",
        "plural": "Bolivian Bolivianos",
        "related_terms": ["Bolivia Bolíviano"],
        "withdrawal_date": null,
        "slug": "bob-bolivian-bolíviano",
        "popularity": 85
    }
}, {
    "name": "Brazilian Real",
    "value": {
        "currency_alpha3_code": "BRL",
        "currency_no": 986,
        "minor_unit": "2",
        "description": "Brazilian Real",
        "plural": "Brazilian Reais",
        "related_terms": ["Brazil Real"],
        "withdrawal_date": null,
        "slug": "brl-brazilian-real",
        "popularity": 24
    }
}, {
    "name": "Bahamian Dollar",
    "value": {
        "currency_alpha3_code": "BSD",
        "currency_no": 44,
        "minor_unit": "2",
        "description": "Bahamian Dollar",
        "plural": "Bahamian Dollars",
        "related_terms": ["Bahamas Dollar"],
        "withdrawal_date": null,
        "slug": "bsd-bahamian-dollar",
        "popularity": 76
    }
}, {
    "name": "Bhutanese Ngultrum",
    "value": {
        "currency_alpha3_code": "BTN",
        "currency_no": 64,
        "minor_unit": "2",
        "description": "Bhutanese Ngultrum",
        "plural": "Bhutanese Ngultrums",
        "related_terms": ["Bhutan Ngultrum"],
        "withdrawal_date": null,
        "slug": "btn-bhutanese-ngultrum",
        "popularity": 139
    }
}, {
    "name": "Botswana Pula",
    "value": {
        "currency_alpha3_code": "BWP",
        "currency_no": 72,
        "minor_unit": "2",
        "description": "Botswana Pula",
        "plural": "Botswana Pule",
        "related_terms": ["Botswana Pula"],
        "withdrawal_date": null,
        "slug": "bwp-botswana-pula",
        "popularity": 84
    }
}, {
    "name": "Belarusian Ruble",
    "value": {
        "currency_alpha3_code": "BYN",
        "currency_no": 933,
        "minor_unit": "2",
        "description": "Belarusian Ruble",
        "plural": "Belarusian Rubles",
        "related_terms": ["Belarus Ruble"],
        "withdrawal_date": null,
        "slug": "byn-belarusian-ruble",
        "popularity": 123
    }
}, {
    "name": "Belarusian Ruble",
    "value": {
        "currency_alpha3_code": "BYR",
        "currency_no": 974,
        "minor_unit": null,
        "description": "Belarusian Ruble",
        "plural": "Belarusian Rubles",
        "related_terms": ["Belarus Ruble"],
        "withdrawal_date": "2017-01",
        "slug": "byr-belarusian-ruble",
        "popularity": 123
    }
}, {
    "name": "Belizean Dollar",
    "value": {
        "currency_alpha3_code": "BZD",
        "currency_no": 84,
        "minor_unit": "2",
        "description": "Belizean Dollar",
        "plural": "Belizean Dollars",
        "related_terms": ["Belize Dollar"],
        "withdrawal_date": null,
        "slug": "bzd-belizean-dollar",
        "popularity": 164
    }
}, {
    "name": "Canadian Dollar",
    "value": {
        "currency_alpha3_code": "CAD",
        "currency_no": 124,
        "minor_unit": "2",
        "description": "Canadian Dollar",
        "plural": "Canadian Dollars",
        "related_terms": ["Canada Dollar"],
        "withdrawal_date": null,
        "slug": "cad-canadian-dollar",
        "popularity": 4
    }
}, {
    "name": "Congolese Franc",
    "value": {
        "currency_alpha3_code": "CDF",
        "currency_no": 976,
        "minor_unit": "2",
        "description": "Congolese Franc",
        "plural": "Congolese Francs",
        "related_terms": ["Congo/Kinshasa Franc"],
        "withdrawal_date": null,
        "slug": "cdf-congolese-franc",
        "popularity": 143
    }
}, {
    "name": "Swiss Franc",
    "value": {
        "currency_alpha3_code": "CHF",
        "currency_no": 756,
        "minor_unit": "2",
        "description": "Swiss Franc",
        "plural": "Swiss Francs",
        "related_terms": ["Switzerland Franc", "Liechtenstein", "Campione d'Italia", "Büsingen am Hochrhein"],
        "withdrawal_date": null,
        "slug": "chf-swiss-franc",
        "popularity": 9
    }
}, {
    "name": "Chilean Peso",
    "value": {
        "currency_alpha3_code": "CLP",
        "currency_no": 152,
        "minor_unit": "0",
        "description": "Chilean Peso",
        "plural": "Chilean Pesos",
        "related_terms": ["Chile Peso"],
        "withdrawal_date": null,
        "slug": "clp-chilean-peso",
        "popularity": 33
    }
}, {
    "name": "Chinese Yuan Renminbi",
    "value": {
        "currency_alpha3_code": "CNY",
        "currency_no": 156,
        "minor_unit": "2",
        "description": "Chinese Yuan Renminbi",
        "plural": "Chinese Yuan Renminbi",
        "related_terms": ["China Yuan Renminbi"],
        "withdrawal_date": null,
        "slug": "cny-chinese-yuan-renminbi",
        "popularity": 18
    }
}, {
    "name": "Colombian Peso",
    "value": {
        "currency_alpha3_code": "COP",
        "currency_no": 170,
        "minor_unit": "2",
        "description": "Colombian Peso",
        "plural": "Colombian Pesos",
        "related_terms": ["Colombia Peso"],
        "withdrawal_date": null,
        "slug": "cop-colombian-peso",
        "popularity": 39
    }
}, {
    "name": "Costa Rican Colon",
    "value": {
        "currency_alpha3_code": "CRC",
        "currency_no": 188,
        "minor_unit": "2",
        "description": "Costa Rican Colon",
        "plural": "Costa Rican Colones",
        "related_terms": ["Costa Rica Colon"],
        "withdrawal_date": null,
        "slug": "crc-costa-rican-colon",
        "popularity": 53
    }
}, {
    "name": "Cuban Convertible Peso",
    "value": {
        "currency_alpha3_code": "CUC",
        "currency_no": 931,
        "minor_unit": "2",
        "description": "Cuban Convertible Peso",
        "plural": "Cuban Convertible Pesos",
        "related_terms": ["Cuba Convertible Peso"],
        "withdrawal_date": null,
        "slug": "cuc-cuban-convertible-peso",
        "popularity": 165
    }
}, {
    "name": "Cuban Peso",
    "value": {
        "currency_alpha3_code": "CUP",
        "currency_no": 192,
        "minor_unit": "2",
        "description": "Cuban Peso",
        "plural": "Cuban Pesos",
        "related_terms": ["Cuba Peso"],
        "withdrawal_date": null,
        "slug": "cup-cuban-peso",
        "popularity": 86
    }
}, {
    "name": "Cape Verdean Escudo",
    "value": {
        "currency_alpha3_code": "CVE",
        "currency_no": 132,
        "minor_unit": "2",
        "description": "Cape Verdean Escudo",
        "plural": "Cape Verdean Escudos",
        "related_terms": ["Cape Verde Escudo"],
        "withdrawal_date": null,
        "slug": "cve-cape-verdean-escudo",
        "popularity": 100
    }
}, {
    "name": "Czech Koruna",
    "value": {
        "currency_alpha3_code": "CZK",
        "currency_no": 203,
        "minor_unit": "2",
        "description": "Czech Koruna",
        "plural": "Czech Koruny",
        "related_terms": ["Czech Republic Koruna"],
        "withdrawal_date": null,
        "slug": "czk-czech-koruna",
        "popularity": 27
    }
}, {
    "name": "Djiboutian Franc",
    "value": {
        "currency_alpha3_code": "DJF",
        "currency_no": 262,
        "minor_unit": "0",
        "description": "Djiboutian Franc",
        "plural": "Djiboutian Francs",
        "related_terms": ["Djibouti Franc"],
        "withdrawal_date": null,
        "slug": "djf-djiboutian-franc",
        "popularity": 133
    }
}, {
    "name": "Danish Krone",
    "value": {
        "currency_alpha3_code": "DKK",
        "currency_no": 208,
        "minor_unit": "2",
        "description": "Danish Krone",
        "plural": "Danish Kroner",
        "related_terms": ["Denmark Krone", "Faroe Islands", "Greenland"],
        "withdrawal_date": null,
        "slug": "dkk-danish-krone",
        "popularity": 21
    }
}, {
    "name": "Dominican Peso",
    "value": {
        "currency_alpha3_code": "DOP",
        "currency_no": 214,
        "minor_unit": "2",
        "description": "Dominican Peso",
        "plural": "Dominican Pesos",
        "related_terms": ["Dominican Republic Peso"],
        "withdrawal_date": null,
        "slug": "dop-dominican-peso",
        "popularity": 51
    }
}, {
    "name": "Algerian Dinar",
    "value": {
        "currency_alpha3_code": "DZD",
        "currency_no": 12,
        "minor_unit": "2",
        "description": "Algerian Dinar",
        "plural": "Algerian Dinars",
        "related_terms": ["Algeria Dinar"],
        "withdrawal_date": null,
        "slug": "dzd-algerian-dinar",
        "popularity": 56
    }
}, {
    "name": "Estonian Kroon",
    "value": {
        "currency_alpha3_code": "EEK",
        "currency_no": 233,
        "minor_unit": null,
        "description": "Estonian Kroon",
        "plural": "Estonian Krooni",
        "related_terms": ["Estonia Kroon"],
        "withdrawal_date": "2011-01",
        "slug": "eek-estonian-kroon",
        "popularity": 68
    }
}, {
    "name": "Egyptian Pound",
    "value": {
        "currency_alpha3_code": "EGP",
        "currency_no": 818,
        "minor_unit": "2",
        "description": "Egyptian Pound",
        "plural": "Egyptian Pounds",
        "related_terms": ["Egypt Pound", "Gaza Strip"],
        "withdrawal_date": null,
        "slug": "egp-egyptian-pound",
        "popularity": 34
    }
}, {
    "name": "Eritrean Nakfa",
    "value": {
        "currency_alpha3_code": "ERN",
        "currency_no": 232,
        "minor_unit": "2",
        "description": "Eritrean Nakfa",
        "plural": "Eritrean Nakfas",
        "related_terms": ["Eritrea Nakfa"],
        "withdrawal_date": null,
        "slug": "ern-eritrean-nakfa",
        "popularity": 146
    }
}, {
    "name": "Ethiopian Birr",
    "value": {
        "currency_alpha3_code": "ETB",
        "currency_no": 230,
        "minor_unit": "2",
        "description": "Ethiopian Birr",
        "plural": "Ethiopian Birrs",
        "related_terms": ["Ethiopia Birr", "Eritrea"],
        "withdrawal_date": null,
        "slug": "etb-ethiopian-birr",
        "popularity": 106
    }
}, {
    "name": "Euro",
    "value": {
        "currency_alpha3_code": "EUR",
        "currency_no": 978,
        "minor_unit": "2",
        "description": "Euro",
        "plural": "Euros",
        "related_terms": ["Euro Member Countries", "Andorra", "Austria", "Azores", "Baleares (Balearic Islands)", "Belgium", "Canary Islands", "Cyprus", "Finland", "France", "French Guiana", "French Southern Territories", "Germany", "Greece", "Guadeloupe", "Holland (Netherlands)", "Holy See (Vatican City)", "Ireland (Eire)", "Italy", "Luxembourg", "Madeira Islands", "Malta", "Monaco", "Montenegro", "Netherlands", "Portugal", "Réunion", "Saint Pierre and Miquelon", "Saint-Martin", "San Marino", "Slovakia", "Slovenia", "Spain", "Vatican City (The Holy See)", "Estonia", "Lithuania", "Latvia"],
        "withdrawal_date": null,
        "slug": "eur-euro",
        "popularity": 2
    }
}, {
    "name": "Fijian Dollar",
    "value": {
        "currency_alpha3_code": "FJD",
        "currency_no": 242,
        "minor_unit": "2",
        "description": "Fijian Dollar",
        "plural": "Fijian Dollars",
        "related_terms": ["Fiji Dollar"],
        "withdrawal_date": null,
        "slug": "fjd-fijian-dollar",
        "popularity": 42
    }
}, {
    "name": "Falkland Island Pound",
    "value": {
        "currency_alpha3_code": "FKP",
        "currency_no": 238,
        "minor_unit": "2",
        "description": "Falkland Island Pound",
        "plural": "Falkland Island Pounds",
        "related_terms": ["Falkland Islands (Malvinas) Pound"],
        "withdrawal_date": null,
        "slug": "fkp-falkland-island-pound",
        "popularity": 101
    }
}, {
    "name": "British Pound",
    "value": {
        "currency_alpha3_code": "GBP",
        "currency_no": 826,
        "minor_unit": "2",
        "description": "British Pound",
        "plural": "British Pounds",
        "related_terms": ["United Kingdom Pound", "UK", "England", "Northern Ireland", "Scotland", "Wales", "Falkland Islands", "Gibraltar", "Guernsey", "Isle of Man", "Jersey", "Saint Helena and Ascension", "South Georgia and the South Sandwich Islands", "Tristan da Cunha"],
        "withdrawal_date": null,
        "slug": "gbp-british-pound",
        "popularity": 3
    }
}, {
    "name": "Georgian Lari",
    "value": {
        "currency_alpha3_code": "GEL",
        "currency_no": 981,
        "minor_unit": "2",
        "description": "Georgian Lari",
        "plural": "Georgian Lari",
        "related_terms": ["Georgia Lari"],
        "withdrawal_date": null,
        "slug": "gel-georgian-lari",
        "popularity": 105
    }
}, {
    "name": "Ghanaian Cedi",
    "value": {
        "currency_alpha3_code": "GHS",
        "currency_no": 936,
        "minor_unit": "2",
        "description": "Ghanaian Cedi",
        "plural": "Ghanaian Cedis",
        "related_terms": ["Ghana Cedi"],
        "withdrawal_date": null,
        "slug": "ghs-ghanaian-cedi",
        "popularity": 166
    }
}, {
    "name": "Gibraltar Pound",
    "value": {
        "currency_alpha3_code": "GIP",
        "currency_no": 292,
        "minor_unit": "2",
        "description": "Gibraltar Pound",
        "plural": "Gibraltar Pounds",
        "related_terms": ["Gibraltar Pound"],
        "withdrawal_date": null,
        "slug": "gip-gibraltar-pound",
        "popularity": 141
    }
}, {
    "name": "Gambian Dalasi",
    "value": {
        "currency_alpha3_code": "GMD",
        "currency_no": 270,
        "minor_unit": "2",
        "description": "Gambian Dalasi",
        "plural": "Gambian Dalasis",
        "related_terms": ["Gambia Dalasi"],
        "withdrawal_date": null,
        "slug": "gmd-gambian-dalasi",
        "popularity": 124
    }
}, {
    "name": "Guinean Franc",
    "value": {
        "currency_alpha3_code": "GNF",
        "currency_no": 324,
        "minor_unit": "0",
        "description": "Guinean Franc",
        "plural": "Guinean Francs",
        "related_terms": ["Guinea Franc"],
        "withdrawal_date": null,
        "slug": "gnf-guinean-franc",
        "popularity": 122
    }
}, {
    "name": "Guatemalan Quetzal",
    "value": {
        "currency_alpha3_code": "GTQ",
        "currency_no": 320,
        "minor_unit": "2",
        "description": "Guatemalan Quetzal",
        "plural": "Guatemalan Quetzales",
        "related_terms": ["Guatemala Quetzal"],
        "withdrawal_date": null,
        "slug": "gtq-guatemalan-quetzal",
        "popularity": 82
    }
}, {
    "name": "Guyanese Dollar",
    "value": {
        "currency_alpha3_code": "GYD",
        "currency_no": 328,
        "minor_unit": "2",
        "description": "Guyanese Dollar",
        "plural": "Guyanese Dollars",
        "related_terms": ["Guyana Dollar"],
        "withdrawal_date": null,
        "slug": "gyd-guyanese-dollar",
        "popularity": 97
    }
}, {
    "name": "Hong Kong Dollar",
    "value": {
        "currency_alpha3_code": "HKD",
        "currency_no": 344,
        "minor_unit": "2",
        "description": "Hong Kong Dollar",
        "plural": "Hong Kong Dollars",
        "related_terms": ["Hong Kong Dollar"],
        "withdrawal_date": null,
        "slug": "hkd-hong-kong-dollar",
        "popularity": 14
    }
}, {
    "name": "Honduran Lempira",
    "value": {
        "currency_alpha3_code": "HNL",
        "currency_no": 340,
        "minor_unit": "2",
        "description": "Honduran Lempira",
        "plural": "Honduran Lempiras",
        "related_terms": ["Honduras Lempira"],
        "withdrawal_date": null,
        "slug": "hnl-honduran-lempira",
        "popularity": 88
    }
}, {
    "name": "Croatian Kuna",
    "value": {
        "currency_alpha3_code": "HRK",
        "currency_no": 191,
        "minor_unit": "2",
        "description": "Croatian Kuna",
        "plural": "Croatian Kunas",
        "related_terms": ["Croatia Kuna"],
        "withdrawal_date": null,
        "slug": "hrk-croatian-kuna",
        "popularity": 47
    }
}, {
    "name": "Haitian Gourde",
    "value": {
        "currency_alpha3_code": "HTG",
        "currency_no": 332,
        "minor_unit": "2",
        "description": "Haitian Gourde",
        "plural": "Haitian Gourdes",
        "related_terms": ["Haiti Gourde"],
        "withdrawal_date": null,
        "slug": "htg-haitian-gourde",
        "popularity": 94
    }
}, {
    "name": "Hungarian Forint",
    "value": {
        "currency_alpha3_code": "HUF",
        "currency_no": 348,
        "minor_unit": "2",
        "description": "Hungarian Forint",
        "plural": "Hungarian Forints",
        "related_terms": ["Hungary Forint"],
        "withdrawal_date": null,
        "slug": "huf-hungarian-forint",
        "popularity": 17
    }
}, {
    "name": "Indonesian Rupiah",
    "value": {
        "currency_alpha3_code": "IDR",
        "currency_no": 360,
        "minor_unit": "2",
        "description": "Indonesian Rupiah",
        "plural": "Indonesian Rupiahs",
        "related_terms": ["Indonesia Rupiah", "East Timor"],
        "withdrawal_date": null,
        "slug": "idr-indonesian-rupiah",
        "popularity": 26
    }
}, {
    "name": "Israeli Shekel",
    "value": {
        "currency_alpha3_code": "ILS",
        "currency_no": 376,
        "minor_unit": "2",
        "description": "Israeli Shekel",
        "plural": "Israeli New Shekels",
        "related_terms": ["Israel Shekel", "Palestinian Territories"],
        "withdrawal_date": null,
        "slug": "ils-israeli-shekel",
        "popularity": 31
    }
}, {
    "name": "Indian Rupee",
    "value": {
        "currency_alpha3_code": "INR",
        "currency_no": 356,
        "minor_unit": "2",
        "description": "Indian Rupee",
        "plural": "Indian Rupees",
        "related_terms": ["India Rupee", "Bhutan", "Nepal"],
        "withdrawal_date": null,
        "slug": "inr-indian-rupee",
        "popularity": 7
    }
}, {
    "name": "Iraqi Dinar",
    "value": {
        "currency_alpha3_code": "IQD",
        "currency_no": 368,
        "minor_unit": "3",
        "description": "Iraqi Dinar",
        "plural": "Iraqi Dinars",
        "related_terms": ["Iraq Dinar"],
        "withdrawal_date": null,
        "slug": "iqd-iraqi-dinar",
        "popularity": 40
    }
}, {
    "name": "Iranian Rial",
    "value": {
        "currency_alpha3_code": "IRR",
        "currency_no": 364,
        "minor_unit": "2",
        "description": "Iranian Rial",
        "plural": "Iranian Rials",
        "related_terms": ["Iran Rial"],
        "withdrawal_date": null,
        "slug": "irr-iranian-rial",
        "popularity": 63
    }
}, {
    "name": "Icelandic Krona",
    "value": {
        "currency_alpha3_code": "ISK",
        "currency_no": 352,
        "minor_unit": "0",
        "description": "Icelandic Krona",
        "plural": "Icelandic Kronur",
        "related_terms": ["Iceland Krona"],
        "withdrawal_date": null,
        "slug": "isk-icelandic-krona",
        "popularity": 45
    }
}, {
    "name": "Jamaican Dollar",
    "value": {
        "currency_alpha3_code": "JMD",
        "currency_no": 388,
        "minor_unit": "2",
        "description": "Jamaican Dollar",
        "plural": "Jamaican Dollars",
        "related_terms": ["Jamaica Dollar"],
        "withdrawal_date": null,
        "slug": "jmd-jamaican-dollar",
        "popularity": 49
    }
}, {
    "name": "Jordanian Dinar",
    "value": {
        "currency_alpha3_code": "JOD",
        "currency_no": 400,
        "minor_unit": "3",
        "description": "Jordanian Dinar",
        "plural": "Jordanian Dinars",
        "related_terms": ["Jordan Dinar"],
        "withdrawal_date": null,
        "slug": "jod-jordanian-dinar",
        "popularity": 50
    }
}, {
    "name": "Japanese Yen",
    "value": {
        "currency_alpha3_code": "JPY",
        "currency_no": 392,
        "minor_unit": "0",
        "description": "Japanese Yen",
        "plural": "Japanese Yen",
        "related_terms": ["Japan Yen"],
        "withdrawal_date": null,
        "slug": "jpy-japanese-yen",
        "popularity": 6
    }
}, {
    "name": "Kenyan Shilling",
    "value": {
        "currency_alpha3_code": "KES",
        "currency_no": 404,
        "minor_unit": "2",
        "description": "Kenyan Shilling",
        "plural": "Kenyan Shillings",
        "related_terms": ["Kenya Shilling"],
        "withdrawal_date": null,
        "slug": "kes-kenyan-shilling",
        "popularity": 57
    }
}, {
    "name": "Kyrgyzstani Som",
    "value": {
        "currency_alpha3_code": "KGS",
        "currency_no": 417,
        "minor_unit": "2",
        "description": "Kyrgyzstani Som",
        "plural": "Kyrgyzstani Soms",
        "related_terms": ["Kyrgyzstan Som"],
        "withdrawal_date": null,
        "slug": "kgs-kyrgyzstani-som",
        "popularity": 138
    }
}, {
    "name": "Cambodian Riel",
    "value": {
        "currency_alpha3_code": "KHR",
        "currency_no": 116,
        "minor_unit": "2",
        "description": "Cambodian Riel",
        "plural": "Cambodian Riels",
        "related_terms": ["Cambodia Riel"],
        "withdrawal_date": null,
        "slug": "khr-cambodian-riel",
        "popularity": 111
    }
}, {
    "name": "Comorian Franc",
    "value": {
        "currency_alpha3_code": "KMF",
        "currency_no": 174,
        "minor_unit": "0",
        "description": "Comorian Franc",
        "plural": "Comorian Francs",
        "related_terms": ["Comorian Franc"],
        "withdrawal_date": null,
        "slug": "kmf-comorian-franc",
        "popularity": 154
    }
}, {
    "name": "North Korean Won",
    "value": {
        "currency_alpha3_code": "KPW",
        "currency_no": 408,
        "minor_unit": "2",
        "description": "North Korean Won",
        "plural": "North Korean Won",
        "related_terms": ["Korea (North) Won"],
        "withdrawal_date": null,
        "slug": "kpw-north-korean-won",
        "popularity": 131
    }
}, {
    "name": "South Korean Won",
    "value": {
        "currency_alpha3_code": "KRW",
        "currency_no": 410,
        "minor_unit": "0",
        "description": "South Korean Won",
        "plural": "South Korean Won",
        "related_terms": ["Korea (South) Won"],
        "withdrawal_date": null,
        "slug": "krw-south-korean-won",
        "popularity": 30
    }
}, {
    "name": "Kuwaiti Dinar",
    "value": {
        "currency_alpha3_code": "KWD",
        "currency_no": 414,
        "minor_unit": "3",
        "description": "Kuwaiti Dinar",
        "plural": "Kuwaiti Dinars",
        "related_terms": ["Kuwait Dinar"],
        "withdrawal_date": null,
        "slug": "kwd-kuwaiti-dinar",
        "popularity": 43
    }
}, {
    "name": "Caymanian Dollar",
    "value": {
        "currency_alpha3_code": "KYD",
        "currency_no": 136,
        "minor_unit": "2",
        "description": "Caymanian Dollar",
        "plural": "Caymanian Dollars",
        "related_terms": ["Cayman Islands Dollar"],
        "withdrawal_date": null,
        "slug": "kyd-caymanian-dollar",
        "popularity": 98
    }
}, {
    "name": "Kazakhstani Tenge",
    "value": {
        "currency_alpha3_code": "KZT",
        "currency_no": 398,
        "minor_unit": "2",
        "description": "Kazakhstani Tenge",
        "plural": "Kazakhstani Tenge",
        "related_terms": ["Kazakhstan Tenge"],
        "withdrawal_date": null,
        "slug": "kzt-kazakhstani-tenge",
        "popularity": 117
    }
}, {
    "name": "Lao Kip",
    "value": {
        "currency_alpha3_code": "LAK",
        "currency_no": 418,
        "minor_unit": "2",
        "description": "Lao Kip",
        "plural": "Lao Kips",
        "related_terms": ["Laos Kip"],
        "withdrawal_date": null,
        "slug": "lak-lao-kip",
        "popularity": 128
    }
}, {
    "name": "Lebanese Pound",
    "value": {
        "currency_alpha3_code": "LBP",
        "currency_no": 422,
        "minor_unit": "2",
        "description": "Lebanese Pound",
        "plural": "Lebanese Pounds",
        "related_terms": ["Lebanon Pound"],
        "withdrawal_date": null,
        "slug": "lbp-lebanese-pound",
        "popularity": 72
    }
}, {
    "name": "Sri Lankan Rupee",
    "value": {
        "currency_alpha3_code": "LKR",
        "currency_no": 144,
        "minor_unit": "2",
        "description": "Sri Lankan Rupee",
        "plural": "Sri Lankan Rupees",
        "related_terms": ["Sri Lanka Rupee"],
        "withdrawal_date": null,
        "slug": "lkr-sri-lankan-rupee",
        "popularity": 59
    }
}, {
    "name": "Liberian Dollar",
    "value": {
        "currency_alpha3_code": "LRD",
        "currency_no": 430,
        "minor_unit": "2",
        "description": "Liberian Dollar",
        "plural": "Liberian Dollars",
        "related_terms": ["Liberia Dollar"],
        "withdrawal_date": null,
        "slug": "lrd-liberian-dollar",
        "popularity": 150
    }
}, {
    "name": "Basotho Loti",
    "value": {
        "currency_alpha3_code": "LSL",
        "currency_no": 426,
        "minor_unit": "2",
        "description": "Basotho Loti",
        "plural": "Basotho Maloti",
        "related_terms": ["Lesotho Loti"],
        "withdrawal_date": null,
        "slug": "lsl-basotho-loti",
        "popularity": 151
    }
}, {
    "name": "Lithuanian Litas",
    "value": {
        "currency_alpha3_code": "LTL",
        "currency_no": 440,
        "minor_unit": null,
        "description": "Lithuanian Litas",
        "plural": "Lithuanian Litai",
        "related_terms": ["Lithuania Litas"],
        "withdrawal_date": "2014-12",
        "slug": "ltl-lithuanian-litas",
        "popularity": 89
    }
}, {
    "name": "Latvian Lat",
    "value": {
        "currency_alpha3_code": "LVL",
        "currency_no": 428,
        "minor_unit": null,
        "description": "Latvian Lat",
        "plural": "Latvian Lati",
        "related_terms": ["Latvia Lat"],
        "withdrawal_date": "2014-01",
        "slug": "lvl-latvian-lat",
        "popularity": 80
    }
}, {
    "name": "Libyan Dinar",
    "value": {
        "currency_alpha3_code": "LYD",
        "currency_no": 434,
        "minor_unit": "3",
        "description": "Libyan Dinar",
        "plural": "Libyan Dinars",
        "related_terms": ["Libya Dinar"],
        "withdrawal_date": null,
        "slug": "lyd-libyan-dinar",
        "popularity": 120
    }
}, {
    "name": "Moroccan Dirham",
    "value": {
        "currency_alpha3_code": "MAD",
        "currency_no": 504,
        "minor_unit": "2",
        "description": "Moroccan Dirham",
        "plural": "Moroccan Dirhams",
        "related_terms": ["Morocco Dirham", "Western Sahara"],
        "withdrawal_date": null,
        "slug": "mad-moroccan-dirham",
        "popularity": 46
    }
}, {
    "name": "Moldovan Leu",
    "value": {
        "currency_alpha3_code": "MDL",
        "currency_no": 498,
        "minor_unit": "2",
        "description": "Moldovan Leu",
        "plural": "Moldovan Lei",
        "related_terms": ["Moldova Leu"],
        "withdrawal_date": null,
        "slug": "mdl-moldovan-leu",
        "popularity": 107
    }
}, {
    "name": "Malagasy Ariary",
    "value": {
        "currency_alpha3_code": "MGA",
        "currency_no": 969,
        "minor_unit": "2",
        "description": "Malagasy Ariary",
        "plural": "Malagasy Ariary",
        "related_terms": ["Madagascar Ariary"],
        "withdrawal_date": null,
        "slug": "mga-malagasy-ariary",
        "popularity": 104
    }
}, {
    "name": "Macedonian Denar",
    "value": {
        "currency_alpha3_code": "MKD",
        "currency_no": 807,
        "minor_unit": "2",
        "description": "Macedonian Denar",
        "plural": "Macedonian Denars",
        "related_terms": ["Macedonia Denar"],
        "withdrawal_date": null,
        "slug": "mkd-macedonian-denar",
        "popularity": 113
    }
}, {
    "name": "Burmese Kyat",
    "value": {
        "currency_alpha3_code": "MMK",
        "currency_no": 104,
        "minor_unit": "2",
        "description": "Burmese Kyat",
        "plural": "Burmese Kyats",
        "related_terms": ["Myanmar (Burma) Kyat"],
        "withdrawal_date": null,
        "slug": "mmk-burmese-kyat",
        "popularity": 116
    }
}, {
    "name": "Mongolian Tughrik",
    "value": {
        "currency_alpha3_code": "MNT",
        "currency_no": 496,
        "minor_unit": "2",
        "description": "Mongolian Tughrik",
        "plural": "Mongolian Tugriks",
        "related_terms": ["Mongolia Tughrik"],
        "withdrawal_date": null,
        "slug": "mnt-mongolian-tughrik",
        "popularity": 134
    }
}, {
    "name": "Macau Pataca",
    "value": {
        "currency_alpha3_code": "MOP",
        "currency_no": 446,
        "minor_unit": "2",
        "description": "Macau Pataca",
        "plural": "Macau Patacas",
        "related_terms": ["Macau Pataca"],
        "withdrawal_date": null,
        "slug": "mop-macau-pataca",
        "popularity": 118
    }
}, {
    "name": "Mauritanian Ouguiya",
    "value": {
        "currency_alpha3_code": "MRU",
        "currency_no": 929,
        "minor_unit": "2",
        "description": "Mauritanian Ouguiya",
        "plural": "Mauritanian Ouguiyas",
        "related_terms": ["Mauritania Ouguiya"],
        "withdrawal_date": null,
        "slug": "mru-mauritanian-ouguiya",
        "popularity": 145
    }
}, {
    "name": "Mauritian Rupee",
    "value": {
        "currency_alpha3_code": "MUR",
        "currency_no": 480,
        "minor_unit": "2",
        "description": "Mauritian Rupee",
        "plural": "Mauritian Rupees",
        "related_terms": ["Mauritius Rupee"],
        "withdrawal_date": null,
        "slug": "mur-mauritian-rupee",
        "popularity": 74
    }
}, {
    "name": "Maldivian Rufiyaa",
    "value": {
        "currency_alpha3_code": "MVR",
        "currency_no": 462,
        "minor_unit": "2",
        "description": "Maldivian Rufiyaa",
        "plural": "Maldivian Rufiyaa",
        "related_terms": ["Maldives (Maldive Islands) Rufiyaa"],
        "withdrawal_date": null,
        "slug": "mvr-maldivian-rufiyaa",
        "popularity": 144
    }
}, {
    "name": "Malawian Kwacha",
    "value": {
        "currency_alpha3_code": "MWK",
        "currency_no": 454,
        "minor_unit": "2",
        "description": "Malawian Kwacha",
        "plural": "Malawian Kwachas",
        "related_terms": ["Malawi Kwacha"],
        "withdrawal_date": null,
        "slug": "mwk-malawian-kwacha",
        "popularity": 130
    }
}, {
    "name": "Mexican Peso",
    "value": {
        "currency_alpha3_code": "MXN",
        "currency_no": 484,
        "minor_unit": "2",
        "description": "Mexican Peso",
        "plural": "Mexican Pesos",
        "related_terms": ["Mexico Peso"],
        "withdrawal_date": null,
        "slug": "mxn-mexican-peso",
        "popularity": 20
    }
}, {
    "name": "Malaysian Ringgit",
    "value": {
        "currency_alpha3_code": "MYR",
        "currency_no": 458,
        "minor_unit": "2",
        "description": "Malaysian Ringgit",
        "plural": "Malaysian Ringgits",
        "related_terms": ["Malaysia Ringgit"],
        "withdrawal_date": null,
        "slug": "myr-malaysian-ringgit",
        "popularity": 22
    }
}, {
    "name": "Mozambican Metical",
    "value": {
        "currency_alpha3_code": "MZN",
        "currency_no": 943,
        "minor_unit": "2",
        "description": "Mozambican Metical",
        "plural": "Mozambican Meticais",
        "related_terms": ["Mozambique Metical"],
        "withdrawal_date": null,
        "slug": "mzn-mozambican-metical",
        "popularity": 119
    }
}, {
    "name": "Namibian Dollar",
    "value": {
        "currency_alpha3_code": "NAD",
        "currency_no": 516,
        "minor_unit": "2",
        "description": "Namibian Dollar",
        "plural": "Namibian Dollars",
        "related_terms": ["Namibia Dollar"],
        "withdrawal_date": null,
        "slug": "nad-namibian-dollar",
        "popularity": 112
    }
}, {
    "name": "Nigerian Naira",
    "value": {
        "currency_alpha3_code": "NGN",
        "currency_no": 566,
        "minor_unit": "2",
        "description": "Nigerian Naira",
        "plural": "Nigerian Nairas",
        "related_terms": ["Nigeria Naira"],
        "withdrawal_date": null,
        "slug": "ngn-nigerian-naira",
        "popularity": 71
    }
}, {
    "name": "Nicaraguan Cordoba",
    "value": {
        "currency_alpha3_code": "NIO",
        "currency_no": 558,
        "minor_unit": "2",
        "description": "Nicaraguan Cordoba",
        "plural": "Nicaraguan Cordobas",
        "related_terms": ["Nicaragua Cordoba"],
        "withdrawal_date": null,
        "slug": "nio-nicaraguan-cordoba",
        "popularity": 91
    }
}, {
    "name": "Norwegian Krone",
    "value": {
        "currency_alpha3_code": "NOK",
        "currency_no": 578,
        "minor_unit": "2",
        "description": "Norwegian Krone",
        "plural": "Norwegian Kroner",
        "related_terms": ["Norway Krone", "Bouvet Island", "Svalbard", "Jan Mayen", "Queen Maud Land", "Peter I Island"],
        "withdrawal_date": null,
        "slug": "nok-norwegian-krone",
        "popularity": 19
    }
}, {
    "name": "Nepalese Rupee",
    "value": {
        "currency_alpha3_code": "NPR",
        "currency_no": 524,
        "minor_unit": "2",
        "description": "Nepalese Rupee",
        "plural": "Nepalese Rupees",
        "related_terms": ["Nepal Rupee", "India (unofficially near India-Nepal border)"],
        "withdrawal_date": null,
        "slug": "npr-nepalese-rupee",
        "popularity": 93
    }
}, {
    "name": "New Zealand Dollar",
    "value": {
        "currency_alpha3_code": "NZD",
        "currency_no": 554,
        "minor_unit": "2",
        "description": "New Zealand Dollar",
        "plural": "New Zealand Dollars",
        "related_terms": ["New Zealand Dollar", "Cook Islands", "Niue", "Pitcairn Islands", "Tokelau"],
        "withdrawal_date": null,
        "slug": "nzd-new-zealand-dollar",
        "popularity": 8
    }
}, {
    "name": "Omani Rial",
    "value": {
        "currency_alpha3_code": "OMR",
        "currency_no": 512,
        "minor_unit": "3",
        "description": "Omani Rial",
        "plural": "Omani Rials",
        "related_terms": ["Oman Rial"],
        "withdrawal_date": null,
        "slug": "omr-omani-rial",
        "popularity": 60
    }
}, {
    "name": "Panamanian Balboa",
    "value": {
        "currency_alpha3_code": "PAB",
        "currency_no": 590,
        "minor_unit": "2",
        "description": "Panamanian Balboa",
        "plural": "Panamanian Balboa",
        "related_terms": ["Panama Balboa"],
        "withdrawal_date": null,
        "slug": "pab-panamanian-balboa",
        "popularity": 95
    }
}, {
    "name": "Peruvian Sol",
    "value": {
        "currency_alpha3_code": "PEN",
        "currency_no": 604,
        "minor_unit": "2",
        "description": "Peruvian Sol",
        "plural": "Peruvian Soles",
        "related_terms": ["Peru Sol"],
        "withdrawal_date": null,
        "slug": "pen-peruvian-sol",
        "popularity": 52
    }
}, {
    "name": "Papua New Guinean Kina",
    "value": {
        "currency_alpha3_code": "PGK",
        "currency_no": 598,
        "minor_unit": "2",
        "description": "Papua New Guinean Kina",
        "plural": "Papua New Guinean Kina",
        "related_terms": ["Papua New Guinea Kina"],
        "withdrawal_date": null,
        "slug": "pgk-papua-new-guinean-kina",
        "popularity": 115
    }
}, {
    "name": "Philippine Peso",
    "value": {
        "currency_alpha3_code": "PHP",
        "currency_no": 608,
        "minor_unit": "2",
        "description": "Philippine Peso",
        "plural": "Philippine Pesos",
        "related_terms": ["Philippines Peso"],
        "withdrawal_date": null,
        "slug": "php-philippine-peso",
        "popularity": 25
    }
}, {
    "name": "Pakistani Rupee",
    "value": {
        "currency_alpha3_code": "PKR",
        "currency_no": 586,
        "minor_unit": "2",
        "description": "Pakistani Rupee",
        "plural": "Pakistani Rupees",
        "related_terms": ["Pakistan Rupee"],
        "withdrawal_date": null,
        "slug": "pkr-pakistani-rupee",
        "popularity": 38
    }
}, {
    "name": "Polish Zloty",
    "value": {
        "currency_alpha3_code": "PLN",
        "currency_no": 985,
        "minor_unit": "2",
        "description": "Polish Zloty",
        "plural": "Polish Zlotych",
        "related_terms": ["Poland Zloty"],
        "withdrawal_date": null,
        "slug": "pln-polish-zloty",
        "popularity": 23
    }
}, {
    "name": "Paraguayan Guarani",
    "value": {
        "currency_alpha3_code": "PYG",
        "currency_no": 600,
        "minor_unit": "0",
        "description": "Paraguayan Guarani",
        "plural": "Paraguayan Guarani",
        "related_terms": ["Paraguay Guarani"],
        "withdrawal_date": null,
        "slug": "pyg-paraguayan-guarani",
        "popularity": 87
    }
}, {
    "name": "Qatari Riyal",
    "value": {
        "currency_alpha3_code": "QAR",
        "currency_no": 634,
        "minor_unit": "2",
        "description": "Qatari Riyal",
        "plural": "Qatari Rials",
        "related_terms": ["Qatar Riyal"],
        "withdrawal_date": null,
        "slug": "qar-qatari-riyal",
        "popularity": 61
    }
}, {
    "name": "Romanian Leu",
    "value": {
        "currency_alpha3_code": "RON",
        "currency_no": 946,
        "minor_unit": "2",
        "description": "Romanian Leu",
        "plural": "Romanian Lei",
        "related_terms": ["Romania Leu"],
        "withdrawal_date": null,
        "slug": "ron-romanian-leu",
        "popularity": 36
    }
}, {
    "name": "Serbian Dinar",
    "value": {
        "currency_alpha3_code": "RSD",
        "currency_no": 941,
        "minor_unit": "2",
        "description": "Serbian Dinar",
        "plural": "Serbian Dinars",
        "related_terms": ["Serbia Dinar"],
        "withdrawal_date": null,
        "slug": "rsd-serbian-dinar",
        "popularity": 92
    }
}, {
    "name": "Russian Ruble",
    "value": {
        "currency_alpha3_code": "RUB",
        "currency_no": 643,
        "minor_unit": "2",
        "description": "Russian Ruble",
        "plural": "Russian Rubles",
        "related_terms": ["Russia Ruble", "Tajikistan"],
        "withdrawal_date": null,
        "slug": "rub-russian-ruble",
        "popularity": 11
    }
}, {
    "name": "Rwandan Franc",
    "value": {
        "currency_alpha3_code": "RWF",
        "currency_no": 646,
        "minor_unit": "0",
        "description": "Rwandan Franc",
        "plural": "Rwandan Francs",
        "related_terms": ["Rwanda Franc"],
        "withdrawal_date": null,
        "slug": "rwf-rwandan-franc",
        "popularity": 142
    }
}, {
    "name": "Saudi Arabian Riyal",
    "value": {
        "currency_alpha3_code": "SAR",
        "currency_no": 682,
        "minor_unit": "2",
        "description": "Saudi Arabian Riyal",
        "plural": "Saudi Arabian Riyals",
        "related_terms": ["Saudi Arabia Riyal"],
        "withdrawal_date": null,
        "slug": "sar-saudi-arabian-riyal",
        "popularity": 37
    }
}, {
    "name": "Solomon Islander Dollar",
    "value": {
        "currency_alpha3_code": "SBD",
        "currency_no": 90,
        "minor_unit": "2",
        "description": "Solomon Islander Dollar",
        "plural": "Solomon Islander Dollars",
        "related_terms": ["Solomon Islands Dollar"],
        "withdrawal_date": null,
        "slug": "sbd-solomon-islander-dollar",
        "popularity": 140
    }
}, {
    "name": "Seychellois Rupee",
    "value": {
        "currency_alpha3_code": "SCR",
        "currency_no": 690,
        "minor_unit": "2",
        "description": "Seychellois Rupee",
        "plural": "Seychellois Rupees",
        "related_terms": ["Seychelles Rupee"],
        "withdrawal_date": null,
        "slug": "scr-seychellois-rupee",
        "popularity": 137
    }
}, {
    "name": "Sudanese Pound",
    "value": {
        "currency_alpha3_code": "SDG",
        "currency_no": 938,
        "minor_unit": "2",
        "description": "Sudanese Pound",
        "plural": "Sudanese Pounds",
        "related_terms": ["Sudan Pound"],
        "withdrawal_date": null,
        "slug": "sdg-sudanese-pound",
        "popularity": 167
    }
}, {
    "name": "Swedish Krona",
    "value": {
        "currency_alpha3_code": "SEK",
        "currency_no": 752,
        "minor_unit": "2",
        "description": "Swedish Krona",
        "plural": "Swedish Kronor",
        "related_terms": ["Sweden Krona"],
        "withdrawal_date": null,
        "slug": "sek-swedish-krona",
        "popularity": 15
    }
}, {
    "name": "Singapore Dollar",
    "value": {
        "currency_alpha3_code": "SGD",
        "currency_no": 702,
        "minor_unit": "2",
        "description": "Singapore Dollar",
        "plural": "Singapore Dollars",
        "related_terms": ["Singapore Dollar"],
        "withdrawal_date": null,
        "slug": "sgd-singapore-dollar",
        "popularity": 13
    }
}, {
    "name": "Saint Helenian Pound",
    "value": {
        "currency_alpha3_code": "SHP",
        "currency_no": 654,
        "minor_unit": "2",
        "description": "Saint Helenian Pound",
        "plural": "Saint Helenian Pounds",
        "related_terms": ["Saint Helena Pound"],
        "withdrawal_date": null,
        "slug": "shp-saint-helenian-pound",
        "popularity": 152
    }
}, {
    "name": "Sierra Leonean Leone",
    "value": {
        "currency_alpha3_code": "SLL",
        "currency_no": 694,
        "minor_unit": "2",
        "description": "Sierra Leonean Leone",
        "plural": "Sierra Leonean Leones",
        "related_terms": ["Sierra Leone Leone"],
        "withdrawal_date": null,
        "slug": "sll-sierra-leonean-leone",
        "popularity": 121
    }
}, {
    "name": "Somali Shilling",
    "value": {
        "currency_alpha3_code": "SOS",
        "currency_no": 706,
        "minor_unit": "2",
        "description": "Somali Shilling",
        "plural": "Somali Shillings",
        "related_terms": ["Somalia Shilling"],
        "withdrawal_date": null,
        "slug": "sos-somali-shilling",
        "popularity": 147
    }
}, {
    "name": "Surinamese Dollar",
    "value": {
        "currency_alpha3_code": "SRD",
        "currency_no": 968,
        "minor_unit": "2",
        "description": "Surinamese Dollar",
        "plural": "Surinamese Dollars",
        "related_terms": ["Suriname Dollar"],
        "withdrawal_date": null,
        "slug": "srd-surinamese-dollar",
        "popularity": 157
    }
}, {
    "name": "Sao Tomean Dobra",
    "value": {
        "currency_alpha3_code": "STN",
        "currency_no": 930,
        "minor_unit": "2",
        "description": "Sao Tomean Dobra",
        "plural": "Sao Tomean Dobras",
        "related_terms": ["São Tomé and Príncipe Dobra"],
        "withdrawal_date": null,
        "slug": "stn-sao-tomean-dobra",
        "popularity": 153
    }
}, {
    "name": "Salvadoran Colon",
    "value": {
        "currency_alpha3_code": "SVC",
        "currency_no": 222,
        "minor_unit": "2",
        "description": "Salvadoran Colon",
        "plural": "Salvadoran Colones",
        "related_terms": ["El Salvador Colon"],
        "withdrawal_date": null,
        "slug": "svc-salvadoran-colon",
        "popularity": 96
    }
}, {
    "name": "Syrian Pound",
    "value": {
        "currency_alpha3_code": "SYP",
        "currency_no": 760,
        "minor_unit": "2",
        "description": "Syrian Pound",
        "plural": "Syrian Pounds",
        "related_terms": ["Syria Pound"],
        "withdrawal_date": null,
        "slug": "syp-syrian-pound",
        "popularity": 109
    }
}, {
    "name": "Swazi Lilangeni",
    "value": {
        "currency_alpha3_code": "SZL",
        "currency_no": 748,
        "minor_unit": "2",
        "description": "Swazi Lilangeni",
        "plural": "Swazi Emalangeni",
        "related_terms": ["eSwatini Lilangeni"],
        "withdrawal_date": null,
        "slug": "szl-swazi-lilangeni",
        "popularity": 148
    }
}, {
    "name": "Thai Baht",
    "value": {
        "currency_alpha3_code": "THB",
        "currency_no": 764,
        "minor_unit": "2",
        "description": "Thai Baht",
        "plural": "Thai Baht",
        "related_terms": ["Thailand Baht"],
        "withdrawal_date": null,
        "slug": "thb-thai-baht",
        "popularity": 16
    }
}, {
    "name": "Tajikistani Somoni",
    "value": {
        "currency_alpha3_code": "TJS",
        "currency_no": 972,
        "minor_unit": "2",
        "description": "Tajikistani Somoni",
        "plural": "Tajikistani Somoni",
        "related_terms": ["Tajikistan Somoni"],
        "withdrawal_date": null,
        "slug": "tjs-tajikistani-somoni",
        "popularity": 149
    }
}, {
    "name": "Turkmenistani Manat",
    "value": {
        "currency_alpha3_code": "TMT",
        "currency_no": 934,
        "minor_unit": "2",
        "description": "Turkmenistani Manat",
        "plural": "Turkmenistani Manats",
        "related_terms": ["Turkmenistan Manat"],
        "withdrawal_date": null,
        "slug": "tmt-turkmenistani-manat",
        "popularity": 156
    }
}, {
    "name": "Tunisian Dinar",
    "value": {
        "currency_alpha3_code": "TND",
        "currency_no": 788,
        "minor_unit": "3",
        "description": "Tunisian Dinar",
        "plural": "Tunisian Dinars",
        "related_terms": ["Tunisia Dinar"],
        "withdrawal_date": null,
        "slug": "tnd-tunisian-dinar",
        "popularity": 65
    }
}, {
    "name": "Tongan Pa'anga",
    "value": {
        "currency_alpha3_code": "TOP",
        "currency_no": 776,
        "minor_unit": "2",
        "description": "Tongan Pa'anga",
        "plural": "Tongan Pa'anga",
        "related_terms": ["Tonga Pa'anga"],
        "withdrawal_date": null,
        "slug": "top-tongan-pa'anga",
        "popularity": 136
    }
}, {
    "name": "Turkish Lira",
    "value": {
        "currency_alpha3_code": "TRY",
        "currency_no": 949,
        "minor_unit": "2",
        "description": "Turkish Lira",
        "plural": "Turkish Lire",
        "related_terms": ["Turkey Lira", "North Cyprus"],
        "withdrawal_date": null,
        "slug": "try-turkish-lira",
        "popularity": 35
    }
}, {
    "name": "Trinidadian Dollar",
    "value": {
        "currency_alpha3_code": "TTD",
        "currency_no": 780,
        "minor_unit": "2",
        "description": "Trinidadian Dollar",
        "plural": "Trinidadian Dollars",
        "related_terms": ["Trinidad and Tobago Dollar", "Trinidad", "Tobago"],
        "withdrawal_date": null,
        "slug": "ttd-trinidadian-dollar",
        "popularity": 66
    }
}, {
    "name": "Taiwan New Dollar",
    "value": {
        "currency_alpha3_code": "TWD",
        "currency_no": 901,
        "minor_unit": "2",
        "description": "Taiwan New Dollar",
        "plural": "Taiwan New Dollars",
        "related_terms": ["Taiwan New Dollar"],
        "withdrawal_date": null,
        "slug": "twd-taiwan-new-dollar",
        "popularity": 29
    }
}, {
    "name": "Tanzanian Shilling",
    "value": {
        "currency_alpha3_code": "TZS",
        "currency_no": 834,
        "minor_unit": "2",
        "description": "Tanzanian Shilling",
        "plural": "Tanzanian Shillings",
        "related_terms": ["Tanzania Shilling"],
        "withdrawal_date": null,
        "slug": "tzs-tanzanian-shilling",
        "popularity": 99
    }
}, {
    "name": "Ukrainian Hryvnia",
    "value": {
        "currency_alpha3_code": "UAH",
        "currency_no": 980,
        "minor_unit": "2",
        "description": "Ukrainian Hryvnia",
        "plural": "Ukrainian Hryvni",
        "related_terms": ["Ukraine Hryvnia"],
        "withdrawal_date": null,
        "slug": "uah-ukrainian-hryvnia",
        "popularity": 81
    }
}, {
    "name": "Ugandan Shilling",
    "value": {
        "currency_alpha3_code": "UGX",
        "currency_no": 800,
        "minor_unit": "0",
        "description": "Ugandan Shilling",
        "plural": "Ugandan Shillings",
        "related_terms": ["Uganda Shilling"],
        "withdrawal_date": null,
        "slug": "ugx-ugandan-shilling",
        "popularity": 103
    }
}, {
    "name": "US Dollar",
    "value": {
        "currency_alpha3_code": "USD",
        "currency_no": 840,
        "minor_unit": "2",
        "description": "US Dollar",
        "plural": "US Dollars",
        "related_terms": ["United States Dollar", "America", "American Samoa", "American Virgin Islands", "British Indian Ocean Territory", "British Virgin Islands", "Ecuador", "El Salvador", "Guam", "Haiti", "Micronesia", "Northern Mariana Islands", "Palau", "Panama", "Puerto Rico", "Turks and Caicos Islands", "United States Minor Outlying Islands", "Wake Island", "East Timor"],
        "withdrawal_date": null,
        "slug": "usd-us-dollar",
        "popularity": 1
    }
}, {
    "name": "Uruguayan Peso",
    "value": {
        "currency_alpha3_code": "UYU",
        "currency_no": 858,
        "minor_unit": "2",
        "description": "Uruguayan Peso",
        "plural": "Uruguayan Pesos",
        "related_terms": ["Uruguay Peso"],
        "withdrawal_date": null,
        "slug": "uyu-uruguayan-peso",
        "popularity": 78
    }
}, {
    "name": "Uzbekistani Som",
    "value": {
        "currency_alpha3_code": "UZS",
        "currency_no": 860,
        "minor_unit": "2",
        "description": "Uzbekistani Som",
        "plural": "Uzbekistani Sums",
        "related_terms": ["Uzbekistan Som"],
        "withdrawal_date": null,
        "slug": "uzs-uzbekistani-som",
        "popularity": 135
    }
}, {
    "name": "Venezuelan Bolívar",
    "value": {
        "currency_alpha3_code": "VEF",
        "currency_no": 937,
        "minor_unit": null,
        "description": "Venezuelan Bolívar",
        "plural": "Venezuelan Bolívares",
        "related_terms": ["Venezuela Bolívar"],
        "withdrawal_date": "2011-12",
        "slug": "vef-venezuelan-bolívar",
        "popularity": 169
    }
}, {
    "name": "Venezuelan Bolívar",
    "value": {
        "currency_alpha3_code": "VES",
        "currency_no": 928,
        "minor_unit": "2",
        "description": "Venezuelan Bolívar",
        "plural": "Venezuelan Bolívares",
        "related_terms": ["Venezuela Bolívar"],
        "withdrawal_date": null,
        "slug": "ves-venezuelan-bolívar",
        "popularity": 168
    }
}, {
    "name": "Vietnamese Dong",
    "value": {
        "currency_alpha3_code": "VND",
        "currency_no": 704,
        "minor_unit": "0",
        "description": "Vietnamese Dong",
        "plural": "Vietnamese Dongs",
        "related_terms": ["Viet Nam Dong"],
        "withdrawal_date": null,
        "slug": "vnd-vietnamese-dong",
        "popularity": 48
    }
}, {
    "name": "Ni-Vanuatu Vatu",
    "value": {
        "currency_alpha3_code": "VUV",
        "currency_no": 548,
        "minor_unit": "0",
        "description": "Ni-Vanuatu Vatu",
        "plural": "Ni-Vanuatu Vatu",
        "related_terms": ["Vanuatu Vatu"],
        "withdrawal_date": null,
        "slug": "vuv-ni-vanuatu-vatu",
        "popularity": 108
    }
}, {
    "name": "Samoan Tala",
    "value": {
        "currency_alpha3_code": "WST",
        "currency_no": 882,
        "minor_unit": "2",
        "description": "Samoan Tala",
        "plural": "Samoan Tala",
        "related_terms": ["Samoa Tala"],
        "withdrawal_date": null,
        "slug": "wst-samoan-tala",
        "popularity": 129
    }
}, {
    "name": "Central African CFA Franc BEAC",
    "value": {
        "currency_alpha3_code": "XAF",
        "currency_no": 950,
        "minor_unit": "0",
        "description": "Central African CFA Franc BEAC",
        "plural": "Central African Francs",
        "related_terms": ["Communauté Financière Africaine (BEAC) CFA Franc BEAC", "Cameroon", "Central African Republic", "Chad", "Congo/Brazzaville", "Equatorial Guinea", "Gabon"],
        "withdrawal_date": null,
        "slug": "xaf-central-african-cfa-franc-beac",
        "popularity": 73
    }
}, {
    "name": "Silver Ounce",
    "value": {
        "currency_alpha3_code": "XAG",
        "currency_no": 961,
        "minor_unit": "-",
        "description": "Silver Ounce",
        "plural": "Silver Ounces",
        "related_terms": ["Silver"],
        "withdrawal_date": null,
        "slug": "xag-silver-ounce",
        "popularity": 58
    }
}, {
    "name": "Gold Ounce",
    "value": {
        "currency_alpha3_code": "XAU",
        "currency_no": 959,
        "minor_unit": "-",
        "description": "Gold Ounce",
        "plural": "Gold Ounces",
        "related_terms": ["Gold"],
        "withdrawal_date": null,
        "slug": "xau-gold-ounce",
        "popularity": 41
    }
}, {
    "name": "East Caribbean Dollar",
    "value": {
        "currency_alpha3_code": "XCD",
        "currency_no": 951,
        "minor_unit": "2",
        "description": "East Caribbean Dollar",
        "plural": "East Caribbean Dollars",
        "related_terms": ["East Caribbean Dollar", "Anguilla", "Antigua and Barbuda", "Dominica", "Grenada", "The Grenadines and Saint Vincent", "Montserrat"],
        "withdrawal_date": null,
        "slug": "xcd-east-caribbean-dollar",
        "popularity": 64
    }
}, {
    "name": "IMF Special Drawing Rights",
    "value": {
        "currency_alpha3_code": "XDR",
        "currency_no": 960,
        "minor_unit": "-",
        "description": "IMF Special Drawing Rights",
        "plural": "IMF Special Drawing Rights",
        "related_terms": ["International Monetary Fund (IMF) Special Drawing Rights"],
        "withdrawal_date": null,
        "slug": "xdr-imf-special-drawing-rights",
        "popularity": 83
    }
}, {
    "name": "CFA Franc",
    "value": {
        "currency_alpha3_code": "XOF",
        "currency_no": 952,
        "minor_unit": "0",
        "description": "CFA Franc",
        "plural": "CFA Francs",
        "related_terms": ["Communauté Financière Africaine (BCEAO) Franc", "Benin", "Burkina Faso", "Ivory Coast", "Guinea-Bissau", "Mali", "Niger", "Senegal", "Togo"],
        "withdrawal_date": null,
        "slug": "xof-cfa-franc",
        "popularity": 62
    }
}, {
    "name": "Palladium Ounce",
    "value": {
        "currency_alpha3_code": "XPD",
        "currency_no": 964,
        "minor_unit": "-",
        "description": "Palladium Ounce",
        "plural": "Palladium Ounces",
        "related_terms": ["Palladium"],
        "withdrawal_date": null,
        "slug": "xpd-palladium-ounce",
        "popularity": 170
    }
}, {
    "name": "CFP Franc",
    "value": {
        "currency_alpha3_code": "XPF",
        "currency_no": 953,
        "minor_unit": "0",
        "description": "CFP Franc",
        "plural": "CFP Francs",
        "related_terms": ["Comptoirs Français du Pacifique (CFP) Franc", "French Polynesia", "New Caledonia", "Wallis and Futuna Islands"],
        "withdrawal_date": null,
        "slug": "xpf-cfp-franc",
        "popularity": 67
    }
}, {
    "name": "Platinum Ounce",
    "value": {
        "currency_alpha3_code": "XPT",
        "currency_no": 962,
        "minor_unit": "-",
        "description": "Platinum Ounce",
        "plural": "Platinum Ounces",
        "related_terms": ["Platinum"],
        "withdrawal_date": null,
        "slug": "xpt-platinum-ounce",
        "popularity": 75
    }
}, {
    "name": "Yemeni Rial",
    "value": {
        "currency_alpha3_code": "YER",
        "currency_no": 886,
        "minor_unit": "2",
        "description": "Yemeni Rial",
        "plural": "Yemeni Rials",
        "related_terms": ["Yemen Rial"],
        "withdrawal_date": null,
        "slug": "yer-yemeni-rial",
        "popularity": 127
    }
}, {
    "name": "South African Rand",
    "value": {
        "currency_alpha3_code": "ZAR",
        "currency_no": 710,
        "minor_unit": "2",
        "description": "South African Rand",
        "plural": "South African Rand",
        "related_terms": ["South Africa Rand", "Lesotho", "Namibia"],
        "withdrawal_date": null,
        "slug": "zar-south-african-rand",
        "popularity": 10
    }
}, {
    "name": "Zambian Kwacha",
    "value": {
        "currency_alpha3_code": "ZMK",
        "currency_no": 894,
        "minor_unit": null,
        "description": "Zambian Kwacha",
        "plural": "Zambian Kwacha",
        "related_terms": ["Zambia Kwacha"],
        "withdrawal_date": "2012-12",
        "slug": "zmk-zambian-kwacha",
        "popularity": 69
    }
}, {
    "name": "Zambian Kwacha",
    "value": {
        "currency_alpha3_code": "ZMW",
        "currency_no": 967,
        "minor_unit": "2",
        "description": "Zambian Kwacha",
        "plural": "Zambian Kwacha",
        "related_terms": ["Zambia Kwacha"],
        "withdrawal_date": null,
        "slug": "zmw-zambian-kwacha",
        "popularity": 69
    }
}, {
    "name": "Zimbabwean Dollar",
    "value": {
        "currency_alpha3_code": "ZWD",
        "currency_no": 716,
        "minor_unit": null,
        "description": "Zimbabwean Dollar",
        "plural": "Zimbabwean Dollars",
        "related_terms": ["Zimbabwe Dollar"],
        "withdrawal_date": "2006-08",
        "slug": "zwd-zimbabwean-dollar",
        "popularity": 90
    }
}, {
    "name": "Guernsey Pound",
    "value": {
        "currency_alpha3_code": "GGP",
        "currency_no": null,
        "minor_unit": "2",
        "description": "Guernsey Pound",
        "plural": "Guernsey Pounds",
        "related_terms": ["Guernsey Pound"],
        "withdrawal_date": null,
        "slug": "ggp-guernsey-pound",
        "popularity": 161
    }
}, {
    "name": "Isle of Man Pound",
    "value": {
        "currency_alpha3_code": "IMP",
        "currency_no": null,
        "minor_unit": "2",
        "description": "Isle of Man Pound",
        "plural": "Isle of Man Pounds",
        "related_terms": ["Isle of Man Pound", "Manx Pound"],
        "withdrawal_date": null,
        "slug": "imp-isle-of-man-pound",
        "popularity": 158
    }
}, {
    "name": "Jersey Pound",
    "value": {
        "currency_alpha3_code": "JEP",
        "currency_no": null,
        "minor_unit": "2",
        "description": "Jersey Pound",
        "plural": "Jersey Pounds",
        "related_terms": ["Jersey Pound"],
        "withdrawal_date": null,
        "slug": "jep-jersey-pound",
        "popularity": 159
    }
}, {
    "name": "Seborga Luigino",
    "value": {
        "currency_alpha3_code": "SPL",
        "currency_no": null,
        "minor_unit": null,
        "description": "Seborga Luigino",
        "plural": "Seborga Luiginos",
        "related_terms": ["Seborga Luigino"],
        "withdrawal_date": null,
        "slug": "spl-seborgan-luigino",
        "popularity": 155
    }
}, {
    "name": "Tuvaluan Dollar",
    "value": {
        "currency_alpha3_code": "TVD",
        "currency_no": null,
        "minor_unit": "2",
        "description": "Tuvaluan Dollar",
        "plural": "Tuvaluan Dollars",
        "related_terms": ["Tuvaluan Dollar"],
        "withdrawal_date": null,
        "slug": "tvd-tuvaluan-dollar",
        "popularity": 155
    }
}, {
    "name": "Bitcoin",
    "value": {
        "currency_alpha3_code": "XBT",
        "currency_no": null,
        "minor_unit": "8",
        "description": "Bitcoin",
        "plural": "Bitcoins",
        "related_terms": ["Bitcoin"],
        "withdrawal_date": null,
        "slug": "xbt-bitcoin",
        "popularity": 171
    }
}]

module.exports = {
    drawerWidth: drawerWidth,
    countries: countries,
    currencies: currencies
}