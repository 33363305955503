import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, registerUser, setUser } from '../actions'
import Pricing from '../components/Pricing'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
})

const mapDispatchToProps = dispatch => ({
    registerUser: (reqBody) => dispatch(registerUser(reqBody)),
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    setUser:(user) => dispatch(setUser(user)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pricing)