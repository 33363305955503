import React, {useLayoutEffect, useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import { red, grey } from '@mui/material/colors';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import { countries } from '../app/globals'

const useStyles = makeStyles(theme => ({
    error: {
        color: red[700]
    },
    registrationBox: {
        width: '350px',
        minHeight: '550px',
        margin: '0 auto'
    },
    registrationPage: {
        width: '100%',
        verticalAlign: 'middle'
    },
    container: {
        background: 'rgb(255, 255, 255, 0)',
        maxWidth: '780px',
        marginTop: '5em',
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    countryFlag: {
        maxWidth: '1.4em'
    }
}));


const Register = (props) => {
    const classes = useStyles(props)
    const history = useHistory();

    const { openDrawer, toggleDrawer, loading, registerUser, user, setUser } = props

    const [registrationBox, setRegistrationBox] = useState(true);
    const [registrationError, setRegistrationError] = useState({});
    const [logo, setLogo] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [country, setCountry] = useState("");
    const [contact, setContact] = useState("");

    const handleRegisterUser = () => {
        let errorExist = false
        registrationError && Object.keys(registrationError).map((eachErrorKey) => {
            if (registrationError[eachErrorKey]) {
                errorExist = true
            }
        })
        if (!errorExist) {
            registerUser({
                name,
                email: email.toLowerCase(),
                password,
                contact: `${country}${contact}`,
                role: 'candidate'
            })
        }
    }

    const handleChange = (field, value) => {
        let errors = {...registrationError}
        switch (field) {
            case 'name':
                setName(value)
            break;
            case 'email':
                const validEmail = value.match(/^([\w-\+\.]+@([\w-]+\.)+[\w-]{2,17})?$/)
                errors = {...errors, email: ''}
                setRegistrationError(errors)
                if (validEmail) {
                    setEmail(value)
                } else {
                    errors = {...errors, email: 'Email: Invalid email address.'}
                    setRegistrationError(errors)
                }
            break;
            case 'password':
                const validPassword = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
                const validPasswords = confirmPassword === value
                errors = {...errors, password: '', confirm_password: ''}
                setRegistrationError(errors)
                if (validPassword) {
                    setPassword(value)
                } else {
                    errors = {...errors, password: 'Password: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.'}
                    setRegistrationError(errors)
                }
                if (validPasswords) {
                    setConfirmPassword(value)
                } else {
                    errors = {...errors, confirm_password: 'Confirm Password: Passwords do not match'}
                    setRegistrationError(errors)
                }
            break;
            case 'confirm_password':
                const validConfirmPassword = password === value
                errors = {...errors, confirm_password: ''}
                setRegistrationError(errors)
                if (validConfirmPassword) {
                    setConfirmPassword(value)
                } else {
                    errors = {...errors, confirm_password: 'Confirm Password: Passwords do not match'}
                    setRegistrationError(errors)
                }
            break;
            case 'country':
                const validContacts = `${value}${contact}`.match(/^\+[1-9]\d{1,14}$/)
                errors = {...errors, contact: ''}
                setRegistrationError(errors)
                if (!validContacts) {
                    errors = {...errors, contact: 'Contact: Invalid contact number.'}
                    setRegistrationError(errors)
                }
                setCountry(value)
            break;
            case 'contact':
                const validContact = `${country}${value}`.match(/^\+[1-9]\d{1,14}$/)
                errors = {...errors, contact: ''}
                setRegistrationError(errors)
                console.log('validContact : ', validContact)
                if (validContact) {
                    setContact(value)
                } else {
                    errors = {...errors, contact: 'Contact: Invalid contact number.'}
                    setRegistrationError(errors)
                }
            break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (user && user._id) {
            history.push({
                pathname: `/login`,
            })
            setUser({})
        }
    },[user])


    return (
    <main className={classes.registrationPage}>
        <Container maxWidth="xl" className={classes.container} >
        {logo && <img className={classes.logo} alt="logo" src={logo}/>}
        <Slide direction="up" in={registrationBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h5" className={classes.text}>Registration</Typography><br/>
                <TextField
                    disabled={loading}
                    error={false}
                    onChange={(e) => { handleChange("name", e.target.value) }}
                    className={classes.root}
                    label="Name"
                    fullWidth
                    variant="outlined"
                /><br/><br/>
                <TextField
                    disabled={loading}
                    error={false}
                    onChange={(e) => { handleChange("email", e.target.value) }}
                    className={classes.root}
                    label="Email"
                    fullWidth
                    variant="outlined"
                />
                <br/><br/>
                <TextField
                    onChange={(e) => { handleChange("password", e.target.value) }}
                    disabled={loading}
                    error={false}
                    className={classes.root}
                    label="Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                />
                <br/><br/>
                <TextField
                    onChange={(e) => { handleChange("confirm_password", e.target.value) }}
                    disabled={loading}
                    error={false}
                    className={classes.root}
                    label="Confirm Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                />
                <br/><br/>
                <div style={{width: '20%', display: 'inline-block'}}>
                <TextField
                    fullWidth
                    select
                    label="Country"
                    value={country}
                    onChange={(e) => { handleChange("country", e.target.value) }}
                    variant="outlined"
                    >
                    {countries && countries.sort((a,b) => {
                        return a.dialCode - b.dialCode
                    }).map((option) => (
                        <MenuItem key={option.isoCode} value={option.dialCode}>
                            <img src={option.flag} className={classes.countryFlag}/>&nbsp; {`${option.dialCode}`}
                        </MenuItem>
                    ))}
                </TextField>
                </div>
                <div style={{width: '80%', display: 'inline-block'}}>
                    <TextField
                        onChange={(e) => { handleChange("contact", e.target.value) }}
                        disabled={loading}
                        error={false}
                        className={classes.root}
                        label="Contact"
                        fullWidth
                        type="text"
                        variant="outlined"
                    />
                </div>
                <br/><br/>
                {/* <Typography >
                    <Link to={`/forgot`} className={classes.link}>
                        Forgot your password?
                    </Link>
                </Typography><br/> */}
                {
                    registrationError && Object.keys(registrationError).map((eachErrorKey, index) => {
                        if (registrationError[eachErrorKey]) {
                            return <div key={index} className={classes.error}>{registrationError[eachErrorKey]}</div>
                        }
                        return ''
                    })
                }
                <Button 
                    disabled={loading} 
                    onClick={() => {handleRegisterUser()}}
                    className={classes.introBtn}
                >{loading ? <CircularProgress/> : 'Register'}</Button><br/><br/>
                <Typography className={classes.text}>Have an account? <Link className={classes.link} to={`/login`}>Login</Link></Typography> 
            </Box>
          </Slide>
        </Container>
    </main>
    )
}

export default Register