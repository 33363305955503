import React, { useEffect, useState, createRef } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Drawer } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MaterialBtn from '../select-components/Button';
import MaterialPaper from '../select-components/Paper';
import MaterialGrid from '../select-components/Grid';
import MaterialTypography from '../select-components/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemText } from '@mui/material';
import { Collapse } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CircularProgress from '@mui/material/CircularProgress';
import { nanoid } from '@reduxjs/toolkit';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import {fetchApi} from '../app/utils/s3Api';
// LeftDrawer is the Visual Editor's left drawer
const LeftDrawer = (props) => {

    const {
        landingPageComponents, images, getDesignAssets, createDesignAsset, updateDesignAsset,
        imagesId
    } = props

    const drawerWidth = 250
    const [imagesHover, setImagesHover] = useState(false)
    const [imagesDropdown, setImagesDropdown] = useState(false)
    const [imageLoading, setImageLoading] = useState(false);
    const [organizationId, setOrganizationId] = useState("");

    const hiddenInputRef = React.createRef();

    useEffect(() => {
        let urlArr = window.location.href.split("/")
        urlArr.pop()
        const orgID = urlArr.pop()
        setOrganizationId(orgID)
        getDesignAssets({
            'filter[key]': 'images',
            organizationId: orgID,
            count: 100,
            page: 1
        })
    },[])

    const handleUpdateImages = (reqBody) => {
        updateDesignAsset({ id: imagesId }, reqBody)
    }

    const removeImage = (imageIndex) => {
        let newImages = JSON.parse(JSON.stringify(images))
        newImages.splice(imageIndex, 1)
        let reqBody = {
            value: newImages
        }
        handleUpdateImages(reqBody)
    }

    const imageUpload = (eventFile) => {
        setImageLoading(true);
        let file = eventFile[0]
        console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setImageLoading(false);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/jpg':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setImageLoading(false);
            alert('Invalid file type')
            return;
        }
        const max_file_size = 102400 * 3
        if (file.size <= max_file_size){
            const reader = new FileReader()
            console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                console.log('content -> ', content);
                console.log('file 2 -> ', file);
                saveLogoImage(file, content)
            }
        } else {
            setImageLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 300KB');
        }
    }

    const saveLogoImage = (file, content) => {
        console.log('saveLogoImage: ', file);
        try {
            let fileNameArr = file.name.split('.')
            fetchApi("production",
                '/s3/putObjectUrl',
                'get',
                null,
                null,
                {fileType: file.type}
                ).then((result) => {
                    console.log('presigned result => ', result);
                    const { data } = result
                    if (!data.signedRequest) return
                    const xhr = new XMLHttpRequest()
                    let url = data.signedRequest
                    
                    xhr.open(data.method, url)
                    // temp commented out
                    // xhr.setRequestHeader('x-amz-acl', data.XamzACL)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200){
                                console.log('success!');
                                let currentImages = images || []
                                currentImages = currentImages.concat([data.cdnUrl]);
                                let reqBody = {
                                    value: currentImages
                                }
                                if (imagesId) {
                                    console.log('FIRED updateDesignAsset');
                                    handleUpdateImages(reqBody)
                                } else {
                                    if (!organizationId) return
                                    reqBody.key = "images"
                                    reqBody.organizationId = organizationId
                                    createDesignAsset(reqBody)
                                }
                                // Create Image List
                                setImageLoading(false);
                            }
                        }
                        console.log('xhr : ', xhr);
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setImageLoading(false);
            console.log('error : ', err);
        }
    }

    const fallbackCopyTextToClipboard = (text) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
      }

      const copyTextToClipboard = (text) => {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }

    return (
            <Drawer
                anchor={'left'}
                open={true}
                variant={'permanent'}
            >
                <Box
                    sx={{ width: drawerWidth }}
                    role="presentation"
                >
                    <Toolbar>Draggable Components</Toolbar>
                    <Divider />
                    <List
                        style={{padding: 0}}
                    >
                        <ListItem>
                            <MaterialBtn _id={nanoid()} />
                        </ListItem>
                        <ListItem>
                            <MaterialGrid _id={nanoid()} container={true} item={false}/>
                        </ListItem>
                        <ListItem>
                            <MaterialGrid _id={nanoid()} container={false} item={true}/>
                        </ListItem>
                        <ListItem>
                            <MaterialPaper _id={nanoid()}/>
                        </ListItem>
                        <ListItem>
                            <MaterialTypography _id={nanoid()}/>
                        </ListItem>
                    </List>
                    <Divider />
                    <List 
                        style={{padding: 1}}
                    >
                        <ListItem button onMouseEnter={() => {setImagesHover(true)}} onMouseLeave={() => {setImagesHover(false)}} onClick={() => {setImagesDropdown(!imagesDropdown)}}>
                        <ListItemText ><Typography>Images</Typography></ListItemText>
                        {imagesHover && (imagesDropdown ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                        </ListItem>
                        <Collapse in={imagesDropdown} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{maxHeight: 500, overflow: 'scroll'}}>
                                {/* Images map */}
                                {images && images.map((eachImage, index) => {
                                    return <div>
                                        <ListItem 
                                            style={{
                                                backgroundImage: `url(${eachImage})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                minHeight: '150px',
                                                width: '100%',
                                                margin: '5px'
                                            }}
                                            onClick
                                        >
                                        </ListItem>
                                        <ListItem>
                                            <IconButton 
                                                onClick={() => {
                                                    copyTextToClipboard(eachImage)
                                                }}
                                            ><ContentCopyIcon/></IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    removeImage(index)
                                                }}
                                            ><DeleteOutlineIcon/></IconButton>
                                        </ListItem>
                                    </div>
                                })}
                            </List><br/>
                            <div>
                                <input ref={hiddenInputRef} accept="image/*" style={{display: 'none'}} onChange={(e) => {imageUpload(e.target.files)}} id="image-button-file" type="file" />
                                <label htmlFor="image-button-file">
                                    <Button 
                                        fullWidth
                                        startIcon={imageLoading ? <CircularProgress size={20}/> : <PhotoCamera />}
                                        variant='contained'
                                        onClick={() => {
                                            if (imageLoading) return
                                            hiddenInputRef.current.click()
                                        }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                            </div><br/>
                        </Collapse>
                    </List>
                </Box>
            </Drawer>
    );
}

export default LeftDrawer