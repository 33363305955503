import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom"
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import DevicesIcon from '@material-ui/icons/Devices';
import PaymentIcon from '@material-ui/icons/Payment';
import CodeIcon from '@material-ui/icons/Code';
// import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AppsIcon from '@material-ui/icons/Apps';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import { drawerWidth } from '../app/globals';

const useStyles = makeStyles((theme => ({
      link:{
        color: '#156573',
        textDecoration: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
})));

const AppDrawer = (props) => {
    const classes = useStyles(props)
    const theme = useTheme();
    const { openDrawer, toggleDrawer } = props
    return (<Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
    >
        <div className={classes.drawerHeader}>
            <IconButton onClick={() => toggleDrawer()}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </div>
        <Divider />
        <List>
          {['Products', 'Developers', 'Company', 'Pricing', 'Support', 'Console'].map((text, index) => {
            if (text !== 'Console' && text !== 'Support' && text !== 'Developers') {
              return (<Link key={index} className={classes.link} to={`/${text.toLowerCase()}`} onClick={() => toggleDrawer()}>
              <ListItem button key={text}>
                {text === 'Products' && <ListItemIcon><PaymentIcon htmlColor={"#156573"}/></ListItemIcon>}
                {text === 'Company' && <ListItemIcon><PeopleIcon htmlColor={"#156573"}/></ListItemIcon>}
                {text === 'Pricing' && <ListItemIcon><LocalOfferIcon htmlColor={"#156573"}/></ListItemIcon>}
                {/* {text === 'Console' && <a className={classes.link} href={`${process.env.REACT_APP_CONSOLE_MANAGEMENT_URL}`}><ListItemIcon><AppsIcon htmlColor={"#156573"}/></ListItemIcon></a>} */}
                <ListItemText primary={text} />
              </ListItem>
            </Link>)
            } else if (text === 'Console') {
              return (<a key={index} className={classes.link} href={text === 'Console' ? "https://console.liberalize.io": ""} onClick={() => toggleDrawer()}>
              <ListItem button key={text} >
                {/* Developer should redirect out to our docs */}
                {/* {text === 'Developers' && <ListItemIcon><CodeIcon htmlColor={"#156573"}/></ListItemIcon>} */}
                {/* Console should redirect out to our console management */}
                {text === 'Console' && <ListItemIcon><AppsIcon htmlColor={"#156573"}/></ListItemIcon>}
                {/* {text === 'Console' && <a className={classes.link} href={`${process.env.REACT_APP_CONSOLE_MANAGEMENT_URL}`}><ListItemIcon><AppsIcon htmlColor={"#156573"}/></ListItemIcon></a>} */}
                <ListItemText primary={text} />
              </ListItem>
            </a>)
            } else if (text === 'Developers') {
              return (<ListItem button key={text} className={classes.link}
                onClick={() => {
                  if (text === "Developers") {
                    window.open("https://docs.liberalize.io")
                    toggleDrawer()
                  }
                }}
              >
                {/* Developer should redirect out to our docs */}
                {/* {text === 'Developers' && <ListItemIcon><CodeIcon htmlColor={"#156573"}/></ListItemIcon>} */}
                {/* Console should redirect out to our console management */}
                {text === 'Developers' && <ListItemIcon><CodeIcon htmlColor={"#156573"}/></ListItemIcon>}
                {/* {text === 'Console' && <a className={classes.link} href={`${process.env.REACT_APP_CONSOLE_MANAGEMENT_URL}`}><ListItemIcon><AppsIcon htmlColor={"#156573"}/></ListItemIcon></a>} */}
                <ListItemText primary={text} />
              </ListItem>)
            } else {
              return (
                <ListItem button key={text} className={classes.link} onClick={(event)=> {
                  event.preventDefault()
                  window.open('mailto:support@liberalize.io?subject=RE:Support')
                  toggleDrawer()
                }}>
                {/* Developer should redirect out to our docs */}
                {text === 'Support' && <ListItemIcon><HelpOutlineIcon htmlColor={"#156573"}/></ListItemIcon>}
                <ListItemText primary={text} />
              </ListItem>
              )
            }
        })}
        </List>
      </Drawer>)
}

export default AppDrawer
