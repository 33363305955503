import { connect } from 'react-redux'

import { setRoot, addComponent, updateComponent, selectComponent, setComponentsTree, getDesignAssets } from '../actions'
import UserApp from '../components/UserApp'

const mapStateToProps = state => ({
    components: state.components.components,
    selectedComponent: state.components.selectedComponent,
    preview: state.app.preview,
    landingPageComponents: state.designAssets.landingPageComponents,
})
  
const mapDispatchToProps = dispatch => ({
    setRoot: (data) => dispatch(setRoot(data)),
    setComponentsTree: (reqBody) => dispatch(setComponentsTree(reqBody)),
    addComponent: (data) => dispatch(addComponent(data)),
    updateComponent: (data) => dispatch(updateComponent(data)),
    selectComponent: (data) => dispatch(selectComponent(data)),
    getDesignAssets: (query) => dispatch(getDesignAssets(query)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserApp)