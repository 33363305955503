import { CALL_API } from '../app/middleware/mainSsoApi';

export const setUser = (user) => ({
    type: 'SET_USER',
    data: { user: user }
})

export const login = (reqBody) => ({
    types: ['POST_LOGIN_REQUEST', 'POST_LOGIN_SUCCESS', 'POST_LOGIN_FAILURE'],
    [CALL_API]: '/mainAuth/local',
    method: 'post',
    reqBody: reqBody,
})

export const forgetPassword = (reqBody) => ({
    types: ['POST_PASSWORD_RESET_REQUEST', 'POST_PASSWORD_RESET_SUCCESS', 'POST_PASSWORD_RESET_FAILURE'],
    [CALL_API]: '/mainUsers/forgetPassword',
    method: 'post',
    reqBody: reqBody,
})

export const resetPassword = (reqBody) => ({
    types: ['POST_RESET_PASSWORD_REQUEST', 'POST_RESET_PASSWORD_SUCCESS', 'POST_RESET_PASSWORD_FAILURE'],
    [CALL_API]: '/mainUsers/resetPassword',
    method: 'put',
    reqBody: reqBody,
})


export const createUser = (reqBody) => ({
    types: ['POST_CREATE_USER_REQUEST', 'POST_CREATE_USER_SUCCESS', 'POST_CREATE_USER_FAILURE'],
    [CALL_API]: '/mainUsers/',
    method: 'post',
    reqBody: reqBody,
})

export const registerUser = (reqBody) => ({
    types: ['POST_REGISTER_USER_REQUEST', 'POST_REGISTER_USER_SUCCESS', 'POST_REGISTER_USER_FAILURE'],
    [CALL_API]: '/mainUsers',
    method: 'post',
    reqBody: reqBody,
})

export const resetRegistrationSuccess = () => ({
    type: 'RESET_REGISTER_USER_SUCCESS',
})

export const getMe = () => ({
    types: ['GET_ME_REQUEST', 'GET_ME_SUCCESS', 'GET_ME_FAILURE'],
    [CALL_API]: '/me',
    method: 'get',
})

export const getUsers = (query) => ({
    types: ['GET_USERS_REQUEST', 'GET_USERS_SUCCESS', 'GET_USERS_FAILURE'],
    [CALL_API]: '/mainUsers',
    method: 'get',
    query: query
})

export const getNextUsers = (query) => ({
    types: ['GET_NEXT_USERS_REQUEST', 'GET_NEXT_USERS_SUCCESS', 'GET_NEXT_USERS_FAILURE'],
    [CALL_API]: '/mainUsers',
    method: 'get',
    query: query
})

export const updateUser = (params, reqBody) => ({
    types: ['PUT_UPDATE_USER_REQUEST', 'PUT_UPDATE_USER_SUCCESS', 'PUT_UPDATE_USER_FAILURE'],
    [CALL_API]: '/mainUsers/:id',
    method: 'put',
    params: params,
    reqBody: reqBody,
})


// module.exports = {
//     setUser: setUser,
// }