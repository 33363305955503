import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, resetPassword } from '../actions'
import ResetPassword from '../components/ResetPassword'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
    resetSuccess: state.users.resetSuccess,
    configurations: state.miscs && state.miscs.configurations ? state.miscs.configurations : {}
})

const mapDispatchToProps = dispatch => ({
    resetPassword: (reqBody) => dispatch(resetPassword(reqBody)),
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword)