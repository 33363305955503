import React, {useLayoutEffect, useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import { red, grey } from '@mui/material/colors';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import { countries } from '../app/globals'

const useStyles = makeStyles(theme => ({
    error: {
        color: red[700]
    },
    resetBox: {
        width: '350px',
        minHeight: '550px',
        margin: '0 auto'
    },
    registrationPage: {
        width: '100%',
        verticalAlign: 'middle'
    },
    container: {
        background: 'rgb(255, 255, 255, 0)',
        maxWidth: '780px',
        marginTop: '5em',
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    countryFlag: {
        maxWidth: '1.4em'
    }
}));


const ResetPassword = (props) => {
    const classes = useStyles(props)
    const history = useHistory();

    const { openDrawer, toggleDrawer, loading, configurations, resetPassword, user, resetSuccess } = props

    const [resetBox, setResetBox] = useState(true);
    const [completeBox, setCompleteBox] = useState(false);
    const [logo, setLogo] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [resetError, setResetError] = useState({});

    const handleResetPassword = () => {
        let errorExist = false
        resetError && Object.keys(resetError).map((eachErrorKey) => {
            if (resetError[eachErrorKey]) {
                errorExist = true
            }
        })
        if (!errorExist) {
            let token = ""
            // get token from url's query params
            const urlArr = window.location.href.split('?')
            const queryParams = urlArr[1].split('&')
            queryParams.forEach((query) => {
                const queryArr = query.split('=')
                if (queryArr[0] === 'token') token = queryArr[1]
            })
            
            resetPassword({
                newPassword: password,
                token: token
            })
        }
    }

    const handleRedirectLogin = () => {
        history.push({
            pathname: `/login`,
        })
    }

    const handleChange = (field, value) => {
        let errors = {...resetError}
        switch (field) {
            case 'password':
                const validPassword = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
                const validPasswords = confirmPassword === value
                errors = {...errors, password: '', confirm_password: ''}
                setResetError(errors)
                if (validPassword) {
                    setPassword(value)
                } else {
                    errors = {...errors, password: 'Password: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.'}
                    setResetError(errors)
                }
                if (validPasswords) {
                    setConfirmPassword(value)
                } else {
                    errors = {...errors, confirm_password: 'Confirm Password: Passwords do not match'}
                    setResetError(errors)
                }
            break;
            case 'confirm_password':
                const validConfirmPassword = password === value
                errors = {...errors, confirm_password: ''}
                setResetError(errors)
                if (validConfirmPassword) {
                    setConfirmPassword(value)
                } else {
                    errors = {...errors, confirm_password: 'Confirm Password: Passwords do not match'}
                    setResetError(errors)
                }
            break;
            default:
                break;
        }
    }

    useEffect(() => {
        configurations && configurations.length > 0 && configurations.map((eachConfig) => {
            switch (eachConfig.name) {
                case "organization_logo":
                    setLogo(eachConfig.value)
                    break;
                case "organization_name":
                    setOrganizationName(eachConfig.value)
                    break;
                default:
                    break;
            }
        })
    },[configurations])

    useEffect(() => {
        if (resetSuccess) {
            setResetBox(false)
            setCompleteBox(true)
        }
    },[resetSuccess])

    return (
    <main className={classes.registrationPage}>
        <Container maxWidth="xl" className={classes.container} >
        {logo && <img className={classes.logo} alt="logo" src={logo}/>}
        <Slide direction="up" in={resetBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h5" className={classes.text}>Reset Password</Typography><br/>
                <TextField
                    onChange={(e) => { handleChange("password", e.target.value) }}
                    disabled={loading}
                    error={false}
                    className={classes.root}
                    label="Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                />
                <br/><br/>
                <TextField
                    onChange={(e) => { handleChange("confirm_password", e.target.value) }}
                    disabled={loading}
                    error={false}
                    className={classes.root}
                    label="Confirm Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                />
                <br/><br/>
                {
                    resetError && Object.keys(resetError).map((eachErrorKey, index) => {
                        if (resetError[eachErrorKey]) {
                            return <div key={index} className={classes.error}>{resetError[eachErrorKey]}</div>
                        }
                        return ''
                    })
                }
                <Button 
                    disabled={loading} 
                    onClick={() => {handleResetPassword()}}
                    className={classes.introBtn}
                >{loading ? <CircularProgress/> : 'Change Password'}</Button><br/><br/>
            </Box>
          </Slide>
          <Slide direction="up" in={completeBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h5" className={classes.text}>Reset Password Complete</Typography><br/>
                <Button 
                    disabled={loading} 
                    onClick={() => { handleRedirectLogin() }}
                    className={classes.introBtn}
                >{loading ? <CircularProgress/> : 'Proceed To Login'}</Button><br/><br/>
            </Box>
          </Slide>
        </Container>
    </main>
    )
}

export default ResetPassword