import { fetchApi } from '../utils/mainSsoApi';

export const CALL_API = Symbol('callApi key');

// Action key that carries API call info interpreted by this Redux middleware.
const callApi = store => next => async (action) => {
    console.log('action IN callApi MIDDLEWARE is', action);
    // console.log("next IN callApi MIDDLEWARE => ", next);
    // console.log("store IN callApi MIDDLEWARE => ", store);
    const apiRoutes = action[CALL_API];

    // console.log('apiRoutes => ', apiRoutes);
    // If it's not a callApi's action skip to next action.
    if (typeof apiRoutes === 'undefined') {
        return next(action);
    }
    const state = store.getState()
    const env = 'production'
    const { method, types, params, reqBody, query } = action;
    // console.log(' WHAT TYPES: ', types);
    // console.log('params => ', params);
    // console.log('reqBody => ', reqBody);
    next({ type: types[0] });
    return fetchApi(env, apiRoutes, method, params, reqBody, query).then(
        response => next({
        data: response.data.data,
        type: types[1],
        }), error => next({
        error: error || 'Something bad happened',
        type: types[2]
        })
    )
}

export default callApi