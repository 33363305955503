import React, {useLayoutEffect, useEffect, useState} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import { red, grey } from '@mui/material/colors';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import { countries } from '../app/globals'
import Organization from './Organization'
import Grid from '@material-ui/core/Grid'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';

import {fetchApi} from '../app/utils/s3Api';

const useStyles = makeStyles(theme => ({
    organizationsContainer: {
        overflowY: 'scroll',
        maxHeight: '700px'
    },
    gridContainer: {
        margin: '1.1em'
    },
    error: {
        color: red[700]
    },
    organizationsBox: {
    },
    eachOrg: {
        margin: '1em',
        maxWidth: '500px',
        width: '500px',
        padding: '0.7em',
    },
    orgId: {
        color: grey[500],
    },
    registrationPage: {
        width: '100%',
        verticalAlign: 'middle'
    },
    container: {
        background: 'rgb(255, 255, 255, 0)',
        maxWidth: '780px',
        marginTop: '5em',
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    countryFlag: {
        maxWidth: '1.4em'
    },
    hiddeninput: {
        display: 'none',
    },
}));

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));
  
const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Organizations = (props) => {
    const classes = useStyles(props)
    const history = useHistory();

    const { openDrawer, toggleDrawer, loading, getOrganizations, organizations, 
        getNextOrganizations, totalCount, organization, createOrganization, 
        getOrganization, updateOrganization, setOrganization, createdOrgAdminEmail, createdOrgAdminPass,
        updateOrganizationTemplate, createCustomDomainLink, getCustomDomainLink, deleteCustomDomainLink,
        checkNameSpace, resetCheckNameSpace, nameSpaceAvailable
    } = props

    const hiddenInputRef = React.createRef();

    const faviconHiddenInputRef = React.createRef();

    const [organizationsBox, setOrganizationsBox] = useState(true);
    const [organizationsDetails, setOrganizationsDetails] = useState(true);
    const [logo, setLogo] = useState("");
    const [page, setPage] = useState(1);
    const [targetOrganization, setTargetOrganization] = useState({});
    const [creatingOrg, setCreatingOrg] = useState(false);
    const [newOrgName, setNewOrgName] = useState("");
    const [newOrgWebName, setNewOrgWebName] = useState("");
    const [newOrgLogo, setNewOrgLogo] = useState("");
    const [newOrgFavicon, setNewOrgFavicon] = useState("");
    const [newOrgTemplate, setNewOrgTemplate] = useState("");
    const [imageHover, setImageHover] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [faviconHover, setFaviconHover] = useState(false);
    const [faviconLoading, setFaviconLoading] = useState(false);
    const [allTimers, setAllTimers] = useState([]);
    const [showCreateOrgCustomDomain, setShowCreateOrgCustomDomain] = useState(false);
    const [showDeleteOrgCustomDomain, setShowDeleteOrgCustomDomain] = useState(false);
    const [cxDomain, setCxDomain] = useState("");
    
    useEffect(() => {
        getOrganizations({
            count: 5, // change back to 5
            page: page
        })
    },[])

    useEffect(() => {
        // if there's an existing target Organization update it
        if (targetOrganization && targetOrganization._id) {
            organizations.map((eachOrganization) => {
                if (eachOrganization._id === targetOrganization._id) {
                    setTargetOrganization(eachOrganization)
                }
            })
        }
    },[organizations])



    useEffect(() => {
        if (organization && organization._id) {
            if (targetOrganization && targetOrganization._id !== organization._id) {
                setTargetOrganization(organization)
            }
            if (targetOrganization && targetOrganization.domain_state !== organization.domain_state) {
                setTargetOrganization(organization)
            }
            console.log('DIFFERENCE FOUND : ', organization);
            setTargetOrganization(organization)
        }
    },[organization, ((organization && organization.cx_domain_cert && organization.cx_domain_cert.certificateArn) || ""), ((organization && organization.cx_domain) || "")])

    const handleNextOrganizations = () => {
        getNextOrganizations({
            count: 5,
            page: page+1
        })
        setPage(page+1)
    }

    const getTemplateVersion = (template) => {
        let version = ""
        switch (template) {
            case 'template_1':
                version = "0.2.2"
                break;
            default:
                break;
        }
        return version
    }

    const handleCreateOrganization = () => {
        // check if availability was checked
        if(!nameSpaceAvailable) {
            window.alert("Please check availability before proceeding")
            return
        }
        if (!newOrgName) {
            window.alert("Organization name required.")
            return
        }
        if (!newOrgTemplate) {
            window.alert("Website template required.")
            return
        }
        createOrganization({
            name: newOrgWebName,
            web_namespace: newOrgWebName,
            organization_logo: newOrgLogo,
            organization_favicon: newOrgFavicon,
            isSubscribed: "Y",
            isActive: "Y",
            domain_template: newOrgTemplate,
            domain_template_version: getTemplateVersion(newOrgTemplate)
        })
        setCreatingOrg(false)
        setNewOrgName("")
        setNewOrgWebName("")
        setNewOrgLogo("")
        setNewOrgTemplate("")
        setNewOrgFavicon("")
    }

    const imageUpload = (eventFile) => {
        setImageLoading(true);
        let file = eventFile[0]
        console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setImageLoading(false);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/jpg':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setImageLoading(false);
            alert('Invalid file type')
            return;
        }
        const max_file_size = 102400 * 3
        if (file.size <= max_file_size){
            const reader = new FileReader()
            console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                console.log('content -> ', content);
                console.log('file 2 -> ', file);
                saveLogoImage(file, content)
            }
        } else {
            setImageLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 300KB');
        }
    }

    const saveLogoImage = (file, content) => {
        console.log('saveLogoImage: ', file);
        try {
            let fileNameArr = file.name.split('.')
            fetchApi("production",
                '/s3/putObjectUrl',
                'get',
                null,
                null,
                {fileType: file.type}
                ).then((result) => {
                    console.log('presigned result => ', result);
                    const { data } = result
                    if (!data.signedRequest) return
                    const xhr = new XMLHttpRequest()
                    let url = data.signedRequest
                    
                    xhr.open(data.method, url)
                    // temp commented out
                    // xhr.setRequestHeader('x-amz-acl', data.XamzACL)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            console.log('success!');
                            setNewOrgLogo(data.cdnUrl)
                            setImageLoading(false);
                        }
                        console.log('xhr : ', xhr);
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setImageLoading(false);
            console.log('error : ', err);
        }
    }

    const faviconUpload = (eventFile) => {
        setFaviconLoading(true);
        let file = eventFile[0]
        console.log('file -> ', file);
        console.log('file.type2 -> ', file.type);
        let matchedFileType = false;
        if (!file) {
            setImageLoading(false);
            return;
        }
        console.log('file.type2 -> ', file.type);
        switch (file.type) {
            case 'image/x-icon':
            case 'image/vnd.microsoft.icon':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setFaviconLoading(false);
            alert('Invalid file type')
            return;
        }
        const max_file_size = 102400 * 3
        if (file.size <= max_file_size){
            const reader = new FileReader()
            console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                console.log('content -> ', content);
                console.log('file 2 -> ', file);
                saveFaviconImage(file, content)
            }
        } else {
            setFaviconLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 300KB');
        }
    }

    const saveFaviconImage = (file, content) => {
        console.log('saveLogoImage: ', file);
        try {
            let fileNameArr = file.name.split('.')
            fetchApi("production",
                '/s3/putObjectUrl',
                'get',
                null,
                null,
                {fileType: file.type}
                ).then((result) => {
                    console.log('presigned result => ', result);
                    const { data } = result
                    if (!data.signedRequest) return
                    const xhr = new XMLHttpRequest()
                    let url = data.signedRequest
                    
                    xhr.open(data.method, url)
                    // temp commented out
                    // xhr.setRequestHeader('x-amz-acl', data.XamzACL)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            console.log('success!');
                            setNewOrgFavicon(data.cdnUrl)
                            setFaviconLoading(false);
                        }
                        console.log('xhr : ', xhr);
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setFaviconLoading(false);
            console.log('error : ', err);
        }
    }

    const pollOrganization = () => {
        if (targetOrganization && targetOrganization.domain_state === "processing" && (targetOrganization && targetOrganization._id)) {
            getOrganization({
                id: targetOrganization._id
            })
            // set time out to poll this organization
            const timer = setTimeout(() => {
                pollOrganization()
            }, 10000);
            setAllTimers([timer])
        }
    }

    // POLLING
    useEffect(() => {
        // CLEAR ALL PREVIOUS TIMERS
        allTimers.map((eachTimer)=> {
            window.clearTimeout(eachTimer);
        })
        pollOrganization()
    },[(targetOrganization && targetOrganization._id)])

    const cxDomainSteps = ['Enter domain', 'Verify ownership', 'Go live'];

    const [activeCxDomainStep, setActiveCxDomainStep] = React.useState(0);
    const [allDomainVerificationTimers, setDomainVerificationTimers] = useState([]);

    const handleShowCreateOrgCustomDomain = () => {
        setActiveCxDomainStep(0)
        setShowCreateOrgCustomDomain(true)
        // If there's no custom domain set step 1
        console.log('targetOrganization: ', targetOrganization);
        if (targetOrganization && (!targetOrganization.cx_domain)) {
            setActiveCxDomainStep(0)
        } else if (targetOrganization && targetOrganization.cx_domain && targetOrganization.cx_domain_cert) {
            // if there's custom domain check cert
            let allVerified = true
            if (targetOrganization.cx_domain_cert.domainValidationOptions) {
                targetOrganization.cx_domain_cert.domainValidationOptions.map((eachDomain) => {
                    if (eachDomain.ValidationStatus !== "SUCCESS") {
                        allVerified = false
                    }
                })
            } else if (!targetOrganization.cx_domain_cert.domainValidationOptions) {
                allVerified = false
            }
            if (!allVerified) {
                setActiveCxDomainStep(1)
            } else {
                setActiveCxDomainStep(2)
            }
        }
    }

    // POLL DOMAIN VERIFICATION
    const pollOrgCustomDomain = () => {
        console.log("activeCxDomainStep : ", activeCxDomainStep);
        if (targetOrganization && activeCxDomainStep === 1) {
            getCustomDomainLink({
                id: targetOrganization._id
            })
            // set time out to poll this organization
            const timer = setTimeout(() => {
                pollOrgCustomDomain()
            }, 10000);
            setDomainVerificationTimers([timer])
        }
        if (targetOrganization && targetOrganization.cx_domain && targetOrganization.cx_domain_cert) {
            // if there's custom domain check cert
            let allVerified = true
            if (targetOrganization.cx_domain_cert.domainValidationOptions) {
                targetOrganization.cx_domain_cert.domainValidationOptions.map((eachDomain) => {
                    if (eachDomain.ValidationStatus !== "SUCCESS") {
                        allVerified = false
                    }
                })
            }
            if (allVerified) {
                setActiveCxDomainStep(2)
            }
        }
    }

    useEffect(() => {
        // CLEAR ALL PREVIOUS TIMERS
        allDomainVerificationTimers.map((eachTimer)=> {
            window.clearTimeout(eachTimer);
        })
        pollOrgCustomDomain()
    },[(activeCxDomainStep)])

    const [copiedText, setCopiedText] = useState("");
    const copyText = (textToCopy) => {
        let textInput = document.createElement("input");
        let wrapper = document.getElementById("copyTextInput")
        // console.log('textInput - > ', textInput);
        // Place in top-left corner of screen regardless of scroll position.
        textInput.style.position = 'fixed';
        textInput.style.top = 0;
        textInput.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textInput.style.width = '2em';
        textInput.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textInput.style.padding = 0;

        // Clean up any borders.
        textInput.style.border = 'none';
        textInput.style.outline = 'none';
        textInput.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textInput.style.background = 'transparent';

        textInput.value = textToCopy
         
        wrapper.appendChild(textInput);
        textInput.focus();
        textInput.select();
        textInput.setSelectionRange(0, 99999);

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            // console.log('Copying text command was ' + msg);
        } catch (err) {
            // console.log('Oops, unable to copy');
        }
        // console.log('textInput - > ', textInput);
        // console.log('textInput.value - > ', textInput.value);
        setCopiedText(textToCopy)
        setTimeout(() => {
            setCopiedText('')
        }, 3500);
        wrapper.removeChild(textInput);
    }

    const handleShowDeleteOrgCustomDomain = () => {
        setShowDeleteOrgCustomDomain(true)
    }

    return (
        <main className={classes.registrationPage}>
            <Container maxWidth="xl" className={classes.container} >
            {logo && <img className={classes.logo} alt="logo" src={logo}/>}
            <Slide direction="up" in={organizationsBox} mountOnEnter unmountOnExit>
                <Box m={2} variant="middle" className={classes.box} >
                    <Typography variant="h5" className={classes.text}>Organisations</Typography><br/>
                    <Button 
                        variant='outlined'
                        onClick={(e) => {
                            setCreatingOrg(true)
                            setOrganizationsDetails(false)
                            setTargetOrganization({})
                            setTimeout(() => {
                                setOrganizationsDetails(true)
                            }, 200);
                        }}
                    >{'Create Organisation'}</Button><br/><br/>
                </Box>
            </Slide>
            <Grid container className={classes.gridContainer}>
                <Slide direction="up" in={organizationsBox} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={12} md={6}>
                        {organizations && organizations.length > 0 && <Grid container className={classes.organizationsContainer}>
                            {organizations && organizations.map((eachOrg) => {
                                return <Button
                                    fullWidth 
                                    color={'inherit'} 
                                    variant='outlined'
                                    className={classes.eachOrg}
                                    key={eachOrg._id}
                                    sx={{
                                        margin: '1em'
                                    }}
                                    onClick={(e) => {
                                        setOrganizationsDetails(false)
                                        setTargetOrganization(eachOrg)
                                        setOrganization(eachOrg)
                                        setCreatingOrg(false)
                                        setTimeout(() => {
                                            setOrganizationsDetails(true)
                                        }, 200);
                                    }}
                                >
                                    <Grid 
                                        item xs={12} sm={12} md={12} 
                                    >
                                        <Typography variant="h7" className={classes.text}>{eachOrg.name}</Typography>
                                        <Typography className={classes.orgId}>{eachOrg._id}</Typography>
                                    </Grid>
                                </Button>
                            })}
                        </Grid>}
                        <Grid container className={classes.organizationsContainer}>
                            {(organizations && (totalCount > organizations.length)) && <Button
                                fullWidth 
                                color={'inherit'} 
                                variant='outlined'
                                className={classes.eachOrg}
                                key={'load more'}
                                sx={{
                                    margin: '1em'
                                }}
                                onClick={(e) => {
                                    handleNextOrganizations()
                                }}
                                disabled={loading || (organizations && organizations.length && (totalCount <= organizations.length))}
                            >
                                <Grid 
                                    item xs={12} sm={12} md={12} 
                                >
                                    <Typography variant="h7" className={classes.text}>
                                        { (organizations && organizations.length && (totalCount > organizations.length)) ? 'LOAD MORE' : 'NO MORE'}
                                    </Typography>
                                </Grid>
                            </Button>}
                        </Grid>
                    </Grid>
                </Slide>
                <Slide direction="up" in={organizationsDetails} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={12} md={6}>
                    {
                            creatingOrg && <Grid container>
                                <Typography variant="h5" className={classes.text}>{'Create Organisation'}</Typography><br/><br/>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        onChange={(e) => { setNewOrgName(e.target.value) }}
                                        disabled={loading}
                                        error={false}
                                        className={classes.root}
                                        label="Organisation Name"
                                        fullWidth
                                        variant="outlined"
                                    />
                                    <br/><br/>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Typography variant="h6" className={classes.frontendUrl}>{'HTTPS://'}</Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <TextField
                                                onChange={(e) => {
                                                    if (nameSpaceAvailable) {
                                                        // if state is currently available reset it
                                                        resetCheckNameSpace()
                                                    }
                                                    setNewOrgWebName(e.target.value) 
                                                }}
                                                required
                                                disabled={loading}
                                                error={false}
                                                className={classes.root}
                                                label="Web Name Space"
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="h6" className={classes.frontendUrl}>.{process.env.REACT_APP_EDUTECH_FRONTEND_URL}</Typography>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8}>
                                                {}
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Button
                                                fullWidth
                                                color={nameSpaceAvailable ? 'success': "primary"}
                                                variant="contained"
                                                sx={{
                                                    height: '100%'
                                                }}
                                                onClick={() => {
                                                    if (!newOrgWebName) {
                                                        window.alert("Invalid Web Name Space")
                                                        return
                                                    }
                                                    const validNameSpace = newOrgWebName.match(/^([a-z-\d]+)?$/)
                                                    if (!validNameSpace) {
                                                        window.alert("Invalid Web Name Space: Only small caps alphanumeric characters and '-' symbol allowed")
                                                        return
                                                    }
                                                    checkNameSpace({
                                                        web_namespace: newOrgWebName
                                                    })
                                                }}
                                            >
                                                {nameSpaceAvailable ? "AVAILABLE" : "CHECK AVAILABILITY"}
                                            </Button>
                                        </Grid>
                                    </Grid><br/>
                                    {/* LOGO */}
                                    <Typography variant="h6" className={classes.text}>Organisation Logo: </Typography><br/>
                                    <input ref={hiddenInputRef} accept="image/*" className={classes.hiddeninput} onChange={(e) => {imageUpload(e.target.files)}} id="icon-button-file" type="file" />
                                    <label>
                                        <Typography variant="h7" className={classes.text}>Recommended Dimension: 280 x 128 </Typography><br/>
                                        <ImageButton
                                            focusRipple
                                            sx={{
                                                width: '40%'
                                            }}
                                            onMouseEnter={() => {
                                                setImageHover(true)
                                            }}
                                            onMouseLeave={() => {
                                                setImageHover(false)
                                            }}
                                            onClick={() => {
                                                if (imageLoading) return
                                                hiddenInputRef.current.click()
                                            }}
                                        >
                                            <ImageSrc style={{ backgroundImage: `url(${(newOrgLogo) || ""})` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography
                                                    component="span"
                                                    variant="subtitle1"
                                                    color="inherit"
                                                    sx={{
                                                        position: 'relative',
                                                        p: 4,
                                                        pt: 2,
                                                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                                    }}
                                                >
                                                    { imageHover && 'UPLOAD IMAGE'}
                                                    {imageHover && <ImageMarked className="MuiImageMarked-root" />}
                                                </Typography>
                                            </Image>
                                        </ImageButton>
                                    </label>
                                    <br/><br/>
                                    {/* FAVICON */}
                                    <Typography variant="h6" className={classes.text}>Organisation Favicon: </Typography><br/>
                                    <input ref={faviconHiddenInputRef} accept="image/x-icon" className={classes.hiddeninput} onChange={(e) => {faviconUpload(e.target.files)}} id="favicon-button-file" type="file" />
                                    <label>
                                        <Typography variant="h7" className={classes.text}>A favicon is a small icon or collection of icons associated with a website, web page, or web application. It’s displayed within the browser tabs and bookmarks bar.
                                        Check <a target="_blank" href="https://favicon.io/">https://favicon.io/</a> to understand more and generate your own favicon. It should be a ".ico" file extension.
                                        </Typography><br/>
                                        <ImageButton
                                            focusRipple
                                            sx={{
                                                width: '40%'
                                            }}
                                            onMouseEnter={() => {
                                                setFaviconHover(true)
                                            }}
                                            onMouseLeave={() => {
                                                setFaviconHover(false)
                                            }}
                                            onClick={() => {
                                                if (faviconLoading) return
                                                faviconHiddenInputRef.current.click()
                                            }}
                                        >
                                            <ImageSrc style={{ backgroundImage: `url(${(newOrgFavicon) || ""})` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography
                                                    component="span"
                                                    variant="subtitle1"
                                                    color="inherit"
                                                    sx={{
                                                        position: 'relative',
                                                        p: 4,
                                                        pt: 2,
                                                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                                    }}
                                                >
                                                    { faviconHover && 'UPLOAD FAVICON'}
                                                    {faviconHover && <ImageMarked className="MuiImageMarked-root" />}
                                                </Typography>
                                            </Image>
                                        </ImageButton>
                                    </label>
                                    <br/><br/>
                                    <FormControl fullWidth required>
                                        <InputLabel id="template">Template</InputLabel>
                                        <Select
                                            labelId="template"
                                            id="templateSelect"
                                            fullWidth
                                            label="Template"
                                            variant="outlined"
                                            error={false}
                                            disabled={loading}
                                            value={newOrgTemplate}
                                            onChange={(e) => {
                                                setNewOrgTemplate(e.target.value)
                                            }}
                                        >
                                            {
                                                ['template_1'].map((targetTemplate, index) => {
                                                    let optionLabel = ""
                                                    switch (targetTemplate) {
                                                        case "template_1":
                                                            optionLabel = "Original"
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                    return <MenuItem key={index} value={targetTemplate}>{optionLabel}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_template'} value={''}>Select Template</MenuItem>
                                        </Select>
                                    </FormControl><br/><br/>
                                    <Button
                                        fullWidth
                                        variant="outline"
                                        onClick={() => { handleCreateOrganization()}}
                                    >
                                        CREATE
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        {!creatingOrg && targetOrganization && targetOrganization._id && <Organization 
                            updateOrganization={updateOrganization} 
                            organization={targetOrganization}
                            getOrganization={getOrganization} 
                            loading={loading}
                            createdOrgAdminEmail={createdOrgAdminEmail}
                            createdOrgAdminPass={createdOrgAdminPass}
                            getTemplateVersion={getTemplateVersion} 
                            updateOrganizationTemplate={updateOrganizationTemplate}
                            handleShowCreateOrgCustomDomain={handleShowCreateOrgCustomDomain}
                            handleShowDeleteOrgCustomDomain={handleShowDeleteOrgCustomDomain}
                        />}
                    </Grid>
                </Slide>
            </Grid>
            {/* Create Custom Domain Dialog */}
            <Dialog
                fullWidth
                maxWidth={'lg'}
                open={showCreateOrgCustomDomain}
                onClose={() => { 
                    setShowCreateOrgCustomDomain(false)
                    setActiveCxDomainStep(0)
                    setCxDomain("")
                }}
            >
                <DialogTitle>
                    Add custom domain
                    <Stepper activeStep={activeCxDomainStep}>
                        {cxDomainSteps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                </DialogTitle>
                <Divider />
                    {
                        activeCxDomainStep === 0 && <DialogContent>
                            <DialogContentText>
                                Enter the exact domain name you want people to see when they visit your site. It can be a domain (yourdomain.com) or a subdomain (www.yourdomain.com or app.yourdomain.com)
                            </DialogContentText><br/>
                            <TextField
                                onChange={(e) => { 
                                    setCxDomain(e.target.value)
                                }}
                                disabled={loading}
                                error={false}
                                label="Domain"
                                fullWidth
                                variant="outlined"
                            />
                        </DialogContent>
                    }
                    {
                        (activeCxDomainStep === 1) && <DialogContent>
                            <DialogContentText>
                                Add these <b>CNAME records</b> to your domain by visiting your DNS provider or registra.<br/>
                                <b>Note</b>: It can take up to 72 hours to receive an SSL certificate from your third-party provider.
                            </DialogContentText><br/>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Record type</TableCell>
                                            <TableCell>Host</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <div id="copyTextInput"></div>
                                        {
                                            targetOrganization && targetOrganization.cx_domain_cert && targetOrganization.cx_domain_cert.domainValidationOptions && targetOrganization.cx_domain_cert.domainValidationOptions.map((eachDomain) => {
                                                if (eachDomain && eachDomain.DomainName === targetOrganization.cx_domain) {
                                                    return <TableRow
                                                        key={eachDomain.DomainName}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell >{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Type}</TableCell>
                                                        <TableCell align="right">{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Name && eachDomain.ResourceRecord.Name.split(".")[0]} <IconButton onClick={() => { copyText(`${(eachDomain.ResourceRecord && eachDomain.ResourceRecord.Name && eachDomain.ResourceRecord.Name.split(".")[0])}`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                        <TableCell align="right">{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Value} <IconButton onClick={() => { copyText(`${(eachDomain.ResourceRecord && eachDomain.ResourceRecord.Value)}`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                </TableRow>
                                                } else if (eachDomain && eachDomain.DomainName === targetOrganization.full_domain) {
                                                    return <TableRow
                                                        key={eachDomain.DomainName}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell>{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Type}</TableCell>
                                                        <TableCell align="right">@ <IconButton onClick={() => { copyText(`@`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                        <TableCell align="right">{targetOrganization.full_domain} <IconButton onClick={() => { copyText(`${targetOrganization && targetOrganization.full_domain}`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                </TableRow>
                                                } else {
                                                    return ''
                                                }
                                            })
                                        }
                                        {
                                            targetOrganization && ((!targetOrganization.cx_domain_cert) || (targetOrganization.cx_domain_cert && !targetOrganization.cx_domain_cert.domainValidationOptions)) && <TableRow>
                                                <TableCell>Loading</TableCell>
                                                <TableCell>Loading</TableCell>
                                                <TableCell>Loading</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                    }
                    {
                        (activeCxDomainStep === 2) && <DialogContent>
                            <DialogContentText>
                                <b>Your Domain</b>, <u>{targetOrganization.cx_domain}</u>, is connected to the site.
                            </DialogContentText><br/>
                            <DialogContentText>
                                <b>VERIFIED</b>. These <b>CNAME records</b> are in your domain with your DNS provider or registra.
                            </DialogContentText><br/>
                            <div id="copyTextInput"></div>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Record type</TableCell>
                                            <TableCell>Host</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            targetOrganization && targetOrganization.cx_domain_cert && targetOrganization.cx_domain_cert.domainValidationOptions && targetOrganization.cx_domain_cert.domainValidationOptions.map((eachDomain) => {
                                                
                                                if (eachDomain && eachDomain.DomainName === targetOrganization.cx_domain) {
                                                    return <TableRow
                                                        key={eachDomain.DomainName}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell >{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Type}</TableCell>
                                                        <TableCell align="right">{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Name && eachDomain.ResourceRecord.Name.split(".")[0]} <IconButton onClick={() => { copyText(`${(eachDomain.ResourceRecord && eachDomain.ResourceRecord.Name && eachDomain.ResourceRecord.Name.split(".")[0])}`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                        <TableCell align="right">{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Value} <IconButton onClick={() => { copyText(`${(eachDomain.ResourceRecord && eachDomain.ResourceRecord.Value)}`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                </TableRow>
                                                } else if (eachDomain && eachDomain.DomainName === targetOrganization.full_domain) {
                                                    return <TableRow
                                                        key={eachDomain.DomainName}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell>{eachDomain.ResourceRecord && eachDomain.ResourceRecord.Type}</TableCell>
                                                        <TableCell align="right">@ <IconButton onClick={() => { copyText(`@`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                        <TableCell align="right">{targetOrganization.full_domain} <IconButton onClick={() => { copyText(`${targetOrganization && targetOrganization.full_domain}`)}} aria-label="copy"><ContentCopyIcon/></IconButton></TableCell>
                                                </TableRow>
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                        {
                                            targetOrganization && ((!targetOrganization.cx_domain_cert) || (targetOrganization.cx_domain_cert && !targetOrganization.cx_domain_cert.domainValidationOptions)) && <TableRow>
                                                <TableCell>Loading</TableCell>
                                                <TableCell>Loading</TableCell>
                                                <TableCell>Loading</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                    }
                <DialogActions>
                    <Button 
                        autoFocus 
                        onClick={() => { 
                            setShowCreateOrgCustomDomain(false)
                            setActiveCxDomainStep(0)
                            setCxDomain("")
                        }}
                        variant={activeCxDomainStep == 2 ? "contained" : "text"}
                    >
                        {activeCxDomainStep == 2 ? "Finished" : "Close"}
                    </Button>
                    {
                        activeCxDomainStep === 0 && <Button 
                            autoFocus 
                            variant='contained'
                            onClick={() => { 
                                if (activeCxDomainStep === 0) {
                                    createCustomDomainLink({
                                        id: targetOrganization._id
                                    }, {
                                        "cx_domain": cxDomain
                                    })
                                    setActiveCxDomainStep(1)
                                }
                            }}
                        >
                            Continue
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            {/* Delete Custom Domain Dialog */}
            <Dialog
                fullWidth
                maxWidth={'lg'}
                open={showDeleteOrgCustomDomain}
                onClose={() => { 
                    setShowDeleteOrgCustomDomain(false)
                }}
            >
                <DialogTitle>
                    Are you sure?
                </DialogTitle>
                <Divider />
                    <DialogContent>
                        <DialogContentText>
                            This will remove the custom domain linked to the site.
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button 
                        autoFocus 
                        onClick={() => { 
                            setShowDeleteOrgCustomDomain(false)
                        }}
                        variant={'text'}
                    >
                        Cancel
                    </Button>
                    {
                        <Button 
                            autoFocus 
                            variant='contained'
                            color="error"
                            onClick={() => { 
                                deleteCustomDomainLink({
                                    id: targetOrganization._id
                                },{action: 'delete'})
                                setShowDeleteOrgCustomDomain(false)
                            }}
                        >
                            Confirm
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            </Container>
        </main>
    )
}

export default Organizations