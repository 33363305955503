import { connect } from 'react-redux'
import { toggleDrawer, setUser, getConfigurations, getMe, getOrganization } from '../actions'
import App from '../App'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users && state.users.user,
    userError: (state.users && state.users.error),
    userSuccess: (state.users && state.users.success),
})
// Maybe we can do an error for each resource
  
const mapDispatchToProps = dispatch => ({
    getMe: () => dispatch(getMe()),
    toggleDrawer: () => dispatch(toggleDrawer()),
    setUser: (user) => dispatch(setUser(user)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)