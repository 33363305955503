import { connect } from 'react-redux'

import LeftDrawer from '../components/LeftDrawer'

import { createDesignAsset, getDesignAssets, updateDesignAsset } from '../actions'

const mapStateToProps = state => ({
    landingPageComponents: state.designAssets.landingPageComponents,
    images: state.designAssets.images,
    imagesId: state.designAssets.imagesId,
})
  
const mapDispatchToProps = dispatch => ({
    getDesignAssets: (query) => dispatch(getDesignAssets(query)),
    createDesignAsset: (reqBody) => dispatch(createDesignAsset(reqBody)),
    updateDesignAsset: (params, reqBody) => dispatch(updateDesignAsset(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeftDrawer)