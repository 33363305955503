import { CALL_API } from '../app/middleware/designAssetApi';

export const createDesignAsset = (reqBody) => ({
    types: ['POST_CREATE_DESIGN_ASSET_REQUEST', 'POST_CREATE_DESIGN_ASSET_SUCCESS', 'POST_CREATE_DESIGN_ASSET_FAILURE'],
    [CALL_API]: '/designAssets',
    method: 'post',
    reqBody: reqBody,
})

export const getLandingPage = (query) => ({
    types: ['GET_LANDING_PAGE_REQUEST', 'GET_LANDING_PAGE_SUCCESS', 'GET_LANDING_PAGE_FAILURE'],
    [CALL_API]: '/designAssets',
    method: 'get',
    query: query
})

export const getDesignAssets = (query) => ({
    types: ['GET_DESIGN_ASSETS_REQUEST', 'GET_DESIGN_ASSETS_SUCCESS', 'GET_DESIGN_ASSETS_FAILURE'],
    [CALL_API]: '/designAssets',
    method: 'get',
    query: query
})

export const getNextDesignAssets = (query) => ({
    types: ['GET_NEXT_DESIGN_ASSETS_REQUEST', 'GET_NEXT_DESIGN_ASSETS_SUCCESS', 'GET_NEXT_DESIGN_ASSETS_FAILURE'],
    [CALL_API]: '/designAssets',
    method: 'get',
    query: query
})

export const getDesignAsset = (params) => ({
    types: ['GET_DESIGN_ASSET_REQUEST', 'GET_DESIGN_ASSET_SUCCESS', 'GET_DESIGN_ASSET_FAILURE'],
    [CALL_API]: '/designAssets/:id',
    method: 'get',
    params: params
})

export const updateDesignAsset = (params, reqBody) => ({
    types: ['PUT_UPDATE_DESIGN_ASSET_REQUEST', 'PUT_UPDATE_DESIGN_ASSET_SUCCESS', 'PUT_UPDATE_DESIGN_ASSET_FAILURE'],
    [CALL_API]: '/designAssets/:id',
    method: 'put',
    params: params,
    reqBody: reqBody,
})
